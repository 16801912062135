import React, { useEffect, useState } from "react";
import AxiosInstance from "../../utils/axiosInstance";
import { setLocalStoarge } from "../../common/allFunctions";
import Select from "react-select";

export default function CountryFilter({ onSelectCountry, reset, borderTrue }) {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("countyList") || "[]");
    setCountries(data);
    if (data.length === 0) {
      getCountry();
    }
  }, []);

  useEffect(() => {
    if (reset) {
      setSelectedCountry("");
    }
  }, [reset]);

  const getCountry = () => {
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_COUNTRY}`
    )
      .then((response) => {
        if (response?.data?.data) {
          setCountries(response?.data?.data);
          setLocalStoarge("countyList", JSON.stringify(response?.data?.data));
        }
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };
  const handleChange = (event) => {
    const country = event;
    setSelectedCountry(country);
    onSelectCountry(country.value);
  };
  const transformedCountries = countries.map((country) => ({
    value: country.countyName,
    label: country.countyName,
  }));
  return (
    <Select
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: "#9FAFCA",
          borderWidth: "3px",
          borderTop: !borderTrue && "none",
          borderLeft: !borderTrue && "none",
          borderRight: !borderTrue && "none",
          boxShadow: borderTrue && "none",
          "&:hover": {
            borderColor: "#2684FF",
          },
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: "#a71e1e",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#6c757d",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#9FAFCA" : "white",
          color: state.isSelected ? "white" : "black",
          "&:hover": {
            backgroundColor: "#9FAFCA",
            color: "white",
          },
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
          borderRadius: 0,
        }),
      }}
      options={transformedCountries}
      value={selectedCountry}
      onChange={handleChange}
      placeholder="COUNTY"
      isSearchable
    />
  );
}
