import bigImg from "../../../assets/bid-img.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import UploadProjectRequestModal from "../../Model/UploadProjectRequestModal";
import SelectDesignBuildCategoryModal from "../../Model/SelectDesignBuildCategoryModal";

export default function BidOpportunityComponent({ type }) {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenDesignBuild, setModalOpenDesignBuild] = useState(false);
  const [requestApproved, setRequestApproved] = useState(
    JSON.parse(localStorage.getItem("loggedInUserInfo")).status_request
  );

  const handleDesignBuildModalOpen = () => {
    setModalOpenDesignBuild(true);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);
  const handleDesignBuildModalClose = () => setModalOpenDesignBuild(false);

  const handleRequestApproved = (status) => setRequestApproved(status);

  return (
    <div className="card w-100 bg-primary rounded-3 shadow-sm mb-4 border-0">
      <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-secondary border-2">
        <div className="row g-0">
          <div className="col g-0">
            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-white fw-bold">
              Bid Opportunities
            </h3>
          </div>
          <div className="col-auto bg-secondary">
            <div className="d-flex flex-column justify-content-center">
              <img src={bigImg} alt="icon" className="header-icon m-2" />
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-3">
        <div className="mt-4">
          <button
            type="button"
            className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
            onClick={() => navigate("/dot")}
          >
            DOT
          </button>

          <button
            type="button"
            className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
            onClick={() => navigate("/statecitycountry")}
          >
            State/City/County
          </button>

          <button
            type="button"
            className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
            onClick={handleDesignBuildModalOpen}
          >
            Design Build
          </button>

          <button
            type="button"
            className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
            onClick={() => navigate("/private")}
          >
            Private
          </button>
        </div>
        <div className="clearfix text-white point-12 text-uppercase mb-2">
          * Section shows based on user profile
        </div>

        {/* Conditional Button Rendering */}
        {type === "WAH" && (
          <div className="col-12 col-md point-18 text-primary text-md-end">
            {requestApproved === "not_requested" ? (
              <button
                className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                onClick={handleModalOpen}
              >
                Request for Project Upload
              </button>
            ) : requestApproved === "pending" ? (
              <button
                className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                disabled
              >
                Upload Project Request is Pending
              </button>
            ) : (
              <button
                className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                onClick={() => navigate("/uplodingRequest")}
              >
                Upload Project
              </button>
            )}
          </div>
        )}
      </div>

      {/* Modals */}
      {modalOpen && (
        <UploadProjectRequestModal
          open={modalOpen}
          handleClose={handleModalClose}
          requestApproved={handleRequestApproved}
        />
      )}

      {modalOpenDesignBuild && (
        <SelectDesignBuildCategoryModal
          open={modalOpenDesignBuild}
          handleClose={handleDesignBuildModalClose}
        />
      )}
    </div>
  );
}
