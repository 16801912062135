import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

export default function CalanderPage() {
  return (
    <>
      <Header />
      <div class="bg-primary pt-5 pb-20">
        <div class="container">
          <div class="row">
            <div class="col col-md-auto bg-primary mb-3">
              <h1 class="text-secondary fw-boldest point-42">My Calendar</h1>
            </div>
            <div class="col text-end pt-md-4">
              <span class="text-secondary fw-bolder text-uppercase point-16 me-3"
              >User Profile</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-3">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div class="card-body p-3">
                  <div class="">
                    <div class="container-fluid">
                      <header>
                        <h4
                          class="point-32 text-primary fw-bold mb-4 text-center"
                        >
                          July 2024
                        </h4>
                        <div
                          class="row d-none d-sm-flex p-1 bg-primary text-white"
                        >
                          <h5 class="col-sm p-1 text-center">Sunday</h5>
                          <h5 class="col-sm p-1 text-center">Monday</h5>
                          <h5 class="col-sm p-1 text-center">Tuesday</h5>
                          <h5 class="col-sm p-1 text-center">Wednesday</h5>
                          <h5 class="col-sm p-1 text-center">Thursday</h5>
                          <h5 class="col-sm p-1 text-center">Friday</h5>
                          <h5 class="col-sm p-1 text-center">Saturday</h5>
                        </div>
                      </header>
                      <div class="row border border-right-0 border-bottom-0">
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">29</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Sunday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">30</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Monday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">31</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Tuesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">1</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Wednesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">2</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Thursday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">3</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Friday</small>
                            <span class="col-1"></span>
                          </h5>
                          <a
                            class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 1"
                          >Contract 1</a>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">4</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Saturday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div class="w-100"></div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">5</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Sunday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">6</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Monday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">7</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Tuesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">8</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Wednesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <a
                            class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 2"
                          >Contract 2</a>
                          <a
                            class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 3"
                          >Contract 3</a>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">9</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Thursday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">10</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Friday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">11</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Saturday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div class="w-100"></div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">12</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Sunday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">13</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Monday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">14</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Tuesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">15</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Wednesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">16</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Thursday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">17</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Friday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">18</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Saturday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div class="w-100"></div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">19</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Sunday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">20</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Monday</small>
                            <span class="col-1"></span>
                          </h5>
                          <a
                            class="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 4"
                          >Contract 4</a>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">21</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Tuesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">22</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Wednesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">23</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Thursday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">24</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Friday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">25</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Saturday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div class="w-100"></div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">26</span>
                            <small class="col d-sm-none text-center text-muted">Sunday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">27</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Monday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">28</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Tuesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">29</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Wednesday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">30</span>
                            <small class="col d-sm-none text-center text-muted"
                            >Thursday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">1</span>
                            <small class="col d-sm-none text-center text-muted">Friday</small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div
                          class="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 class="row align-items-center">
                            <span class="date col-1">2</span>
                            <small class="col d-sm-none text-center text-muted">Saturday </small>
                            <span class="col-1"></span>
                          </h5>
                          <p class="d-sm-none">No events</p>
                        </div>
                        <div class="w-100"></div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
