import React from "react";
import { useNavigate } from "react-router-dom";
import directoryImg from "../../assets/directoryImg.svg";

const DashboardAndWAHDirectoryComponent = () => {
  const navigate = useNavigate();

  return (
    <div
      className="card w-100 bg-secondary rounded-3 shadow-sm mb-4 border-0"
      style={{ height: "90%" }}
    >
      <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-white border-2">
        <div className="row g-0">
          <div className="col g-0">
            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
              Directory
            </h3>
          </div>
          <div className="col-auto bg-primary">
            <div className="d-flex flex-column justify-content-center">
              <img src={directoryImg} alt="icon" className="header-icon m-2" />
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-3">
        <div className="mt-4">
          <button
            type="button"
            className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
            onClick={() => navigate("/directory")}
          >
            View Directory
          </button>
          <div className="clearfix text-white point-12 text-uppercase mb-2">
            * Section shows based on user profile
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAndWAHDirectoryComponent;
