import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import AxiosInstance from "../../../utils/axiosInstance";
import loader from "../../../assets/loader.svg";
import CountryFilter from "../../Filters/CountryFilter";
import WorkTypeFilter from "../../Filters/WorkTypeFilter";
import { useNavigate } from "react-router-dom";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

function Map({ EndPoint }) {
  console.log("entry map ", EndPoint);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_ACCESS_TOKEN,
  });
  const [center, setCenter] = useState({ lat: 32.8065, lng: -86.7911 });
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [openDates, setOpenDates] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [listLoading, setLoading] = useState(true);
  const [selectedState, setSelectedState] = useState(
    JSON.parse(localStorage.getItem("selectedState")) || null
  );
  const [workTypeId, setWorkTypeId] = useState("");
  const [county, setCounty] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [resetTrue, setResetTrue] = useState(false);
  const [APICall, setAPICAll] = useState(false);

  const handleCountrySelect = (countryCode) => {
    setLoading(true); // Show loader

    setTimeout(() => {
      if (!countryCode) {
        setFilteredData(data); // Show all counties if no filter
      } else {
        const filtered = Object.keys(data)
          .filter((county) => county === countryCode)
          .reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
          }, {});

        setFilteredData(filtered);
      }

      console.log("Selected County:", countryCode);
      setLoading(false); // Hide loader
    }, 500); // Simulated delay

    setResetTrue(false);
  };

  const toggleCounty = (county, center) => {
    console.log("county", county, center);
    setSelectedCounty(selectedCounty === county ? null : county);
    setCenter(center);
  };

  const toggleDate = (county, date) => {
    setOpenDates((prev) => ({
      ...prev,
      [`${county}-${date}`]: !prev[`${county}-${date}`],
    }));
  };

  const fetchMarkers = async () => {
    setLoading(true);
    if (!selectedState || !selectedState.label || !EndPoint) {
      setData([]);
      setFilteredData([]); // Initially set filtered data to full data

      setLoading(false);
      return;
    }

    try {
      const response = await AxiosInstance.get(`
                ${process.env.REACT_APP_API_URL}${EndPoint}?stateid=${selectedState.label}`);

      if (response.data?.statusCode === 200 && response.data?.data) {
        const formattedData = {};

        Object.entries(response.data.data).forEach(([county, details]) => {
          if (details.lat && details.lng) {
            formattedData[county] = {
              lat: details.lat,
              lng: details.lng,
              projects: {},
            };

            Object.entries(details).forEach(([date, projects]) => {
              if (Array.isArray(projects)) {
                formattedData[county].projects[date] = projects.map(
                  (project, index) => ({
                    id: `${county}-${date}-${index}`, // Unique ID based on county and date
                    title: project.callid,
                    description: project.description,
                  })
                );
              }
            });
          }
        });
        setLoading(false);
        setData(formattedData);
        setFilteredData(formattedData); // Initially set filtered data to full data

        console.log("Formatted data:", formattedData);
      } else {
        setData([]);
        setFilteredData([]); // Initially set filtered data to full data
      }
    } catch (error) {
      console.error("Error fetching markers:", error);
      setData([]);
      setFilteredData([]); // Initially set filtered data to full data
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMarkers();
  }, [county, selectedDate, selectedState?.lable, workTypeId]); // ✅ Depend on relevant state

  useEffect(() => {
    fetchMarkers();
  }, [EndPoint]);

  const handleReset = () => {
    setResetTrue(true);
    setSelectedCounty("");
    setLoading(true); // Show loader
    setTimeout(() => {
      setFilteredData(data); // Reset to original data
      setLoading(false); // Hide loader
    }, 500); // Adjust delay as needed
  };

  const filtered = Object.keys(data).reduce((acc, countyKey) => {
    const countyData = data[countyKey];
    if (county && countyKey !== county) return acc;

    const filteredProjects = Object.entries(countyData.projects).reduce(
      (projAcc, [date, projects]) => {
        if (selectedDate && date !== selectedDate) return projAcc;
        projAcc[date] = projects.filter((project) =>
          workTypeId ? project.title.includes(workTypeId) : true
        );
        return projAcc;
      },
      {}
    );

    acc[countyKey] = { ...countyData, projects: filteredProjects };
    return acc;
  }, {});
  const [isListOpen, setIsListOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true); // State to track sidebar open/close

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const getShortenedText = (text, id) => {
    const words = text.split(" ");
    if (words.length <= 10) return text; // If 30 words or less, show full text

    return expanded[id] ? (
      <>
        {text} <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button className="item-btn" style={{ marginLeft: "10px" }}>
            Item Button
          </button>
          <span
            className="more-btn"
            style={{ marginRight: "10px" }}
            onClick={() => toggleExpand(id)}
          >
            View Less
          </span>
        </div>
      </>
    ) : (
      <>
        {words.slice(0, 10).join(" ")}...{" "}
        <span className="more-btn" onClick={() => toggleExpand(id)}>
          View More
        </span>
      </>
    );
  };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div className="row mb-2">
        <div className="col-12 col-md-auto">
          <span
            className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
            id="basic-addon1"
          >
            Filter:
          </span>
        </div>

        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div style={{ width: "100%" }}>
              <CountryFilter
                onSelectCountry={handleCountrySelect}
                reset={resetTrue}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-md mb-2">
          <div className="input-group mb-3">
            <button
              className="form-control btn btn-link text-uppercase me-2 text-danger text-start"
              onClick={() => handleReset()}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      <button
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px", // Space between text and icon
          padding: "5px 10px",
          border: "none",
          borderRadius: "50px", // Fully rounded edges
          backgroundColor: isHovered ? "#0056b3" : "#428bca", // Hover effect
          color: "white",
          fontSize: "16px",
          fontWeight: "bold",
          cursor: "pointer",
          transition: "background 0.3s ease, transform 0.2s ease",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={(e) => (e.target.style.transform = "scale(0.95)")} // Click effect
        onMouseUp={(e) => (e.target.style.transform = "scale(1)")}
        onClick={() => {
          setTimeout(() => setIsOpen(!isOpen), 50);
        }}
      >
        {isOpen ? "Close List " : "Open List "}
        <i
          className={`bi ${isOpen ? "bi-arrow-left" : "bi-arrow-right"}`}
          style={{
            fontSize: "22px",
            color: isHovered ? "white" : "inherit", // Icon color change on hover
            background: "none", // Ensures the icon has a transparent background
          }}
        ></i>
      </button>

      <div className="tab-content mt-3 border-radius-10">
        {/* Sidebar */}
        <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
          {/* <button
            className="toggle-btn"
            style={ {zIndex:10}}
            onClick={() => {
              setTimeout(() => setIsOpen(!isOpen), 300);
            }}
          >
            {isOpen ? "<" : ">"}
          </button> */}
          {true &&
            (loading ? (
              <div className="loader">
                <img src={loader} alt="Loading..." />
              </div>
            ) : (
              <div className="list-container">
                {Object.keys(filteredData).map((county) => (
                  <div key={county} className="county-block">
                    <div
                      onClick={() =>
                        toggleCounty(county, {
                          lat: parseFloat(filteredData[county].lat),
                          lng: parseFloat(filteredData[county].lng),
                        })
                      }
                      className="county-name"
                    >
                      {county}
                    </div>

                    {/* Slide animation for project list */}
                    <div
                      style={{ height: "auto" }}
                      className={selectedCounty === county ? "open" : "closed"}
                    >
                      <div className="panel-body">
                        <ul className={`listingView`}>
                          {Object.keys(filteredData[county].projects).map(
                            (date) => (
                              <>
                                {/* Date as a list item */}
                                <li key={county + date} className="date-block ">
                                  <div
                                    role="button"
                                    onClick={() => toggleDate(county, date)}
                                    style={{
                                      backgroundColor: "#428bca",
                                      padding: "4px",
                                      color: "#fff",
                                      width: "fit-content", // ✅ Width matches text
                                      display: "inline-block", // ✅ Prevents full-width expansion
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {new Date(date).toLocaleDateString(
                                      "en-US",
                                      {
                                        month: "short",
                                        day: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </div>
                                </li>

                                {/* Projects as `li` (without extra `ul`) */}
                                {filteredData[county].projects[date].map(
                                  (project) => (
                                    <li
                                      key={project.id}
                                      className="project-item hover-yellow"
                                      style={{
                                        borderBottom: "1px solid #ddd", // Light grey bottom border
                                        textAlign: "left",
                                        marginTop: "2px",
                                        paddingBottom: "8px", // Some spacing for readability
                                      }}
                                    >
                                      <p
                                        className="small text-muted"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <b
                                          style={{
                                            fontSize: "16px",
                                            color: "black",
                                          }}
                                        >
                                          {project.title.toUpperCase()}
                                        </b>
                                        <br />
                                        {getShortenedText(
                                          project.description.toUpperCase(),
                                          project.id
                                        )}
                                      </p>
                                    </li>
                                  )
                                )}
                              </>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>

        {/* Map Section */}
        <div className={`map-container ${isOpen ? "small-map" : "large-map"}`}>
          <div id="map-wrapper-dot" className="map-content">
            {isLoaded ? (
              loading ? (
                <div className="loader">
                  <img src={loader} alt="Loading..." />
                </div>
              ) : (
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  zoom={9}
                  center={center}
                  maxZoom={50}
                >
                  {center && <Marker position={center} />}
                </GoogleMap>
              )
            ) : (
              <div className="loader">
                <img src={loader} alt="Loading..." />
              </div>
            )}
          </div>
        </div>

        <style>{`

        .item-btn{
          margin-top : 5px;
          border-radius: 5px;
            font-weight: bold;
            cursor: pointer;
        }

        .item-btn:hover{
            background-color: #2684FF;
            color: #fff;
          
            }
          .hover-yellow:hover {
   background: #FFFFC5 none repeat scroll 0 0;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece;
  }
.open {
  max-height: 1000px; /* Adjust based on content */
  opacity: 1;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}

.closed {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}


        .more-btn {
  color: blue;
  cursor: pointer;
  font-weight: bold;
}
.more-btn:hover {
  text-decoration: underline;
}


        .date-block{
        text-align: left;
         margin-top: 10px;
        }

        .listingView {
    max-height: 290px;
    list-style: outside none none;
    margin: 0;
    overflow: auto;
    padding: 10px;
    position: relative;
    background-color: #fff;
    /* margin-bottom: 10px; */
}

       .panel-body {
   border-top-color: #ddd;
   border-top: 1px solid #ddd;  
  /* padding :15px; */
   list-style: none;
       }

    .tab-content {
      display: flex;
      width: 100%;
      height: 80vh;
      transition: all 0.3s ease-in-out;
    }

    .sidebar {
      background: #cbd2d9;
      color: white;
      display: flex;
      flex-direction: column;
  transition: width 0.3s ease, align-items 0.3s ease, justify-content 0.3s ease;
     
      overflow: hidden;
    }

    .sidebar.open {
      width: 40%;
    }

    .sidebar.closed {
      width: 1%;
      align-items: center;
      justify-content: center;
     
    }

    .list-container {
      flex-grow: 1;
      overflow-y: auto;
      max-height: 100%;
      padding: 10px;
      text-align: center;   
      background : darkgrey
    }

  */  .toggle-btn {
      background: white;
      color: #003366;
      border: none;
      cursor: pointer;
      font-size: 20px;
      padding: 5px;
      width: 30px;
      position: absolute;
      right: 5px;
      top: 10px;
    }  */
.slider-btn {
  border: 1px;
    border-radius: 50px;
}


    
  */  .listingView li:hover {
    background: #FFFFC5 none repeat scroll 0 0;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece; */
}
    .project-dates {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      background: wheat;
    border-radius: 5px;
    }

    .project-dates.open {
      max-height: 500px;
    }
   .date-name {
    text-align: left;
    margin-left: 4px;
    margin-top: 4px;
    background: black;
    /* width: auto; */
    margin-right: 4px;
    padding-left: 4px;
    border-radius: 4px;
    width: fit-content;
    padding: 4px;
}
    .map-container {
      transition: width 0.3s ease-in-out;
      background: #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .small-map {
      width: 70%;
    }

    .large-map {
      width: 100%;
    }
 .county-block {
   
}

.county-name{
 cursor: pointer; /* Changes the cursor to a pointer */
 margin-top: 2px;
    padding: 4px;
    border-radius: 2px;
   background-color:#428bca;
    color:#fff}
    
    .map-content {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    @media (max-width: 768px) {
      .tab-content {
        flex-direction: column;
      }

      .sidebar.open {
        width: 100%;
        height: 40vh;
      }

      .small-map {
        width: 100%;
        height: 60vh;
      }

      .large-map {
        width: 100%;
        height: 95vh;
      }
    }
  `}</style>
      </div>
    </>
  );
}

export default Map;
