import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import AxiosInstance from "../utils/axiosInstance";
import loader from "../assets/loader.svg";
import CountryFilter from "./Filters/CountryFilter";
import WorkTypeFilter from "./Filters/WorkTypeFilter";
import { useNavigate } from "react-router-dom";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

function Map({ EndPoint }) {
  console.log("entry map ", EndPoint);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_ACCESS_TOKEN,
  });
  const [center, setCenter] = useState({ lat: 32.8065, lng: -86.7911 });

  const navigate = useNavigate();
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedState, setSelectedState] = useState(
    JSON.parse(localStorage.getItem("selectedState")) || null
  );
  const [workTypeId, setWorkTypeId] = useState("");
  const [county, setCounty] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [resetTrue, setResetTrue] = useState(false);
  const [APICall, setAPICAll] = useState(false);

  const handleCountrySelect = (countryCode) => {
    setCounty(countryCode);
    setResetTrue(false);
  };

  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setResetTrue(false);
  };
  const fetchMarkers = async () => {
    setLoading(true);
    if (!selectedState || !selectedState.label) return; // Ensure valid state before making API call

    if (EndPoint === undefined) {
      setMarkers([]);
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 1000ms = 1 second
      return;
    }
    const queryParams = new URLSearchParams({
      report_id: workTypeId || "",
      stateid: selectedState.label || "",
      county: county || "",
      date: selectedDate || "",
    });
    const currentTime = new Date().toISOString();

    try {
      const response = await AxiosInstance.get(
        `${process.env.REACT_APP_API_URL}${EndPoint}?currentTime=${currentTime}&${queryParams}`
      );

      if (response.data?.data?.length > 0) {
        const fetchedMarkers = response.data.data
          .filter((item) => item.lat && item.lon) // Ensure valid coordinates
          .map((item) => ({
            id: item.account_id,
            position: {
              lat: parseFloat(item.lat),
              lng: parseFloat(item.lon),
            },
            title: item.account_name,
            other: {
              address:
                item.billing_address_street +
                  " ," +
                  item.billing_address_city +
                  " , " +
                  item.billing_address_state +
                  " , " +
                  item.zip_code || "N/A",
              county: item.billing_address_city || "N/A",
              phone: item.phone_office || "N/A",
              certificates: item.certificate_names || "N/A",
            },
          }));

        setMarkers(fetchedMarkers);

        // ✅ Only update center if valid markers exist
        if (fetchedMarkers.length > 0) {
          const avgLat =
            fetchedMarkers.reduce(
              (sum, marker) => sum + marker.position.lat,
              0
            ) / fetchedMarkers.length;
          const avgLng =
            fetchedMarkers.reduce(
              (sum, marker) => sum + marker.position.lng,
              0
            ) / fetchedMarkers.length;

          setCenter({ lat: avgLat, lng: avgLng });
        }
      } else {
        setMarkers([]);
      }
    } catch (error) {
      console.error("Error fetching markers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMarkers();
  }, [county, selectedDate, selectedState?.lable, workTypeId]); // ✅ Depend on relevant state

  useEffect(() => {
    fetchMarkers();
  }, [EndPoint]);

  const handleReset = () => {
    setResetTrue(true);
    setWorkTypeId("");
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div
              id="map-wrapper"
              className="map-wrapper vh-50 mt-n20 bg-white shadow-sm mb-5"
              style={{ overflow: "hidden", height: "100%" }}
            >
              {isLoaded ? (
                loading ? (
                  <div className="loader">
                    <img src={loader} alt="loading" />
                  </div>
                ) : (
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={5}
                    center={center}
                    maxZoom={50}
                    //mapTypeId="hybrid" // ✅ Corrected syntax
                  >
                    <MarkerClusterer
                      gridSize={80}
                      maxZoom={14}
                      disableClusteringAtZoom={14}
                      minimumClusterSize={5}
                    >
                      {(clusterer) =>
                        markers.map((marker) => (
                          <React.Fragment key={marker.id}>
                            <Marker
                              position={marker.position}
                              title={marker.title}
                              clusterer={clusterer}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                scaledSize: new window.google.maps.Size(40, 40),
                              }}
                              // onMouseOut={() => setSelectedMarker(null)}
                              onMouseOver={() => setSelectedMarker(marker)}
                            />

                            {/* Always show marker when info window is open */}
                            {selectedMarker &&
                              selectedMarker.id === marker.id && (
                                <Marker
                                  position={marker.position}
                                  icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                    scaledSize: new window.google.maps.Size(
                                      40,
                                      40
                                    ),
                                  }}
                                />
                              )}
                          </React.Fragment>
                        ))
                      }
                    </MarkerClusterer>

                    {selectedMarker && (
                      <InfoWindow
                        position={{
                          lat: selectedMarker.position.lat + 0.002, // Slight offset so marker remains visible
                          lng: selectedMarker.position.lng,
                        }}
                        onCloseClick={() => setSelectedMarker(null)}
                      >
                        <div className="info-window">
                          <h4>{selectedMarker.title}</h4>
                          <p>
                            <strong
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Address :{" "}
                            </strong>
                            {selectedMarker.other.address}
                          </p>
                          <p>
                            <strong
                              className="field-label"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              County :
                            </strong>{" "}
                            {selectedMarker.other.county}
                          </p>
                          <p>
                            <strong
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Phone :
                            </strong>{" "}
                            {selectedMarker.other.phone}
                          </p>
                          <p>
                            <strong
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              Certificates :
                            </strong>
                          </p>
                          <ul>
                            {selectedMarker.other.certificates
                              .split("#") // Split by #
                              .map((cert, index) => (
                                <li key={index}>- {cert.trim()}</li> // Add '-' before each item
                              ))}
                          </ul>
                        </div>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                )
              ) : (
                <div className="loader">
                  <img src={loader} alt="loading" />
                </div>
              )}
                {/* <div
                  id="compass-rose"
                  className="p-4 bg-white w-25 clearfix"
                  style={{ zIndex: 10 }}
                >
                  <h4 className="text-secondary text-uppercase point-18">
                    Filter:
                  </h4>
                  <div>
                    <WorkTypeFilter
                      onSelectWorkType={handleWorkTypeSelect}
                      reset={resetTrue}
                    />
                  </div>
                  <div>
                    <CountryFilter
                      onSelectCountry={handleCountrySelect}
                      reset={resetTrue}
                    />
                  </div>
                  <input
                    type="date"
                    className="form-control fw-bold bg-white rounded-0 border-bottom border border-0 border-3 border-secondary text-uppercase"
                    aria-label="Date"
                    aria-describedby="basic-addon1"
                    onChange={handleDateSelect}
                    reset={resetTrue}
                  />
                </div> */}
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          .info-window {
            width: 300px;
            background: #fff;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(0,0,0,0.2);
            font-family: Arial, sans-serif;
          }
          .info-window h3 {
            font-size: 18px;
            font-weight: bold;
            color: #0A2540;
            margin-bottom: 10px;
          }
          .info-window p {
            margin-bottom: 5px;
          }
          .info-window ul {
            list-style-type: none; /* Removes bullets */
            padding-left: 0; /* R
          }
          .info-window li {
            margin-bottom: 3px;
          }
            .field-label{
              color: "#000";
              fontWeight: "bold";
              fontSize: "14px";
            }
        `}
      </style>
    </>
  );
}

export default Map;
