import React, { useEffect, useState ,useRef } from "react";
import CountryFilter from "../../Filters/CountryFilter";
import Pagination from "../../Layout/Pagination";
import paginationValue from "../../../common/paginationValue.json";
import toast from "react-hot-toast";
import AxiosInstance from "../../../utils/axiosInstance";
import loader from "../../../assets/loader.svg";
import Select from "react-select";
import BidMatchingModel from "../../Model/BidMatchingModel";
import axios from "axios";

function BidMatching() {
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [bidList, setBidList] = useState([]);
  const [showloader, setloader] = useState(false);
  const [county, setCounty] = useState("");
  const [resetTrue, setResetTrue] = useState(false);
  const [lettingDate, setLettingDate] = useState("");
  const [stateId, setStateId] = useState("");
  const [selectedlettingDate, setSelectedlettingDate] = useState("");
  const [lettingDateList, setLettingDateList] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [reportId, setReportId] = useState("");

  const abortControllerRef = useRef(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getLettingDate();
  }, []);

  useEffect(() => {
    if (currentPage > 0) {
      getBidList();
    }
  }, [currentPage, lettingDate, stateId, county]);

  const getBidList = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const queryParams = new URLSearchParams({
      county: county,
      letting_id: lettingDate,
      stateid: stateId,
      page: currentPage,
      limit: pageLimit,
    });
    setloader(true);

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const currentTime = new Date().getTime();
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_BID_MATCHING}?currentTime=${currentTime}&${queryParams}`,
      { signal: controller.signal }
    )
      .then((response) => {
        if (response.data?.data) {
          setBidList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setBidList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") {
          // console.log("Aborted");
          //toast.error(error?.message);
        } else {
          toast.error(error?.message);
          setloader(false);
        }
      });
  };

  const getLettingDate = () => {
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_LETTING_DATE}`
    )
      .then((response) => {
        if (response.data?.data) {
          setLettingDateList(response.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.log("in catch block:", error);
      });
  };
  const handleChange = (event) => {
    const [lettingid, stateid] = event.value.split("|");
    setLettingDate(lettingid);
    setStateId(stateid);
    setSelectedlettingDate(event);
  };

  const handleReset = () => {
    setResetTrue(true);
    setStateId("");
    setLettingDate("");
    setCounty("");
    setSelectedlettingDate("");
  };
  const handleCountrySelect = (county) => {
    setCounty(county);
    setResetTrue(false);
  };
  const transformedLettingDateList = lettingDateList.map((options) => ({
    value: `${options.lettingid}|${options.stateid}`,
    label: `${options.LettingDate}(${options.stateid})`,
  }));

  const handleModelOpen = (reportId, lettingId, state_id) => {
    // setSelectedData(callId);
 //   console.log(reportId, lettingId, county, stateId);
    setReportId(reportId);
    setLettingDate(lettingId);
    setStateId(state_id);
    setModelOpen(true);
  };

  const handleModelClose = () => setModelOpen(false);

  return (
    <>
      <div className="row mb-2">
        <div className="col-12 col-md-auto">
          <span
            className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
            id="basic-addon1"
          >
            Filter:
          </span>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div style={{ width: "100%" }}>
              <CountryFilter
                onSelectCountry={handleCountrySelect}
                reset={resetTrue}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-md mb-2">
          <Select
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#9FAFCA",
                borderWidth: "3px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#2684FF",
                },
              }),
              dropdownIndicator: (provided, state) => ({
                ...provided,
                color: "#a71e1e",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#6c757d",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#9FAFCA" : "white",
                color: state.isSelected ? "white" : "black",
                "&:hover": {
                  backgroundColor: "#9FAFCA",
                  color: "white",
                },
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                borderRadius: 0,
              }),
            }}
            options={transformedLettingDateList}
            value={selectedlettingDate}
            onChange={handleChange}
            placeholder="DATE"
            isSearchable
          />
        </div>
        <div className="col-6 col-md mb-2">
          <div className="input-group mb-3">
            <button
              className="form-control btn btn-link text-uppercase me-2 text-danger text-start"
              onClick={() => handleReset()}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      <div className="">
        {!showloader ? (
          <ul className="list-group py-3">
            {bidList.length && bidList ? (
              bidList.map((data, i) => (
                <li
                  className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0"
                  key={i}
                >
                  <div className="d-md-flex justify-content-between align-items-start">
                    <div className="me-auto mb-2">
                      <span className="fw-bold point-22">
                        {data?.report_name}
                      </span>
                    </div>
                    <div className="mb-2">
                      <a
                        className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer"
                        onClick={() =>
                          handleModelOpen(
                            data?.report_id,
                            data?.letting_id,
                            data?.stateid
                          )
                        }
                      >
                        View
                      </a>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div className="d-md-flex justify-content-center">
                No Data Found.
              </div>
            )}
          </ul>
        ) : (
          <div className="loader">
            <img src={loader} alt="loading" />
          </div>
        )}
      </div>
      <div className="col">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onPageChange={handlePageChange}
        />
      </div>
      {modelOpen && (
        <BidMatchingModel
          open={modelOpen}
          handleClose={handleModelClose}
          county={county}
          stateId={stateId}
          reportId={reportId}
          lettingId={lettingDate}
        />
      )}
    </>
  );
}

export default BidMatching;
