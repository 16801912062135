import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";
import AxiosInstance from "../../utils/axiosInstance";
import loader from "../../assets/loader.svg";

export default function UploadProjectRequestModal({ open, handleClose, requestApproved }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);

  const options = [
    { value: "city", label: "City" },
    { value: "county", label: "County" },
    { value: "design_build", label: "Design Build" },
    { value: "private", label: "Private" },
    { value: "state", label: "State" },
  ];

 

  const handleSelectChange = (selected) => setSelectedOption(selected);

  const handleSubmit = async () => {
    if (!selectedOption) {
      toast.error("Please select an option");
      return;
    }

    setLoading(true); // Start loading before request

    try {
      const response = await AxiosInstance.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REQUEST_FOR_PROJECT_UPLOAD}`,
        { status_request: "pending", request_for: selectedOption.value }
      );

      requestApproved("pending");

      // Update localStorage
      const userInfo = JSON.parse(localStorage.getItem("loggedInUserInfo")) || {};
      userInfo.status_request = "pending";
      localStorage.setItem("loggedInUserInfo", JSON.stringify(userInfo));

      toast.success("Request submitted successfully");
      handleClose();
    } catch (error) {
      console.error("Error:", error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  return (
    <Modal size="md" show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Project Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="mb-3">
            <Select
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
              placeholder="Select an option..."
              isSearchable
              theme={(theme) => ({ ...theme, borderRadius: 0 })}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: state.isFocused ? "#2684FF" : base.borderColor,
                  "&:hover": { borderColor: "#2684FF" },
                }),
                dropdownIndicator: (provided) => ({ ...provided, color: "#a71e1e" }),
                placeholder: (provided) => ({ ...provided, color: "#6c757d" }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#9FAFCA" : "white",
                  color: state.isSelected ? "white" : "black",
                  "&:hover": { backgroundColor: "#9FAFCA", color: "white" },
                }),
                menu: (provided) => ({ ...provided, zIndex: 9999, borderRadius: 0 }),
              }}
            />
          </div>

          <div className="mt-3 text-center">
            <Button variant="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
