import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/Dashboard";
import TermsAndCondition from "./pages/TermsConditionPage";
import ContactUsPage from "./pages/ContactUsPage";
import Dot from "./pages/DotPage";
import StateCityCountryPage from "./pages/StateCityCountryPage";
import DesignBuildPage from "./pages/DesignBuildPage";
import PrivatePage from "./pages/Private";
import DirectoryPage from "./pages/Directory";
import CalanderPage from "./pages/Calander";
import LoginPage from "./pages/Auth/Login";
import UserProfile from "./pages/Profile/UserProfile";
import FormsPage from "./pages/Forms";
import UploadingRequest from "./pages/UploadingRequest";
import MarketingPage from "./pages/Marketing";
import DotSupportServicePage from "./pages/DotSupportService";
import WAH from "./pages/WAH";
import CCN from "./pages/CCN";
import MYPROJECTS from "./pages/MyProjects";

import ProtectedRoute from "./components/Guard/ProtectedRoute";
import UserTypeProtectedRoute from "./components/Guard/UserTypeProtectedRoute";
import AlreadyLoginProtectedRoute from "./components/Guard/AlreadyLoginProtectedRoute";
export default function Router() {
  let isLoggedIn = localStorage.getItem("accessToken") ? true : false;
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUserInfo"));

  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/signup", element: <Signup /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/termsandcondition", element: <TermsAndCondition /> },
    { path: "/contactus", element: <ContactUsPage /> },
    // Protected Routes
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          {/* <UserTypeProtectedRoute> */}
            <Dashboard />
          {/* </UserTypeProtectedRoute> */}
        </ProtectedRoute>
      ),
    },
    {
      path: "/dot",
      element: (
        // <ProtectedRoute isLoggedIn={isLoggedIn}>
          <Dot />
        // </ProtectedRoute>
      ),
    },
    {
      path: "/statecitycountry",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <StateCityCountryPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/designbuild",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <DesignBuildPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/private",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <PrivatePage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/directory",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <DirectoryPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/calander",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <CalanderPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <UserProfile />
        </ProtectedRoute>
      ),
    },
    {
      path: "/forms",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <FormsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/uplodingRequest",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <UploadingRequest />
        </ProtectedRoute>
      ),
    },
    {
      path: "/marketing",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <MarketingPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dotsupportservice",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <DotSupportServicePage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/wah",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <WAH />
        </ProtectedRoute>
      ),
    },
    {
      path: "/ccn",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <CCN />
        </ProtectedRoute>
      ),
    },
    {
      path: "/myprojects",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <MYPROJECTS />
        </ProtectedRoute>
      ),
    },
  ]);
  return routes;
}
