import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import DashboardShortCuts from "../components/dashboardAndWAHComponent/common/DashboardShortCuts";
import calander from "../assets/calander.svg"
import downloadImg from "../assets/download-img.svg"
export default function CCN() {

  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">My Dashboard</h1>
            </div>
            <div className="col-12 col-md text-md-end pt-md-4">
              <span
                className="text-secondary fw-bolder text-uppercase point-16 me-md-3"
              >User Profile</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3 clearfix">
        <div className="container mt-n20">
          <div className="row">
            <div className="col-12 col-md-8 pb-4">
              <div
                className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0"
              >
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold"
                      >
                        Quick Links
                      </h3>
                    </div>
                    <div className="col-auto bg-secondary">
                      <div className="rounded-pill bg-secondary w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <img src={downloadImg} className="header-icon m-2" alt ="logo"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <ul className="mh-20 list-group m-0 p-0">
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        ALDOT Lettings
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        Survey Monkey
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        Emma
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        Upload
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
             <DashboardShortCuts/>
          </div>

          <div className="row">
            <div className="col">
              <div className="card w-100 bg-white rounded-3 shadow-sm mb-4">
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold"
                      >
                        My Calendar
                      </h3>
                    </div>
                    <div className="col-auto bg-secondary">
                      <div className="rounded-pill bg-secondary w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <img src={calander} className="header-icon m-2" alt="logo"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="">
                    <div className="container-fluid">
                      <header>
                        <h4
                          className="point-32 text-primary fw-bold mb-4 text-center"
                        >
                          July 2024
                        </h4>
                        <div
                          className="row d-none d-sm-flex p-1 bg-primary text-white"
                        >
                          <h5 className="col-sm p-1 text-center">Sunday</h5>
                          <h5 className="col-sm p-1 text-center">Monday</h5>
                          <h5 className="col-sm p-1 text-center">Tuesday</h5>
                          <h5 className="col-sm p-1 text-center">Wednesday</h5>
                          <h5 className="col-sm p-1 text-center">Thursday</h5>
                          <h5 className="col-sm p-1 text-center">Friday</h5>
                          <h5 className="col-sm p-1 text-center">Saturday</h5>
                        </div>
                      </header>
                      <div className="row border border-right-0 border-bottom-0">
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">29</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Sunday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">30</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Monday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">31</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Tuesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">1</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Wednesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">2</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Thursday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">3</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Friday</small >
                            <span className="col-1"></span>
                          </h5>
                          <a
                            className="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 1"
                          >Contract 1</a>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">4</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Saturday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div className="w-100"></div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">5</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Sunday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">6</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Monday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">7</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Tuesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">8</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Wednesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <a
                            className="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 2"
                          >Contract 2</a>
                          <a
                            className="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 3"
                          >Contract 3</a>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">9</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Thursday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">10</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Friday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">11</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Saturday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div className="w-100"></div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">12</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Sunday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">13</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Monday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">14</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Tuesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">15</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Wednesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">16</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Thursday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">17</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Friday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">18</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Saturday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div className="w-100"></div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">19</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Sunday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">20</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Monday</small >
                            <span className="col-1"></span>
                          </h5>
                          <a
                            className="event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate text-decoration-none small bg-secondary text-white"
                            title="Contract 4"
                          >Contract 4</a>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">21</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Tuesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">22</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Wednesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">23</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Thursday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">24</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Friday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">25</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Saturday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div className="w-100"></div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">26</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Sunday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">27</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Monday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">28</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Tuesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">29</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Wednesday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">30</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Thursday</small >
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">1</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Friday</small>
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div
                          className="day col-sm p-2 border border-left-0 border-top-0 text-truncate d-none d-sm-inline-block bg-light text-muted"
                        >
                          <h5 className="row align-items-center">
                            <span className="date col-1">2</span>
                            <small className="col d-sm-none text-center text-muted"
                            >Saturday</small>
                            <span className="col-1"></span>
                          </h5>
                          <p className="d-sm-none">No events</p>
                        </div>
                        <div className="w-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
