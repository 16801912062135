export const getFormattedDate = (dateInput) => {
  if (!dateInput) return "Invalid Date"; // Handle empty input

  // Trim and convert to a Date object
  const date = new Date(String(dateInput).trim());

  if (isNaN(date.getTime())) {
    return "Invalid Date"; // Handle invalid dates
  }

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
};

 export const formatPhoneNumber = (phone) => {
    if (!phone) return "N/A"; // Handle null or undefined values
  
    // Remove all non-numeric characters
    const cleaned = phone.toString().replace(/\D/g, "");
  
    // Ensure the number has at least 10 digits
    if (cleaned.length < 10) return "Invalid Number";
  
    // Extract parts (Area Code - Prefix - Line Number)
    const formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  
    return formatted;
  };
  