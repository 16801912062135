import React from "react";
import progrssGraph from "../../../assets/progress-graph-icon.svg";

const DbrPerformancePlanComponent = () => {
  return (
    <div className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0">
      <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2">
        <div className="row g-0">
          <div className="col g-0">
            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
              DBE PERFORMANCE PLAN
            </h3>
          </div>
          <div className="col-auto bg-secondary">
            <div className="rounded-pill bg-secondary w-100">
              <div className="d-flex flex-column justify-content-center">
                <img
                  className="header-icon m-2"
                  src={progrssGraph}
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body p-3">
        <div className="mt-5">
          <button
            type="button"
            className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
            onClick={() =>
              window.open(
                "https://dev.contractorcn.com/resources_file/WAH_DBE_Plan.pdf",
                "_blank"
              )
            }
          >
            DBR Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default DbrPerformancePlanComponent;
