import React, { useState, useEffect } from "react";
import downloadImg from "../../../assets/download-img.svg";
import loader from "../../../assets/loader.svg";

const sampleReports = [
  {
    id: 1,
    title: "CUFS",
    link: "#",
  },
  {
    id: 2,
    title: "Economic Reports",
    link: "#",
  },
  {
    id: 3,
    title: "Meeting & Attendance Records",
    link: "#",
  },
];

const MonitoringReports = () => {
  const [reportList, setReportList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setReportList(sampleReports);
      setShowLoader(false);
    }, 1000); // Simulate loading
  }, []);

  return (
    <div className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0">
      <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2">
        <div className="row g-0">
          <div className="col g-0">
            <div className="float-end text-secondary point-16 mb-2 fw-boldest pt-4 me-4">
              {/* + Add&nbsp;
              <span className="text-danger">contacts</span> */}
            </div>
            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
              Monitoring Reports
            </h3>
          </div>
          <div className="col-auto bg-secondary">
            <div className="rounded-pill bg-secondary w-100">
              <div className="d-flex flex-column justify-content-center">
                <img src={downloadImg} className="header-icon m-2" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body p-3">
        {!showLoader ? (
          reportList.length > 0 ? (
            <ul className="list-group py-3 pe-3 mh-20">
              {reportList.map((report) => (
                <li
                  key={report.id}
                  className="list-group-item py-3 mb-3 d-flex align-items-center bg-light border border-1 rounded-0"
                >
                  <div className="w-100 d-flex justify-content-between align-items-center flex-nowrap">
                    <div
                      className="text-primary fw-bold point-18 me-2 text-truncate"
                      style={{
                        maxWidth: "65%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={report.title}
                    >
                      {report.title}
                    </div>
                    <a
                      href={report.link}
                      className="text-decoration-none btn-with-svg point-18 btn rounded-pill btn-primary"
                    >
                      View
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="d-flex justify-content-center py-3">
              <p className="text-muted">No Reports Found.</p>
            </div>
          )
        ) : (
          <div className="d-flex justify-content-center py-3">
            <img src={loader} alt="Loading..." className="loader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MonitoringReports;
