import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Pagination from "../Layout/Pagination";
import AxiosInstance from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import loader from "../../assets/loader.svg";
import paginationValue from "../../common/paginationValue.json";

export default function BidMatchingModel({
  open,
  handleClose,
  county,
  stateId,
  reportId,
  lettingId,
}) {
  const [search, setSearch] = useState("");
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [bidList, setBidList] = useState([]);
  const [showloader, setloader] = useState(false);

  useEffect(() => {
    if (reportId) {
      getBidList();
    }
  }, [reportId, currentPage, search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const getBidList = () => {
    const queryParams = new URLSearchParams({
      report_id: reportId,
      letting_id: lettingId,
      stateid: stateId,
      county: county,
      page: currentPage,
      limit: pageLimit,
    });
    setloader(true);
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_BID_MATCHING_DETAIL}?${queryParams}`
    )
      .then((response) => {
        if (response.data?.data) {
          setBidList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
        } else {
          setBidList([]);
        }
        setloader(false);
        toast.success(response.data?.message);
      })
      .catch((error) => {
        toast.error(error?.message);
        setloader(false);
      });
  };

  const resetState = () => {
    setSearch("");
    setPageLimit(paginationValue.limit);
    setTotalPages(0);
    setTotalRecords(0);
    setCurrentPage(paginationValue.currentPage);
    setBidList([]);
    setloader(false);
  };

  const handleModalClose = () => {
    resetState();
    handleClose();
  };

  return (
    <Modal
      size="xl"
      show={open}
      onHide={handleModalClose}
      centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton onClick={handleModalClose}>
        <Modal.Title id="example-custom-modal-styling-title centered">
          Bid Matching Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container mt-4 mb-2 listBigModel">
          {!showloader ? (
            <div className="table-responsive"> {/* Wrapper added for horizontal scroll */}
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>Call</th>
                    <th>County</th>
                    <th>Line</th>
                    <th>Quantity</th>
                    <th>Units</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {bidList.length > 0 ? (
                    bidList.map((row, index) => (
                      <tr key={index}>
                        <td>{row.call_order}</td>
                        <td>{row.county}</td>
                        <td>{row.line_number}</td>
                        <td>{row.quanity}</td>
                        <td>{row.units}</td>
                        <td>{row.short_description}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="loader">
              <img src={loader} alt="loading" />
            </div>
          )}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onPageChange={handlePageChange}
        />
      </Modal.Body>
    </Modal>
  );
}
