import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    // Function to generate visible page numbers
    const getPageNumbers = () => {
        let start = Math.max(1, currentPage - 2);
        let end = Math.min(totalPages, currentPage + 2);
        const pages = [];

        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        return pages;
    };

    return (
        <nav aria-label="Page navigation example" className="d-flex justify-content-end">
            <ul className="pagination flex-wrap overflow-auto">
                {/* Previous Button */}
                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button
                        className="page-link border-0"
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                </li>

                {/* Render Page Numbers */}
                {getPageNumbers().map((page) => (
                    <li key={page} className={`page-item ${page === currentPage ? "active fw-bolder" : ""}`}>
                        <button
                            className="page-link border-0"
                            onClick={() => onPageChange(page)}
                        >
                            {page}
                        </button>
                    </li>
                ))}

                {/* Next Button */}
                <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                    <button
                        className="page-link border-0"
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
