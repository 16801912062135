
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import { useState } from 'react';
import AxiosInstance from '../utils/axiosInstance';
import toast from 'react-hot-toast';

export default function ContactUsPage() {
  const maxWords = 256;
  const [wordCount, setWordCount] = useState(0);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    inquiry: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name.trim()) newErrors.first_name = "First name is required.";
    if (!formData.last_name.trim()) newErrors.last_name = "Last name is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!formData.phone_number.trim()) newErrors.phone_number = "Phone number is required.";
    else if (!/^(\+1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formData.phone_number)) {
      newErrors.phone_number = "Invalid phone number format.";
    }
    if (!formData.inquiry.trim()) newErrors.inquiry = "Inquiry message is required.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    AxiosInstance.post(`${process.env.REACT_APP_API_URL}/add_contact_lead`, formData)
      .then(response => {
        if (response) {
          toast.success("Message sent successfully!");
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            inquiry: "",
          });
          setWordCount(0);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch(error => {
        console.error("Error sending message:", error);
        toast.error("Error sending message. Please try again later.");
      });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "inquiry") {
      setWordCount(value.length);
    }
    setErrors((prev) => ({
      ...prev,
      [name]: ''
    }));
  };
  return (
    <>
      <div className="vh-100 d-flex flex-column">
        <div className="bg-image">
          <Header />

          <div className="container">
            <div className="row mt-15">
              <div className="col col-md-auto bg-primary">
                <h1 className="text-white p-3 fw-boldest point-42">Contact Us</h1>
              </div>
              <div className="col">&nbsp;</div>
            </div>
          </div>
        </div>
        <div className="bg-white py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="">
                  <h2 className="text-primary fw-bolder point-32">Headquarters</h2>
                  <p className="point-22">
                    2100 Southbridge Pkwy<br />
                    Birmingham, AL 35209<br />
                    <a href="mailto:info@contractorcn.com">info@contractorcn.com</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-4">
                      <div className="col">
                        <input
                          type="text"
                          className={`form-control border border-secondary rounded-0 ${errors.first_name ? "is-invalid" : ""
                            }`}
                          placeholder="First name"
                          aria-label="First name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleInputChange}
                        />
                        {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className={`form-control border border-secondary rounded-0 ${errors.last_name ? "is-invalid" : ""
                            }`}
                          placeholder="Last name"
                          aria-label="Last name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleInputChange}
                        />
                        {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <input
                          type="text"
                          className={`form-control border border-secondary rounded-0 ${errors.email ? "is-invalid" : ""
                            }`}
                          placeholder="Email"
                          aria-label="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && <div className="text-danger">{errors.email}</div>}
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className={`form-control border border-secondary rounded-0 ${errors.phone_number ? "is-invalid" : ""
                            }`}
                          placeholder="Phone Number"
                          aria-label="Phone Number"
                          name="phone_number"
                          value={formData.phone_number}
                          onChange={handleInputChange}
                        />
                        {errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <textarea
                          className={`form-control form-textarea border border-secondary rounded-0 ${errors.inquiry ? "is-invalid" : ""
                            }`}
                          aria-label="Default select example"
                          id="contactUsMessage"
                          name="inquiry"
                          placeholder="What is the nature of your inquiry?"
                          value={formData.inquiry}
                          onChange={handleInputChange}
                          maxLength={maxWords}
                        />
                        <small
                          id="wordCount"
                          className="font-sm float-end d-block align-right"
                        >
                          {wordCount}/{maxWords}
                        </small>
                        {errors.inquiry && <div className="text-danger">{errors.inquiry}</div>}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-secondary rounded-5 fw-bold text-uppercase point-18"
                    >
                      Contact Us
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    </>
  );
}
