import React from "react";
import shortCutImg from "../../../assets/short-cut-img.svg";
import calander from "../../../assets/calander.svg";
import profileSetting from "../../../assets/profile-setting.svg";
import resourceImg from "../../../assets/resource-img.svg";
import { useNavigate } from "react-router-dom";

export default function DashboardShortCuts() {
  const navigate = useNavigate();
  return (
    <>
      <div className="col-12 col-md-4">
        <div className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0">
          <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2">
            <div className="row g-0">
              <div className="col g-0">
                <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
                  Short Cuts
                </h3>
              </div>
              <div className="col-auto bg-secondary">
                <div className="rounded-pill bg-secondary w-100">
                  <div className="d-flex flex-column justify-content-center">
                    <img
                      src={shortCutImg}
                      alt="logo"
                      className="header-icon m-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="">
              <div className="container-fluid">
                <div className="row mb-4 g-2">
                  <div className="col-2">
                    <div className="text-left">
                      <div className="rounded-pill bg-secondary ratio ratio-1x1 w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="text-white fw-bold text-center text-uppercase point-10">
                            <img src={calander} alt="icon" className="w-50" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col text-primary text-uppercase fw-bold point-18">
                    <div className="h-100 d-flex align-items-center cursor-pointer">
                      <div
                        className="align-middle"
                        onClick={() => navigate("/calander")}
                      >
                        My Calendar
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4 g-2">
                  <div className="col-2">
                    <div className="text-left">
                      <div className="rounded-pill bg-secondary ratio ratio-1x1 w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="text-white fw-bold text-center text-uppercase point-10">
                            <img
                              src={profileSetting}
                              alt="icon"
                              className="w-50"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col text-primary text-uppercase fw-bold point-18">
                    <div className="h-100 d-flex align-items-center cursor-pointer">
                      <div
                        className="align-middle"
                        onClick={() => navigate("/profile")}
                      >
                        Profile Settings
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4 g-2">
                  <div className="col-2">
                    <div className="text-left">
                      <div className="rounded-pill bg-secondary ratio ratio-1x1 w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="text-white fw-bold text-center text-uppercase point-10 text-white">
                            <img
                              src={resourceImg}
                              alt="icon"
                              className="w-50"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col text-primary text-uppercase fw-bold point-18">
                    <div className="h-100 d-flex align-items-center cursor-pointer">
                      <div
                        className="align-middle"
                        onClick={() => navigate("/forms")}
                      >
                        Resources
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4 g-2">
                  <div className="col-2">
                    <div className="text-left">
                      <div className="rounded-pill bg-secondary ratio ratio-1x1 w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="text-white fw-bold text-center text-uppercase point-10 text-white">
                            <img
                              src={resourceImg}
                              alt="icon"
                              className="w-50"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col text-primary text-uppercase fw-bold point-18">
                    <div className="h-100 d-flex align-items-center cursor-pointer">
                      <div
                        className="align-middle"
                        onClick={() => navigate("/myprojects")}
                      >
                        My Projects
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clearfix text-secondary point-16 mb-2 fw-boldest pt-4">
                + Adjust shortcuts in
                <span
                  className="text-danger cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  &nbsp;profile settings.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
