
import { Modal } from 'react-bootstrap';
import AxiosInstance from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import loaderImg from '../../assets/loader.svg'
import moment from 'moment';

export default function PrimeContractorHelpModel({ open, handleClose, callId, ccnCallId }) {

    const [PrimeContractorHelpList, setPrimeContractorHelpList] = useState([])
    const [loader, setloader] = useState(false)

    useEffect(() => {
        getsetPrimeContractorHelp();
    }, [callId, ccnCallId]);

    const getsetPrimeContractorHelp = () => {
        const queryParams = new URLSearchParams({
            proposal_id: callId,
            ccn_call_id: ccnCallId,
        });
        setloader(true);
        AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_PRIME_ADVERTISE}&${queryParams}`)
            .then(response => {
                if (response.data?.data) {
                    setPrimeContractorHelpList(response.data?.data);
                    setloader(false);
                    toast.success(response.data?.message);
                } else {
                    setloader(false);
                    toast.success(response.data?.message);
                }
            })
            .catch(error => {
                toast.error(error?.message);
                setloader(false);
            });
    }


    return (
        <Modal
            size="xl"
            show={open} onHide={handleClose} centered
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton className="centered">
                <Modal.Title className="w-100 text-center">
                    Contractor Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-scroll'>
                <div className="container">
                    {!loader ?
                        <div className="row">
                            <ul className="list-group py-3 point-16">
                                {PrimeContractorHelpList.length && PrimeContractorHelpList ? PrimeContractorHelpList
                                    .map((data, i) => (
                                        <li className="list-group-item mb-3 bg-light border border-1 rounded-0 cursor-pointer" key={i}>
                                            <strong>Contractor:&nbsp;{data?.company_name || '-'}</strong>
                                            <div ><strong>Contact:&nbsp;</strong> {data?.first_name} {data?.last_name}</div>
                                            <div ><strong >Address:&nbsp;</strong> {data?.street_address} {data?.city}{','} {data?.state} </div>
                                            <div ><strong >Phone:&nbsp;</strong> {data?.phone_number || '-'} <strong>ZIP Code:&nbsp;</strong> {data?.zip}</div>
                                            <p><strong>Email:&nbsp;</strong> <a href={data?.email} className="text-blue-500">{data?.email}</a></p>

                                            <strong>Services Needed:&nbsp;</strong>
                                            <p className="text-gray-600">{data?.call_items_description.replace(/&quot;/g, '')}</p>
                                            <strong>Requirements:&nbsp;</strong>
                                            <p className="text-gray-600">{data?.requirements}</p> 
                                            <p><strong>Due Date:&nbsp;</strong> {moment(data?.due_date,"YYYY-MM-DD").format("MM-DD-YYYY")}</p>
                                        </li>
                                    )) :
                                    <div className="d-md-flex justify-content-center">
                                        No Data Found.
                                    </div>
                                }
                            </ul>
                        </div> : <div className="loader">
                            <img src={loaderImg} alt="loading" />
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal >
    );
};