import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import paginationValue from "../common/paginationValue.json";
import Pagination from "../components/Layout/Pagination";
import { Link, useLocation } from "react-router-dom";
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";
import axios from "axios";
import AxiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";
import loader from "../assets/loader.svg";
import favoriteImg from "../assets/bookmark-favorite.svg";

import OptInModel from "../components/Model/OptInModel";
import ViewPlanHolderModel from "../components/Model/ViewPlanHolderModel";
import SeeRecordsModel from "../components/Model/SeeRecordsModel";
import AdvertiseForHelpModel from "../components/Model/AdvertiseForHelpModel";
import PrimeContractorHelpModel from "../components/Model/PrimeContractorHelpModel";

export default function PrivatePage() {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [workTypeId, setWorkTypeId] = useState("");
  const [resetTrue, setResetTrue] = useState(false);
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [List, setList] = useState([]);
  const [APICall, setAPICAll] = useState(false);
  const [favloader, setFavloader] = useState(false);
  const abortControllerRef = useRef(null);
  const [showloader, setloader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedState, setSelectedState] = useState(
    JSON.parse(localStorage.getItem("selectedState")) || null
  );

  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpenViewPlan, setModdelOpenViewPlan] = useState(false);
  const [modelOpenOptIN, setModelOpenOptIN] = useState(false);
  const [modelSeeRecords, setModelSeeRecords] = useState(false);
  const [modelAdvertiseForHelp, setModelAdvertiseForHelp] = useState(false);
  const [PrimeContractorHelp, setModelPrimeContractorHelp] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedCcnCallId, setSelectedCcnCallId] = useState("");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleReset = () => {
    setResetTrue(true);
    setWorkTypeId("");
    setSearchValue("");
  };

  useEffect(() => {
    if (currentPage > 0) {
      getDesignList();
    }
  }, [currentPage, workTypeId]);

  useEffect(() => {
    if (APICall) getDesignList();
  }, [APICall]);

  const getDesignList = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setAPICAll(false);
    setloader(true);

    const controller = new AbortController();
    abortControllerRef.current = controller;
    const queryParams = new URLSearchParams({
      page_type: "PRIVATE",
      page: currentPage,
      limit: pageLimit,
      report_id: workTypeId,
    });

    const currentTime = new Date().getTime();
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_PROJECT}?currentTime=${currentTime}&${queryParams}`,
      { signal: controller.signal }
    )
      .then((response) => {
        if (response.data?.data?.length > 0) {
          setList(response.data.data);
          setPageLimit(response.data.limit);
          setTotalRecords(response.data.total_records);
          setTotalPages(response.data.total_pages);
          setCurrentPage(response.data.page);
          toast.success(response.data.message);
        } else {
          setList([]);
          toast.success("No projects found.");
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          toast.error(error?.message);
        }
      })
      .finally(() => setloader(false));
  };

  const addFav = (account_Id, favorite) => {
    setFavloader(true);
    const payload = {
      project_id: account_Id,
      type: "PRIVATE",
      action: favorite,
      state_id: selectedState?.value,
    };

    AxiosInstance.post(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_FAVORITE}`,
      payload
    )
      .then((response) => {
        setFavloader(false);
        toast.success(response.data?.message);
        // getDirectoryList();
        setList((prevList) =>
          prevList.map((item) =>
            item.project_id === account_Id
              ? { ...item, favorites: item.favorites === "0" ? "1" : "0" }
              : item
          )
        );
      })
      .catch((error) => {
        toast.error(error?.message);
        setFavloader(false);
      });
  };

  const handleAPIcall = () => {
    setAPICAll(true);
  };

  const handleModelOpenOptIN = (callid, ccnCallId) => {
    setAPICAll(false);
    //    setSelectedData(callid);
    // setSelectedCcnCallId(ccnCallId);
    setModelOpenOptIN(true);
  };

  const handleModelOpenSeeRecords = (callid, ccnCallId) => {
    //   setSelectedData(callid);
    //   setSelectedCcnCallId(ccnCallId);
    setModelSeeRecords(true);
  };

  const handleModelOpenAdvertiseForHelp = (callId, ccnCallId) => {
    setModelAdvertiseForHelp(true);
    //  setSelectedCcnCallId(ccnCallId);
    //  setSelectedData(callId);
  };
  const handleModelOpenPrimeContractorHelpModel = (ccnCallId, callId) => {
    //  setSelectedCcnCallId(ccnCallId);
    //    setSelectedData(callId);
    setModelPrimeContractorHelp(true);
  };

  const handleModelClose = () => setModelOpen(false);
  const handleModelCloseViewPlan = () => setModdelOpenViewPlan(false);
  const handleModelCloeseOpenOptIN = () => {
    setModelOpenOptIN(false);
  };
  const handleModelCloeseSeeRecords = () => setModelSeeRecords(false);
  const handleModelCloeseAdvertiseForHelp = () => {
    setModelAdvertiseForHelp(false);
  };

  const handleModelCloesePrimeContractorHelp = () =>
    setModelPrimeContractorHelp(false);

  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <p className="text-white point-22">Bid Opportunities</p>
              <h1 className="text-secondary fw-boldest point-42">Private</h1>
            </div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-12 col-md-auto">
                      <span
                        className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
                        id="basic-addon1"
                      >
                        Filter:
                      </span>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="input-group mb-3">
                        <div style={{ width: "100%" }}>
                          <WorkTypeFilter
                            onSelectWorkType={handleWorkTypeSelect}
                            reset={resetTrue}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col"></div>
                    <div class="col-12 col-md-5">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control fw-bolder text-white bg-secondary rounded-0 border border-1 border-secondary placeholder-white point-18"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="button-addon2"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <button
                          className="btn btn-primary text-secondary rounded-0 border-0"
                          type="button"
                          id="button-addon2"
                        >
                          <span class="point-20">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21.208"
                              height="22.989"
                              viewBox="0 0 21.208 22.989"
                            >
                              <g
                                id="Group_35"
                                data-name="Group 35"
                                transform="translate(-1193.792 -328)"
                              >
                                <g
                                  id="Ellipse_15"
                                  data-name="Ellipse 15"
                                  transform="translate(1199 328)"
                                  fill="none"
                                  stroke="#9fafca"
                                  strokeWidth="3"
                                >
                                  <circle cx="8" cy="8" r="8" stroke="none" />
                                  <circle cx="8" cy="8" r="6.5" fill="none" />
                                </g>
                                <path
                                  id="Path_120"
                                  data-name="Path 120"
                                  d="M1208.54,5578.4l-6.2,7.762"
                                  transform="translate(-6.44 -5237.284)"
                                  fill="none"
                                  stroke="#9fafca"
                                  strokeLinecap="round"
                                  strokeWidth="3"
                                />
                              </g>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md mb-2">
                      <div className="input-group mb-3">
                        <button
                          className="form-control btn btn-link text-uppercase me-2 text-danger text-start"
                          onClick={() => handleReset()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix">
                    <span className="float-end">
                      <span className="point-12 text-black-50 fw-light">
                        <span className="point-18">
                          <i className="bi bi-star fs-6"></i>
                        </span>
                        <span className="text-uppercase fw-bold">
                          &nbsp;Save to Dashboard
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className="">
                    <ul className="list-group py-3">
                      {!showloader && !favloader ? (
                        List.length > 0 ? (
                          List.map((data, i) => (
                            <li
                              key={data.project_id}
                              className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer"
                            >
                              <div className="d-md-flex justify-content-between align-items-start">
                                <div className="me-auto mb-2">
                                  <span className="fw-bold point-22">
                                    {data.project_title}&nbsp;
                                  </span>
                                  <span className="point-22 text-black-50 fw-light mb-2">
                                    {data?.favorites === "0" ? (
                                      <i
                                        className="bi bi-star fs-6"
                                        onClick={() =>
                                          addFav(
                                            data?.project_id,
                                            data?.favorites
                                          )
                                        }
                                      ></i>
                                    ) : (
                                      <img
                                        src={favoriteImg}
                                        alt="fav"
                                        onClick={() =>
                                          addFav(
                                            data?.project_id,
                                            data?.favorites
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                </div>
                                <div className="mb-2">
                                  <a
                                    className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                                    href={
                                      data.download_url
                                        ? data.download_url
                                        : "#"
                                    }
                                    target={
                                      data.download_url ? "_blank" : "_self"
                                    }
                                    rel="noopener noreferrer"
                                  >
                                    Scope of Work
                                  </a>
                                </div>
                              </div>
                              <p className="lh-lg">
                                <span className="fw-bold">DBE Goal:</span>{" "}
                                {data.dbe_goal}% <br />
                                {data.date_entered &&
                                  data.estimated_end_date && (
                                    <>
                                      <span className="fw-bold">
                                        Open Date:
                                      </span>{" "}
                                      {new Date(
                                        data.date_entered
                                      ).toLocaleDateString("en-US")}
                                      <span className="text-danger fw-bolder">
                                        &nbsp;|&nbsp;
                                      </span>
                                      <span className="fw-bold">
                                        Close Date:
                                      </span>{" "}
                                      {new Date(
                                        data.estimated_end_date
                                      ).toLocaleDateString("en-US")}
                                      <br />
                                    </>
                                  )}
                                {data.description && (
                                  <>
                                    <span className="fw-bold">
                                      Description:
                                    </span>{" "}
                                    {data.description}
                                    <br />
                                  </>
                                )}
                                <span className="fw-bold">
                                  Subs/Suppliers: Opt In
                                </span>
                                <span className="text-danger fw-bolder d-none d-md-inline-block">
                                  &nbsp;|&nbsp;
                                </span>
                                <br className="d-block d-md-none" />
                                <span className="fw-bold">
                                  See <span className="fw-bold">0</span> Opt-in
                                  Records
                                </span>
                                <span className="text-danger fw-bolder d-none d-md-inline-block">
                                  &nbsp;|&nbsp;
                                </span>
                                <br className="d-block d-md-none" />
                                <span className="fw-bold">
                                  Primes: Advertise for help
                                </span>
                                <span className="text-danger fw-bolder d-none d-md-inline-block">
                                  &nbsp;|&nbsp;
                                </span>
                                <br className="d-block d-md-none" />
                                <span className="fw-bold">
                                  <span className="fw-bold">0</span> Prime
                                  contractors looking for help
                                </span>
                              </p>
                            </li>
                          ))
                        ) : (
                          <div className="d-md-flex justify-content-center">
                            No Data Found.
                          </div>
                        )
                      ) : (
                        <div className="loader">
                          <img src={loader} alt="loading" />
                        </div>
                      )}
                    </ul>
                    {/* <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer">
                        <div className="d-md-flex justify-content-between align-items-start">
                          <div className="me-auto mb-2">
                            <span className="fw-bold point-22">
                              Project Name Here&nbsp;
                            </span>
                            <span className="point-22 text-black-50 fw-light mb-2">
                              <i className="bi bi-star fs-6"></i>
                            </span>
                          </div>
                          <div className="mb-2">
                            <a className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">
                              Scope of Work
                            </a>
                          </div>
                        </div>
                        <p className="lh-lg">
                          <span className="fw-bold">DBE Goal:</span> 18.1%
                          <span className="text-danger fw-bolder d-none d-md-inline-block">
                            &nbsp;|&nbsp;
                          </span>
                          <br className="d-block d-md-none" />
                          <span className="fw-bold">Call:</span> 001
                          <span className="text-danger fw-bolder d-none d-md-inline-block">
                            &nbsp;|&nbsp;
                          </span>
                          <br className="d-block d-md-none" />
                          <span className="fw-bold text-decoration-underline">
                            List of Bid Items
                          </span>
                          <span className="text-danger fw-bolder">
                            &nbsp;|&nbsp;
                          </span>
                          <span className="fw-bold text-decoration-underline">
                            View Plan Holders
                          </span>
                          <br />
                          <span className="fw-bold">Description:</span> Lorem
                          Ipsum is simply dummy text of the printing and
                          typesetting industry.
                          <br />
                          <span className="fw-bold">
                            Subs/Suppliers: Opt In
                          </span>
                          <span className="text-danger fw-bolder d-none d-md-inline-block">
                            &nbsp;|&nbsp;
                          </span>
                          <br className="d-block d-md-none" />
                          <span>
                            See <span className="fw-bold">0</span> Opt-in
                            Records
                          </span>
                          <br className="d-block d-md-none" />
                          <span className="fw-bold">
                            Primes: Advertise for help
                          </span>
                          <span className="text-danger fw-bolder d-none d-md-inline-block">
                            &nbsp;|&nbsp;
                          </span>
                          <br className="d-block d-md-none" />
                          <span>
                            <span className="fw-bold">0</span> Prime contractors
                            looking for help
                          </span>
                        </p>
                      </li> */}
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageLimit={pageLimit}
                    onPageChange={handlePageChange}
                  />
                  {modelOpenOptIN && (
                    <OptInModel
                      open={modelOpenOptIN}
                      ccnCallId={selectedCcnCallId}
                      callId={selectedData}
                      handleClose={handleModelCloeseOpenOptIN}
                      handleAPIcall={handleAPIcall}
                    />
                  )}

                  {modelOpenViewPlan && (
                    <ViewPlanHolderModel
                      open={modelOpenViewPlan}
                      handleClose={handleModelCloseViewPlan}
                      callId={selectedData}
                    />
                  )}
                  {modelOpenOptIN && (
                    <OptInModel
                      open={modelOpenOptIN}
                      ccnCallId={selectedCcnCallId}
                      callId={selectedData}
                      handleClose={handleModelCloeseOpenOptIN}
                      handleAPIcall={handleAPIcall}
                    />
                  )}
                  {modelSeeRecords && (
                    <SeeRecordsModel
                      open={modelSeeRecords}
                      ccnCallId={selectedCcnCallId}
                      callId={selectedData}
                      handleClose={handleModelCloeseSeeRecords}
                    />
                  )}
                  {modelAdvertiseForHelp && (
                    <AdvertiseForHelpModel
                      open={modelAdvertiseForHelp}
                      handleClose={handleModelCloeseAdvertiseForHelp}
                      callId={selectedData}
                      ccnCallId={selectedCcnCallId}
                      handleAPIcall={handleAPIcall}
                    />
                  )}
                  {PrimeContractorHelp && (
                    <PrimeContractorHelpModel
                      open={PrimeContractorHelp}
                      callId={selectedData}
                      ccnCallId={selectedCcnCallId}
                      handleClose={handleModelCloesePrimeContractorHelp}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
