import React, { useState, useEffect } from "react";
import opportunityImg from "../../../assets/opportunity-img.svg";
import loader from "../../../assets/loader.svg";
import AxiosInstance from "../../../utils/axiosInstance"; // Adjust path if needed

const MySavedOpportunitiesComponent = ({ selectedState, storedState }) => {
  const [allFav, setAllFav] = useState([]);
  const [favLoader, setFavLoader] = useState(false);

  const getAllFavorite = (state) => {
    setFavLoader(true);
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_ALL_FAVORITES}?state_id=${state.value}`
    )
      .then((response) => {
        if (response.data?.data) {
          setAllFav(response.data?.data);
        } else {
          setAllFav([]);
        }
        setFavLoader(false);
      })
      .catch((error) => {
        console.error(error?.message);
        setFavLoader(false);
      });
  };

  useEffect(() => {
    let state = "";
    if (selectedState) {
      state = selectedState;
    } else {
      state = storedState;
    }

    if (state) {
      //    getAllFavorite(state);
      getAllFavorite(state);
    }
  }, [selectedState, storedState]);

  return (
    <div className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0">
      <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2">
        <div className="row g-0">
          <div className="col g-0">
            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
              My Saved Opportunities
            </h3>
          </div>
          <div className="col-auto bg-secondary">
            <div className="rounded-pill bg-secondary w-100">
              <div className="d-flex flex-column justify-content-center">
                <img
                  src={opportunityImg}
                  alt="icon"
                  className="header-icon m-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-3">
        <div className="">
          {!favLoader ? (
            <ul className="list-group py-0 mh-20 pe-2">
              {allFav.length && allFav ? (
                allFav.map((data, i) => (
                  <li
                    className="list-group-item p-0 mb-2 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0"
                    key={i}
                  >
                    <div className="container-fluid g-0">
                      <div className="row m-0 g-0">
                        <div className="col p-2">
                          <div className="point-16 lh-sm text-primary fw-bold">
                            Worktype : {data?.county || "-"}
                          </div>
                          <div className="point-16 lh-sm text-primary">
                            Project Goal : {data?.dbe_goal_c || "-"}
                          </div>
                          <div className="point-16 lh-sm text-primary">
                            {data?.Ldescription || "-"}
                          </div>
                        </div>
                        <div className="col-auto">
                          {data?.type && (
                            <a
                              className="red-arrow-bg p-4 d-block h-100 w-100 text-decoration-none"
                              href={data?.type === "DOT" ? "/dot" : null}
                            >
                              &nbsp;
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="d-md-flex justify-content-center">
                  No Data Found.
                </div>
              )}
            </ul>
          ) : (
            <div className="loader">
              <img src={loader} alt="loading" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MySavedOpportunitiesComponent;
