import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import { useState } from "react";
import toast from "react-hot-toast";
import AxiosInstance from "../../utils/axiosInstance";
import { setLocalStoarge } from "../../common/allFunctions";

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setshowLoader] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const { email, password } = inputValues;
    const newErrors = {};
    let Emailregex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (!email.trim()) newErrors.email = "Email is required.";
    if (!password.trim()) newErrors.password = "password is required.";
    if (!email.match(Emailregex)) {
      newErrors.email = "Email is not valid..";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const do_Login = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    try {
      const { email, password } = inputValues;
      if (validateForm()) {
        const payload = {
          username: email,
          password: password,
        };
        const response = await AxiosInstance.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_LOGIN}`,
          payload
        );
        localStorage.removeItem("loggedInUserInfo");
        if (response && response?.data) {
          toast.success(response.data?.message);
          setIsAuthenticated(true);
          localStorage.setItem("isAuthenticated", "true");
          setLocalStoarge("accessToken", response.data?.token);
          setLocalStoarge(
            "loggedInUserInfo",
            JSON.stringify(response.data?.data)
          );
          const userType = response.data?.data.user_type;

          if (userType?.toUpperCase() === "WAH") {
            navigate("/wah");
          } else if (userType?.toUpperCase() === "CCN") {
            navigate("/ccn");
          } else {
            navigate("/dashboard");
          }
        } else {
          toast.error(
            response.data?.message || "Login failed. Please try again."
          );
        }
        setshowLoader(false);
      }
    } catch (error) {
      if (error.data) {
        toast.error(error.data?.message || "Login failed. Please try again.");
      }
    } finally {
      setshowLoader(false);
    }
  };

  return (
    <>
      <div className="vh-100 d-flex flex-column">
        <div className="bg-image">
          <Header />
          <div className="container">
            <div className="row mt-15">
              <div className="col col-md-auto bg-primary">
                <h1 className="text-white p-3 fw-boldest point-42">Login</h1>
              </div>
              <div className="col">&nbsp;</div>
            </div>
          </div>
        </div>
        <div className="bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 bg-white">
                <form className="p-5" onSubmit={(e) => do_Login(e)}>
                  <div className="col">
                    <input
                      type="text"
                      id="email"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Email"
                      aria-label="Email"
                      name="email"
                      onChange={handleChange}
                    />
                    {!!errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                  <a
                    href="#"
                    className="float-end text-danger point-16 fw-light"
                  >
                    Forgot
                  </a>
                  <div className="col">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control border border-secondary rounded-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      aria-label="Password"
                      name="password"
                      onChange={handleChange}
                    />
                    <span
                      className="float-end point-16 fw-light"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer", margin: "-6% 2%" }}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye"></i>
                      ) : (
                        <i className="bi bi-eye-slash"></i>
                      )}
                    </span>

                    {!!errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </div>
                  <p className="my-3">
                    <span className="text-secondary point-16 fw-bolder">
                      New Here?
                    </span>
                    &nbsp;
                    <Link
                      to="/signup"
                      className="text-danger text-decoration-none"
                    >
                      Sign Up.
                    </Link>
                  </p>
                  <button type="submit" className="btn btn-primary">
                    Login{" "}
                  </button>
                  <div>
                    {loading && (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>

                  <p className="mt-3 point-16 text-secondary">
                    CC Collects And Uses Personal Data In Accordance With Our
                    Privacy Policy. By Creating An Account, You Agree To Our
                    &nbsp;
                    <Link to="/termsandcondition">Terms & Conditions</Link>.
                  </p>
                </form>
              </div>
              <div className="col col-md-6">
                <div className="p-5">
                  <h1 className="text-white fw-boldest my-5 py-5 point-52">
                    Search Less.
                    <br />
                    Bid More.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
