import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import DashboardShortCuts from "../components/dashboardAndWAHComponent/common/DashboardShortCuts";
import wahLogo from "../assets/WAH-Logo-ALDOT-Approved.png";
import { useEffect, useRef, useState } from "react";
import BidOpportunityComponent from "../components/dashboardAndWAHComponent/common/BidOpportunityComponent";
import DashboardAndWAHDirectoryComponent from "../components/Common/DirectoryComponent";
import MonitoringReports from "../components/dashboardAndWAHComponent/wah/MonitoringReportComponent";
import DbrPerformancePlanComponent from "../components/dashboardAndWAHComponent/wah/DbrPerformancePlanComponent";
export default function WAH() {
  const [selectedState, setSelectedState] = useState();
  const [storedState, setstoredState] = useState(
    JSON.parse(localStorage.getItem("selectedState"))
  );
  const [requestApproved, setrequestApproved] = useState(
    JSON.parse(localStorage.getItem("loggedInUserInfo")).status_request
  );

  const transformData = (items) =>
    items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("loggedInUserInfo") || "[]");
    const transformedData = transformData(data?.state || []);
    if (!storedState && transformedData.length > 0) {
      localStorage.setItem("selectedState", JSON.stringify(transformedData[0]));
      setSelectedState(transformedData[0]);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">
                My Dashboard
              </h1>
            </div>
            <div className="col-6 col-md-auto text-end">
              <img src={wahLogo} />
            </div>
            <div className="col-6 col-md-auto text-end pt-md-4">
              <span className="text-secondary fw-bolder text-uppercase point-16 me-md-3">
                User Profile
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3 clearfix">
        <div className="container mt-n20">
          <div className="row">
            <div className="col-12 col-md-8 pb-4">
              <DbrPerformancePlanComponent />
            </div>
            <DashboardShortCuts />
          </div>
          <div className="row">
            <div className="col col-md-4">
              <DashboardAndWAHDirectoryComponent />
            </div>
            <div className="col col-md-8">
              <BidOpportunityComponent type="WAH" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <MonitoringReports />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
