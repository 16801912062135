
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Pagination from '../Layout/Pagination';
import AxiosInstance from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import loader from '../../assets/loader.svg'
import paginationValue from '../../common/paginationValue.json';

export default function ViewPlanHolderModel({ open, handleClose ,callId}) {
  const [search, setSearch] = useState("");
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [planHoldersList, setPlanHoldersList] = useState([]);
  const [showloader, setloader] = useState(false);
  useEffect(() => {
    if(callId){
      getPlanHoldersList();
    }
  }, [callId,currentPage,search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const getPlanHoldersList = () => {
    const queryParams = new URLSearchParams({
      callid: callId,
      page: currentPage,
      limit: pageLimit,
      search_name: search
    });
    setloader(true);
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_GET_PLANHOLDERS}?${queryParams}`)
      .then(response => {
        if (response.data?.data) {
          setPlanHoldersList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setPlanHoldersList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch(error => {
        toast.error(error?.message);
        setloader(false);
      });
  }
  return (
    <Modal
      size="xl"
      show={open} onHide={handleClose} centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className="container mt-4 listBigModel">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              type="text"
              className="form-control w-25"
              placeholder="Search By Name"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          {!showloader ? <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>Name</th>
                <th>State</th>
                <th>Email ID</th>
                <th>Phone Number</th>

              </tr>
            </thead>
            <tbody>
              {planHoldersList.length > 0 ? (
                planHoldersList.map((row, index) => (
                  <tr key={index}>
                    <td>{row.name}</td>
                    <td>{row.state}</td>
                    <td>{row.email_id}</td>
                    <td>{row.phone_office}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>: (<div className="loader">
            <img src={loader} alt="loading" />
          </div>)}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onPageChange={handlePageChange}
        />
      </Modal.Body>
    </Modal>
  );
};