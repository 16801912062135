import React, { useEffect, useState } from 'react';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import Select from 'react-select';
import AxiosInstance from '../../utils/axiosInstance';
import toast from "react-hot-toast";
import { Link } from 'react-router-dom'

const Signup = () => {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [highway, setHighway] = useState([]);
    const [building, setBuilding] = useState([]);
    const [options, setOptions] = useState([]);
    const [workTypeList, setWorkTypeList] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        company_name: "",
        password: "",
        confirm_password: "",
        company_description: "",
        company_type: "",
        worktypes: {},
        subscribe_id: "",
        state_id: []
    });
    const [errors, setErrors] = useState({});
    const [subscribe, setSubscribe] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [selectedState, setSelectedState] = useState([]);

    useEffect(() => {
        const fetchAPIs = async () => {
            try {
                getWorkType();
                getSubscribe();
                getState();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
            }
        };

        fetchAPIs();
    }, []);

    useEffect(() => {
        highway.sort((a, b) => a.label.localeCompare(b.label))
        building.sort((a, b) => a.label.localeCompare(b.label))
        options.sort((a, b) => a.label.localeCompare(b.label))
    }, [options]);

    const getWorkType = () => {

        AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_WORKTYPE_API}`)
            .then(response => {
                setWorkTypeList(response.data?.data)
                setHighway(transformData(response.data?.data?.highway));
                setBuilding(transformData(response.data?.data?.building));
                setOptions([
                    ...transformData(response.data?.data?.highway),
                    ...transformData(response.data?.data?.building),
                ])
            })
            .catch(error => {
                console.log(error?.message);
            });
    }

    const getSubscribe = () => {
        AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_SUBSCRIPTION_API}`)
            .then(response => {
                if (response.data?.data) {
                    setSubscribe(response.data?.data);
                }
            })
            .catch(error => {
                console.log(error?.message);
            });
    }

    const getState = () => {
        AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_STATE_API}`)
            .then(response => {
                if (response?.data?.data)
                    setStateList(transformData(response?.data?.data));
            })
            .catch(error => {
                console.log(error?.message);
            });
    }

    const transformData = (items) =>
        items.map(({ id, name }) => ({
            value: id,
            label: name,
        })
        );


    const handleChange = (selected) => {
        const workTypeData = transformWorkTypeData(workTypeList, selected);
        setFormData((prevData) => ({ ...prevData, 'worktypes': workTypeData }));
        setSelectedOptions(selected);
    };

    const handleStateChange = (selected) => {
        const stateData = selected.map(item => item.value);
        setFormData((prevData) => ({ ...prevData, 'state_id': stateData }));
        setSelectedState(selected);
    };

    const transformWorkTypeData = (originalData, selectedValues) => {
        const selectedIds = selectedValues.map(item => item.value);

        const result = Object.keys(originalData).reduce((acc, key) => {
            acc[key] = originalData[key]
                .filter(item => selectedIds.includes(item.id))
                .map(item => item.id);
            return acc;
        }, {});

        return result;
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        // Handle "state_id" checkboxes
        if (name === "state_id") {
            setFormData((prevData) => {
                const currentIds = prevData[name] || [];
                const updatedIds = checked
                    ? [...currentIds, value] // Add the checked value
                    : currentIds.filter((id) => id !== value); // Remove the unchecked value

                return { ...prevData, [name]: updatedIds };
            });
        } else if (name === "company_type") {
            // Handle "company_type" logic
            setFormData((prevData) => ({ ...prevData, [name]: value }));

            if (value === "1") {
                setOptions(highway);
            } else if (value === "2") {
                setOptions(building);
            } else {
                setOptions([...highway, ...building]);
            }
        } else {
            // Generic handler
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }));

    };

    const validateForm = () => {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@*()_+\-=[\]{};':"\\|,.<>/?!]).{8,}$/;

        const newErrors = {};
        if (!formData.first_name.trim()) newErrors.first_name = "First name is required.";
        if (!formData.last_name.trim()) newErrors.last_name = "Last name is required.";
        if (!formData.email.trim()) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format.";
        }
        if (!formData.phone_number.trim()) newErrors.phone_number = "Phone number is required.";
        else if (!/^(\+1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formData.phone_number)) {
            newErrors.phone_number = "Invalid phone number format.";
        }
        if (!formData.password.trim()) newErrors.password = "password is required.";
        if (!formData.confirm_password.trim()) newErrors.confirm_password = "Confirm password is required.";
        if (formData.password.trim() !== formData.confirm_password.trim()) {
            newErrors.confirm_password = "Password & confirm password not matched.";
        }
        if (!formData.company_name.trim()) newErrors.company_name = "Company name is required.";
        if (!formData.company_description.trim()) newErrors.company_description = "Company description is required.";
        if (!formData.company_type.trim()) newErrors.company_type = "Company description is required.";
        if (!formData.password.match(passwordRegex)) newErrors.password = "password is not Strong!";
        return newErrors;
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});
        const formValue = formData;
        delete formValue.confirm_password;

        AxiosInstance.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REGISTRATION}`, formValue)
            .then(response => {
                if (response?.data) {
                    toast.success(response?.data?.message);
                    setFormData({
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone_number: "",
                        company_name: "",
                        password: "",
                        confirm_password: "",
                        company_description: "",
                        company_type: "",
                        worktypes: {},
                        subscribe_id: "",
                        state_id: []
                    });
                    window.location.href = "/login";
                } else {
                    toast.error(response?.data?.message);
                    setFormData({
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone_number: "",
                        company_name: "",
                        password: "",
                        confirm_password: "",
                        company_description: "",
                        company_type: "",
                        worktypes: {},
                        subscribe_id: "",
                        state_id: []
                    });
                }
            })
            .catch(error => {
                toast.error(error?.data?.message);
                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    company_name: "",
                    password: "",
                    confirm_password: "",
                    company_description: "",
                    company_type: "",
                    worktypes: {},
                    subscribe_id: "",
                    state_id: []
                });
            });
    };

    const togglePasswordVisibility = (value) => {
        if (value) {
            setShowConfirm((prevState) => !prevState);
        } else {
            setShowPassword((prevState) => !prevState);
        }
    };
    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible);
    };

    return (
        <>
            <div className="vh-100 d-flex flex-column">
                <div className="bg-image">
                    <Header />
                    <div className="container">
                        <div className="row mt-15">
                            <div className="col col-md-auto bg-primary">
                                <h1 className="text-white p-3 fw-boldest point-42">Sign Up</h1>
                            </div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                </div>
                <div className="bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col col-md-6 bg-white py-5">
                                <form className="px-3" onSubmit={handleSubmit}>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.first_name ? "is-invalid" : ""
                                                    }`}
                                                placeholder="First name"
                                                aria-label="First name"
                                                name="first_name"
                                                value={formData.first_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                        </div>
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.last_name ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Last name"
                                                aria-label="Last name"
                                                name="last_name"
                                                value={formData.last_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.email ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Email"
                                                aria-label="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && <div className="text-danger">{errors.email}</div>}
                                        </div>
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.phone_number ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Phone Number"
                                                aria-label="Phone Number"
                                                name="phone_number"
                                                value={formData.phone_number}
                                                onChange={handleInputChange}
                                            />
                                            {errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                className={`form-control border border-secondary rounded-0 ${errors.password ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Password"
                                                aria-label="Password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                autoComplete="on"
                                            />
                                            <span
                                                className="float-end point-16 fw-light"
                                                style={{ cursor: "pointer", margin: "-12% 2%", display: "flex" }}
                                            >
                                                {!errors.password && (showPassword ? <i className="bi bi-eye" onClick={() => togglePasswordVisibility()}></i>
                                                    : <i className="bi bi-eye-slash" onClick={() => togglePasswordVisibility()}></i>)}
                                                {!errors.password && <i
                                                    className="bi bi-info-circle ms-2 info-icon"
                                                    onMouseEnter={toggleTooltip}
                                                    onMouseLeave={toggleTooltip}
                                                ></i>}
                                            </span>

                                            {tooltipVisible && (
                                                <div className="password-tooltip">
                                                    <strong>Password Requirements:</strong>
                                                    <ul>
                                                        <li>At least 8 characters</li>
                                                        <li>One uppercase letter</li>
                                                        <li>One lowercase letter</li>
                                                        <li>One number</li>
                                                        <li>One special character (!@#$%^&*)</li>
                                                    </ul>
                                                </div>
                                            )}
                                            {errors.password && <div className="text-danger">{errors.password}</div>}
                                        </div>
                                        <div className="col">
                                            <input
                                                type={showConfirm ? "text" : "password"}
                                                className={`form-control border border-secondary rounded-0 ${errors.confirm_password ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Confirm Password"
                                                aria-label="Confirm Password"
                                                name="confirm_password"
                                                value={formData.confirm_password}
                                                onChange={handleInputChange}
                                                autoComplete="on"
                                            />
                                            <span
                                                className="float-end point-16 fw-light"
                                                onClick={() => togglePasswordVisibility(true)}
                                                style={{ cursor: "pointer", margin: "-12% 2%" }}
                                            >
                                                {!errors.confirm_password &&
                                                    (showConfirm ? <i className="bi bi-eye"></i>
                                                        : <i className="bi bi-eye-slash"></i>)
                                                }
                                            </span>
                                            {errors.confirm_password && <div className="text-danger">{errors.confirm_password}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.company_name ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Company Name"
                                                aria-label="Company Name"
                                                name="company_name"
                                                value={formData.company_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.company_name && <div className="text-danger">{errors.company_name}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <textarea
                                                placeholder="Brief Company Description"
                                                className={`form-control form-textarea border border-secondary rounded-0 ${errors.company_description ? "is-invalid" : ""
                                                    }`}
                                                name="company_description"
                                                value={formData.company_description}
                                                onChange={handleInputChange}
                                            ></textarea>
                                            {errors.company_description && <div className="text-danger">{errors.company_description}</div>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md mb-4">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0`}
                                                placeholder="Street"
                                                aria-label="billing_address_street"
                                                name="billing_address_street"
                                                // value={formData.billing_address_street}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-12 col-md mb-4">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 `}
                                                placeholder="City"
                                                aria-label="billing_address_city"
                                                name="billing_address_city"
                                                // value={formData.billing_address_city}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md mb-4">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 `}
                                                placeholder="State"
                                                aria-label="billing_address_state"
                                                name="billing_address_state"
                                                // value={formData.billing_address_state}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-12 col-md mb-4">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0`}
                                                placeholder="Zip"
                                                aria-label="billing_address_postalcode"
                                                name="billing_address_postalcode"
                                                // value={formData.billing_address_postalcode}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <select
                                                defaultValue=""
                                                className={`form-select border border-secondary rounded-0 ${errors.company_type ? "is-invalid" : ""
                                                    }`}
                                                aria-label="Default select example"
                                                onChange={handleInputChange}
                                                name="company_type"
                                            >
                                                <option defaultValue="">Company Type</option>
                                                <option value="1">Highway</option>
                                                <option value="2">Building</option>
                                                <option value="3">Both</option>
                                            </select>
                                            {errors.company_type && <div className="text-danger">{errors.company_type}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                <span className="fw-bold point-16 text-uppercase">Select Work Type</span>
                                            </label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: '#9FAFCA'
                                                    }),
                                                    dropdownIndicator: (provided, state) => ({
                                                        ...provided,
                                                        color: '#a71e1e',
                                                    })
                                                }}
                                                options={options}
                                                isMulti
                                                value={selectedOptions}
                                                onChange={handleChange}
                                                placeholder="Select work type..."
                                                isSearchable
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <h3
                                                className="text-secondary fw-bolder text-uppercase point-16"
                                            >
                                                SUBSCRIBE:
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        {subscribe.map((item) => (
                                            <div className="col" key={item.id}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input border border-1 border-secondary border-solid rounded-0 text-uppercase"
                                                        type="radio"
                                                        value={item.id}
                                                        name="subscribe_id"
                                                        id={`flexCheckDefault-${item.id}`}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`flexCheckDefault-${item.id}`}
                                                    >
                                                        <span className="fw-bold point-16">{item.name.toUpperCase()}</span>
                                                    </label>
                                                    <br />
                                                    <p className="point-16 fw-light">{item?.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                <span className="fw-bold point-16 text-uppercase"
                                                >What states are you located in?</span>
                                            </label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: '#9FAFCA'
                                                    }),
                                                    dropdownIndicator: (provided, state) => ({
                                                        ...provided,
                                                        color: '#a71e1e'
                                                    })
                                                }}
                                                options={stateList}
                                                isMulti
                                                value={selectedState}
                                                onChange={handleStateChange}
                                                placeholder="Select states..."
                                                isSearchable
                                            />
                                        </div>
                                    </div>

                                    <p className="text-secondary fw-bolder point-16">
                                        Already have an account?&nbsp;
                                        <Link
                                            className="text-danger text-decoration-none"
                                            to="/login"
                                        >
                                            Login
                                        </Link>
                                    </p>

                                    <button
                                        type="submit"
                                        className="btn btn-secondary rounded-5 fw-semibold text-uppercase point-18"
                                    >
                                        Create account & continue
                                    </button>

                                    <p className="mt-3 point-16 text-secondary">
                                        CC Collects And Uses Personal Data In Accordance With Our
                                        Privacy Policy. By Creating An Account, You Agree To Our&nbsp;
                                        <Link
                                            to="/termsandcondition"
                                        >
                                            Terms & Conditions
                                        </Link>.
                                    </p>
                                </form>

                            </div>
                            <div className="col col-md-6" >
                                <div className="p-5">
                                    <h3 className="text-secondary fw-bold point-52" style={{wordBreak:'keep-all',wordWrap:'break-word'}}>
                                        Join the Community to Search Less & Bid More!
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Signup;