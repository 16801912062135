import React, { useState} from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Map from "../components/MapComponent";
import DirectoryList from "../components/directory/DirectoryList";
export default function DirectoryPage() {
  const [viewMode, setViewMode] = useState("list"); // "list" or "map"
  console.log(process.env.REACT_APP_GET_DIRECTORY_MAP)
  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <p className="text-white point-22"></p>
              <h1 className="text-secondary fw-boldest point-42">Directory</h1>
            </div>
            <div className="col text-end">&nbsp;</div>
            <span className="d-flex align-items-center gap-2 justify-content-end me-3 point-16">
              <span className="text-secondary fw-bolder text-uppercase">
                View as:
              </span>

              {/* List View Toggle */}
              <span
                className={`point-16 fw-bolder text-uppercase ${
                  viewMode === "list" ? "text-white" : "text-secondary"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => setViewMode("list")}
              >
                List
              </span>

              <span className="text-secondary fw-bolder text-uppercase">|</span>

              {/* Map View Toggle */}
              <span
                className={`point-16 fw-bolder text-uppercase ${
                  viewMode === "map" ? "text-white" : "text-secondary"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => setViewMode("map")}
              >
                Map
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white py-3">
        {viewMode === "list" ? (
          <DirectoryList />
        ) : (
         
          <Map EndPoint={process.env.REACT_APP_GET_DIRECTORY_MAP} />
        )}
        {/* */}
      </div>
      <Footer />
    </>
  );
}
