import Footer from "../components/Layout/Footer";
import opptunityImg from "../assets/opportunity-img.svg";
import Header from "../components/Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import DashboardShortCuts from "../components/dashboardAndWAHComponent/common/DashboardShortCuts";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { setLocalStoarge } from "../common/allFunctions";
import AxiosInstance from "../utils/axiosInstance";

import BidOpportunityComponent from "../components/dashboardAndWAHComponent/common/BidOpportunityComponent";
import DashboardAndWAHDirectoryComponent from "../components/Common/DirectoryComponent";
import MySavedContactsComponent from "../components/dashboardAndWAHComponent/dashboard/MySavedContactComponent";
import MySavedOpportunitiesComponent from "../components/dashboardAndWAHComponent/dashboard/MySavedOpportunityComponent";
export default function Dashboard() {
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState();
  const [allFav, setAllFav] = useState([]);
  const [allFavContact, setAllFavContact] = useState([]);
  const [StateListFromLocalStorage, setStateListFromLocalStorage] = useState(
    []
  );
  const isInitialRender = useRef(true);
  const [favLoader, setFavLoader] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [storedState, setstoredState] = useState(
    JSON.parse(localStorage.getItem("selectedState"))
  );

  const handleStateChange = (selected) => {
    setSelectedState(selected);
    isInitialRender.current = false;
  };
  const transformData = (items) =>
    items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));


  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("loggedInUserInfo") || "[]");
    const transformedData = transformData(data?.state || []);
    setStateListFromLocalStorage(transformedData);
    if (!storedState && transformedData.length > 0) {
      localStorage.setItem("selectedState", JSON.stringify(transformedData[0]));
      setSelectedState(transformedData[0]);
    }
  }, []);

  useEffect(() => {
    if (!isInitialRender.current && selectedState) {
      setLocalStoarge("selectedState", JSON.stringify(selectedState));
    }
  }, [selectedState]);

  useEffect(() => {
    if (storedState) {
      if (StateListFromLocalStorage.length > 0) {
        const selectedOption = StateListFromLocalStorage.find(
          (option) => option.label === storedState.label
        );
        setSelectedState(selectedOption || null);
      }
    }
  }, [StateListFromLocalStorage]);

 
  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">
                My Dashboard
              </h1>
            </div>
            <div
              className="col col-md-auto bg-primary mb-3"
              style={{ marginTop: "-15px" }}
            >
              <span className="fw-bold point-16  text-secondary">
                Select State
              </span>
              <Select
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                })}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,

                    "&:hover": {
                      borderColor: "#2684FF",
                    },
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#a71e1e",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#6c757d",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#9FAFCA" : "white",
                    color: state.isSelected ? "white" : "black",
                    "&:hover": {
                      backgroundColor: "#9FAFCA",
                      color: "white",
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    borderRadius: 0,
                  }),
                }}
                options={StateListFromLocalStorage}
                value={selectedState}
                onChange={handleStateChange}
                placeholder="Select a state..."
                isSearchable
              />
            </div>
            <div className="col-12 col-md text-md-end pt-md-4">
              <span className="text-secondary fw-bolder text-uppercase point-16 me-md-3">
                User Profile
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3 clearfix">
        <div className="container mt-n20">
          <div className="row">
            <div className="col-12 col-md-8">
              <MySavedOpportunitiesComponent selectedState={selectedState } storedState={storedState} />
            </div>
            <DashboardShortCuts />
          </div>
          <div className="row">
            <div className="col col-md-4">
              <DashboardAndWAHDirectoryComponent   />
            </div>
            <div className="col col-md-8">
              <BidOpportunityComponent />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <MySavedContactsComponent selectedState={selectedState } storedState={storedState}/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
