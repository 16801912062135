import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AxiosInstance from "../../utils/axiosInstance";

export default function SelectDesignBuildCategoryModal({
  open,
  handleClose,
  requestApproved,
}) {
  const [categories, setCategories] = useState([
    { label: "WAH", value: "DESIGN_BUILD_WAH" },
    { label: "MRB", value: "DESIGN_BUILD_MRB" },
    { label: "BAYWAY", value: "DESIGN_BUILD_WAYBAY" },
  ]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showloader, setloader] = useState(false);

  // Fetch categories from localStorage or API
  useEffect(() => {
    // const fetchCategories = async () => {
    //   setLoading(true); // Start loading
    //   try {
    //     const response = await AxiosInstance.get(
    //       `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_FETCH_DESIGNBUILD_CATEGORIES}`
    //     );
    //     if (response.data?.categories?.length) {
    //       setCategories(response.data.categories);
    //       // Update localStorage
    //       const userInfo =
    //         JSON.parse(localStorage.getItem("loggedInUserInfo")) || {};
    //       userInfo.designbuild_category = response.data.categories;
    //       localStorage.setItem("loggedInUserInfo", JSON.stringify(userInfo));
    //     }
    //   } catch (error) {
    //     console.error("Error fetching categories:", error);
    //     toast.error("Failed to load categories.");
    //   } finally {
    //     setLoading(false); // Stop loading
    //   }
    // };
    // // Check if categories exist in localStorage
    // const userInfo = JSON.parse(localStorage.getItem("loggedInUserInfo")) || {};
    // const storedCategories = userInfo.designbuild_category;
    // if (storedCategories?.length) {
    //   setCategories(storedCategories);
    // } else {
    //   fetchCategories();
    // }
  }, []);

  // API call to submit project request
  const handleSubmit = async () => {
    try {
      await AxiosInstance.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REQUEST_FOR_PROJECT_UPLOAD}`,
        { status_request: "pending", request_for: "design_build" }
      );

      requestApproved("pending");

      // Update localStorage
      const userInfo =
        JSON.parse(localStorage.getItem("loggedInUserInfo")) || {};
      userInfo.status_request = "pending";
      localStorage.setItem("loggedInUserInfo", JSON.stringify(userInfo));

      toast.success("Request submitted successfully");
      handleClose();
    } catch (error) {
      console.error("Error:", error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  // Navigate with selected category
  const handleCategorySelect = (selectedValue) => {
    const selectedCategory = categories.find(
      (cat) => cat.value === selectedValue
    );

    if (selectedCategory) {
      console.log(selectedCategory);
      handleClose();
      navigate("/designbuild", { state: { category: selectedCategory } });
    }
  };

  return (
    <Modal size="md" show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Design Build Project </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          {categories.length > 0 ? (
            categories.map((category, index) => (
              <Button
                key={category.value || index}
                variant="outline-primary"
                className="px-4 py-2 text-uppercase"
                onClick={() => handleCategorySelect(category.value)}
              >
                {category.label}
              </Button>
            ))
          ) : (
            <p className="text-center">Loading categories...</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
