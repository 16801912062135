import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import AxiosInstance from "../utils/axiosInstance";

export default function FormsPage() {
  const [resourceLink, setResourceLink] = useState([]);
  const [showloader, setloader] = useState(false);

  useEffect(() => {
    getResources();
  }, []);
  const getResources = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}/get_resources`)
      .then((response) => {
        if (response.data?.data) {
          setResourceLink(response.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.log("in catch block:", error);
      });
  };

  return (
    <>
      <Header />
      <div className="bg-primary py-3">
        <div className="container">
          <div className="row mt-10">
            <div className="col col-md-auto">
              <p className="text-white point-22">Bid Opportunities</p>
              <h1 className="text-secondary fw-boldest point-42">Resources</h1>
            </div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="bg-white py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="fw-bold point-32">Federal Resources</h2>
              <p className="point-18"></p>
            </div>
            <div className="col--12">
              <div className="" role="group" aria-label="Vertical button group">
                <button
                  type="button"
                  className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
                <button
                  type="button"
                  className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
                <button
                  type="button"
                  className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="fw-bold point-32">State Resources</h2>
              <p className="point-18">
              </p>
              <p>
              </p>
            </div>
            <div className="col-12">
              <div className="" role="group" aria-label="Vertical button group">
                <button
                  type="button"
                  className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  Personal net worth statement
                </button>
                <button
                  type="button"
                  className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  Dbe certification procedures
                </button>
                <button
                  type="button"
                  className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  No change affidavit
                </button>
                <button
                  type="button"
                  className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  Uniform certification application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="fw-bold point-32">
                Disadvantaged Business Enterprise Resources
              </h2>
              <p className="point-18"></p>
              <p></p>
            </div>
            <div className="col-12">
              <div className="" role="group" aria-label="Vertical button group">
                {resourceLink.length && resourceLink ? (
                  resourceLink.map((data, i) => (
                    <a
                      title={data?.name}
                      href={data?.resources_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                      style={{
                        width: "30%",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.name}
                    </a>
                  ))
                ) : (
                  <div className="d-md-flex justify-content-center">
                    No Links Found.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
