import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import paginationValue from "../common/paginationValue.json";
import Pagination from "../components/Layout/Pagination";
import axios from "axios";
import AxiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";
import loader from "../assets/loader.svg";

import OptInModel from "../components/Model/OptInModel";
import ViewPlanHolderModel from "../components/Model/ViewPlanHolderModel";
import SeeRecordsModel from "../components/Model/SeeRecordsModel";
import AdvertiseForHelpModel from "../components/Model/AdvertiseForHelpModel";
import PrimeContractorHelpModel from "../components/Model/PrimeContractorHelpModel";
import UpdateUploadRequestModel from "../components/Model/UpdateUploadRequestModel";

import { useNavigate } from "react-router-dom";
import { setLocalStoarge } from "../common/allFunctions";

export default function StateCityCountryPage() {
  const [selectedType, setSelectedType] = useState({
    value: "State",
    label: "State",
  });

  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [resetTrue, setResetTrue] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [APICall, setAPICAll] = useState(false);
  const [selectedState, setSelectedState] = useState(
    JSON.parse(localStorage.getItem("selectedState")) || null
  );
  const [showloader, setloader] = useState(false);
  const [List, setList] = useState([]);
  const abortControllerRef = useRef(null);
  // const ListDataFromLocalStorage = JSON.parse(
  // localStorage.getItem("workTypeList") || []
  // );

  const workTypeListRef = useRef(
    JSON.parse(localStorage.getItem("workTypeList") || "[]") || []
  );

  const getWorkType = () => {
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_WORKTYPE_HIGHWAY}`
    )
      .then((response) => {
        if (response.data?.data) {
          workTypeListRef.current = transformData(response.data?.data);
          setLocalStoarge(
            "workTypeList",
            JSON.stringify(transformData(response.data?.data))
          );
        }
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };

  if (workTypeListRef.current.length === 0) {
    getWorkType();
  }

  const navigate = useNavigate();

  const [updateData, setUpdateData] = useState({});

  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpenViewPlan, setModdelOpenViewPlan] = useState(false);
  const [modelOpenOptIN, setModelOpenOptIN] = useState(false);
  const [modelSeeRecords, setModelSeeRecords] = useState(false);
  const [modelAdvertiseForHelp, setModelAdvertiseForHelp] = useState(false);
  const [PrimeContractorHelp, setModelPrimeContractorHelp] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedCcnCallId, setSelectedCcnCallId] = useState("");

  const list = [
    {
      value: "State",
      label: "State",
    },
    {
      value: "County",
      label: "County",
    },
    {
      value: "City",
      label: "City",
    },
  ];

  const handleChange = (type) => {
    setSelectedType(type);
  };

  useEffect(() => {
    if (currentPage > 0) {
      getList();
    }
  }, [currentPage, selectedType]);

  useEffect(() => {
    if (APICall) getList();
  }, [APICall]);

  const getList = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setAPICAll(false);
    setloader(true);
    console.log(selectedType);
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const queryParams = new URLSearchParams({
      page_type:
        typeof selectedType?.value === "string"
          ? selectedType.value.toUpperCase()
          : "STATE",
      page: currentPage,
      limit: pageLimit,
    });

    const currentTime = new Date().getTime();
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_MY_PROJECTS}?currentTime=${currentTime}&${queryParams}`,
      { signal: controller.signal }
    )
      .then((response) => {
        if (response.data?.data) {
          setList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch((error) => {
        console.log("catch");

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") {
          // console.log("Aborted");
          //toast.error(error?.message);
        } else {
          toast.error(error?.message || "Something went wrong");
          setloader(false);
        }
      });
  };

  const handleAPIcall = () => {
    setAPICAll(true);
  };

  const handleModelOpen = (data) => {
    setAPICAll(false);
    setSelectedData(data);
    setModelOpen(true);
  };

  const handleModelClose = () => {
    setModelOpen(false);
    setAPICAll(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleReset = () => {
    setResetTrue(true);
    setSelectedType({
      value: "State",
      label: "State",
    });
  };

  function getProjectType(type) {
    console.log(type);
    switch (type) {
      case "DESIGN_BUILD_WAH":
        return "WAH";
      case "DESIGN_BUILD_MRB":
        return "MBR";
      case "DESIGN_BUILD_WAYBAY":
        return "BAYWAY";
      default:
        return "";
    }
  }

  const convertDateFormat = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined values

    // Extract only the date part before any space (YYYY-MM-DD HH:mm:ss)
    const datePart = dateString.split(" ")[0];

    // Split and rearrange to MM-DD-YYYY
    const [year, month, day] = datePart.split("-");
    return `${month}-${day}-${year}`;
  };

  const getLabelsFromWorktypes = (worktypes) => {
    console.log("Worktypes:", worktypes);

    // Ensure worktypes is a string before splitting
    const worktypeArray = Array.isArray(worktypes)
      ? worktypes // Already an array
      : worktypes?.split(",").map((id) => id.trim()) || [];

    console.log("WorkTypeList:", workTypeListRef.current); // Corrected reference

    if (worktypeArray.length === 0) return "Unknown";
    return worktypeArray
      .map(
        (id) =>
          workTypeListRef.current.find((item) => item.value === id)?.label ||
          "Unknown"
      )
      .join(", ");
  };
  const transformData = (items) =>
    items
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

  const deleteProject = (projectId, projectType) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setAPICAll(false);
    setloader(true);

    const controller = new AbortController();
    abortControllerRef.current = controller;

    AxiosInstance.post(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DELETE_MY_PROJECTS}`,
      {
        page_type: projectType,
        project_id: projectId,
      }, // Data (payload) should be the second argument
      { signal: controller.signal } // Config (headers, signal, etc.) should be the third argument
    )

      .then((response) => {
        if (response.status === 200) {
          // Remove deleted project from the list
          setList((prevList) =>
            prevList.filter((project) => project.project_id !== projectId)
          );

          // if(List.length == 1 && currentPage > 1){
          //   APICall(true)
          // }
          toast.success(
            response.data?.message || "Project deleted successfully"
          );
        } else {
          toast.error(response.data?.message || "Failed to delete project");
        }
      })
      .catch((error) => {
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") {
          console.log("Request aborted");
        } else {
          toast.error(error?.message || "Something went wrong");
        }
      })
      .finally(() => {
        setloader(false);
      });
  };

  const handleNavigate = (data) => {
    navigate("/uplodingRequest", { state: { data } }); // Passing data as state
  };
  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">
                MY PROJECTS
              </h1>
            </div>
            <div className="col text-end">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div className="card-body p-3">
                  {/* <div className="row">
                    <div className="col-12 col-md-auto">
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
                          id="basic-addon1"
                        >
                          Filter:
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md">
                      <div className="input-group mb-3">
                        <div style={{ width: "100%" }}>
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                            })}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: "#9FAFCA",
                                borderWidth: "3px",
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                boxShadow: "none",
                                "&:hover": {
                                  borderColor: "#2684FF",
                                },
                              }),
                              dropdownIndicator: (provided, state) => ({
                                ...provided,
                                color: "#a71e1e",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#6c757d",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#9FAFCA"
                                  : "white",
                                color: state.isSelected ? "white" : "black",
                                "&:hover": {
                                  backgroundColor: "#9FAFCA",
                                  color: "white",
                                },
                              }),
                              menu: (provided) => ({
                                ...provided,
                                backgroundColor: "#f8f9fa",
                                zIndex: 9999,
                                borderRadius: 0,
                              }),
                            }}
                            options={list}
                            value={selectedType}
                            onChange={handleChange}
                            reset={resetTrue}
                            placeholder="select any Option"
                            isSearchable
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md mb-2">
                      <div className="input-group mb-3">
                        <input
                          type="button"
                          className="form-control btn btn-link text-uppercase me-2 text-danger text-start"
                          placeholder="Reset"
                          aria-label="Reset"
                          value="Reset"
                          onClick={() => handleReset()}
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control fw-bolder text-white bg-secondary rounded-0 border border-1 border-secondary placeholder-white point-18"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="button-addon2"
                        />
                        <button
                          className="btn btn-primary text-secondary rounded-0 border-0"
                          type="button"
                          id="button-addon2"
                        >
                          <span className="point-20">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21.208"
                              height="22.989"
                              viewBox="0 0 21.208 22.989"
                            >
                              <g
                                id="Group_35"
                                data-name="Group 35"
                                transform="translate(-1193.792 -328)"
                              >
                                <g
                                  id="Ellipse_15"
                                  data-name="Ellipse 15"
                                  transform="translate(1199 328)"
                                  fill="none"
                                  stroke="#9fafca"
                                  stroke-width="3"
                                >
                                  <circle
                                    cx="8"
                                    cy="8"
                                    r="8"
                                    stroke="none"
                                  ></circle>
                                  <circle
                                    cx="8"
                                    cy="8"
                                    r="6.5"
                                    fill="none"
                                  ></circle>
                                </g>
                                <path
                                  id="Path_120"
                                  data-name="Path 120"
                                  d="M1208.54,5578.4l-6.2,7.762"
                                  transform="translate(-6.44 -5237.284)"
                                  fill="none"
                                  stroke="#9fafca"
                                  stroke-linecap="round"
                                  stroke-width="3"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix">
                    <span className="float-end">
                      <span className="point-12 text-black-50 fw-light">
                        <span className="point-18">
                          <i className="bi bi-star fs-6"></i>
                        </span>
                        <span className="text-uppercase fw-bold">
                          &nbsp;Save to Dashboard
                        </span>
                      </span>
                    </span>
                  </div> */}
                  <div className="">
                    <ul className="list-group py-3">
                      {!showloader ? (
                        List && List.length > 0 ? (
                          List.map((data, i) => (
                            <li
                              key={data.project_id}
                              className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer"
                            >
                              <div className="d-md-flex justify-content-between align-items-start">
                                <div className="me-auto mb-2">
                                  <span className="fw-bold point-22">
                                    {data.project_title}&nbsp;
                                  </span>
                                </div>
                                <div className="mb-2">
                                  <span
                                    className="badge bg-secondary text-decoration-none text-danger rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                                    onClick={() =>
                                      deleteProject(
                                        data.project_id,
                                        data.page_type
                                      )
                                    }
                                    style={{ cursor: "pointer" }} // Added cursor styling
                                  >
                                    Delete
                                  </span>{" "}
                                  <span
                                    key={i}
                                    className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                                    onClick={() => handleModelOpen(data)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    EDIT
                                  </span>
                                </div>
                              </div>
                              <p className="lh-lg">
                                <span className="fw-bold">Project Type:</span>{" "}
                                {data.page_type}{" "}
                                {data.page_type === "DESIGN_BUILD"
                                  ? `(${getProjectType(
                                      data.design_build_category
                                    )})`
                                  : ""}{" "}
                                <br />
                                <span className="fw-bold">DBE Goal:</span>{" "}
                                {!isNaN(data.dbe_goal) ? data.dbe_goal : "-"}%{" "}
                                <br />
                                {data.date_entered &&
                                  data.estimated_end_date && (
                                    <>
                                      <span className="fw-bold">
                                        Open Date:
                                      </span>{" "}
                                      {convertDateFormat(data.date_entered)}
                                      <span className="text-danger fw-bolder">
                                        {" "}
                                        &nbsp;|&nbsp;{" "}
                                      </span>
                                      <span className="fw-bold">
                                        Close Date:
                                      </span>{" "}
                                      {convertDateFormat(
                                        data.estimated_end_date
                                      )}
                                      <br />
                                    </>
                                  )}
                                <span className="fw-bold">Work Types:</span>{" "}
                                {data.worktypes
                                  ? getLabelsFromWorktypes(data.worktypes)
                                  : ""}
                                <br />
                                {data.description && (
                                  <>
                                    <span className="fw-bold">
                                      Description:
                                    </span>{" "}
                                    {data.description}
                                    <br />
                                  </>
                                )}
                              </p>
                            </li>
                          ))
                        ) : (
                          <div className="d-md-flex justify-content-center">
                            No Data Found.
                          </div>
                        )
                      ) : (
                        <div className="loader">
                          <img src={loader} alt="loading" />
                        </div>
                      )}
                    </ul>
                  </div>

                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageLimit={pageLimit}
                    onPageChange={handlePageChange}
                  />
                  {modelOpen && (
                    <UpdateUploadRequestModel
                      open={modelOpen}
                      handleModalClose={handleModelClose}
                      data={selectedData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
