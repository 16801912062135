import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import CountryFilter from "../../Filters/CountryFilter";
import WorkTypeFilter from "../../Filters/WorkTypeFilter";
import AxiosInstance from "../../../utils/axiosInstance";
import Pagination from "../../Layout/Pagination";
import toast from "react-hot-toast";
import loader from "../../../assets/loader.svg";
import favoriteImg from "../../../assets/bookmark-favorite.svg";
import ListOfBidModel from "../../Model/ListOfBidModel";
import ViewPlanHolderModel from "../../Model/ViewPlanHolderModel";
import paginationValue from "../../../common/paginationValue.json";
import OptInModel from "../../Model/OptInModel";
import SeeRecordsModel from "../../Model/SeeRecordsModel";
import AdvertiseForHelpModel from "../../Model/AdvertiseForHelpModel";
import PrimeContractorHelpModel from "../../Model/PrimeContractorHelpModel";
import axios from "axios";

function ProposalDot({ handleLettingDate }) {
  const [nestedActiveTab, setActiveTab] = useState(0);
  const [praposalList, setPraposalList] = useState([]);
  const [country, setCountry] = useState("");
  const [workTypeId, setWorkTypeId] = useState("");
  const [resetTrue, setResetTrue] = useState(false);
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [showloader, setloader] = useState(false);
  const [favloader, setFavloader] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpenViewPlan, setModdelOpenViewPlan] = useState(false);
  const [modelOpenOptIN, setModelOpenOptIN] = useState(false);
  const [modelSeeRecords, setModelSeeRecords] = useState(false);
  const [modelAdvertiseForHelp, setModelAdvertiseForHelp] = useState(false);
  const [PrimeContractorHelp, setModelPrimeContractorHelp] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedCcnCallId, setSelectedCcnCallId] = useState("");
  const [APICall, setAPICAll] = useState(false);
  const [selectedState, setSelectedState] = useState(
    JSON.parse(localStorage.getItem("selectedState")) || null
  );
  const [noticeToContractor, setNoticeToContractor] = useState("");
  const [asReadBids, setAsReadBids] = useState("");
  const [bidderList, setBidderList] = useState("");
  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (currentPage > 0) {
      getPraposalList();
    }
  }, [currentPage, country, workTypeId]);

  useEffect(() => {
    if (APICall) getPraposalList();
  }, [APICall]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCountrySelect = (country) => {
    setCountry(country);
    setResetTrue(false);
  };
  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false);
  };
  const handleReset = () => {
    setResetTrue(true);
    setCountry("");
    setWorkTypeId("");
  };
  
  useEffect(() => {
    if (resetTrue) {
      getPraposalList();
    }
  }, [resetTrue]);

  const getPraposalList = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setAPICAll(false);
    const queryParams = new URLSearchParams({
      county: country,
      worktype_highway_id: workTypeId,
      page: currentPage,
      limit: pageLimit,
      state_id: selectedState?.value,
    });
    setloader(true);

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const currentTime = new Date().getTime();
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_PROPOSAL_LIST}?currentTime=${currentTime}&${queryParams}`,
      { signal: controller.signal }
    )
      .then((response) => {
        if (response.data?.data) {
          setPraposalList(response.data?.data);
          setPageLimit(response.data?.limit);
          handleLettingDate(response.data?.letting_date); 
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setNoticeToContractor(response.data?.notice_to_contractors);
          setAsReadBids(response.data?.as_read_bids);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setPraposalList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") {
          // console.log("Aborted");
          //toast.error(error?.message);
        } else {
          toast.error(error?.message);
          setloader(false);
        }
      });
  };

  const addFav = (ccn_call_id, favorite) => {
    setFavloader(true);
    const payload = {
      project_id: ccn_call_id,
      type: "DOT",
      action: favorite,
      state_id: selectedState?.value,
    };

    AxiosInstance.post(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_FAVORITE}`,
      payload
    )
      .then((response) => {
        setFavloader(false);
        toast.success(response.data?.message);
        // getPraposalList();
        //  setPraposalList([]);

        setPraposalList((prevList) =>
          prevList.map((item) =>
            item.ccn_call_id === ccn_call_id
              ? { ...item, favorites: item.favorites === "0" ? "1" : "0" }
              : item
          )
        );
      })
      .catch((error) => {
        toast.error(error?.message);
        setFavloader(false);
      });
  };
  const handleModelOpen = (callId) => {
    setSelectedData(callId);
    setModelOpen(true);
  };
  const handleModelOpenViewPlan = (callId) => {
    setSelectedData(callId);
    setModdelOpenViewPlan(true);
  };
  const handleModelOpenOptIN = (callid, ccnCallId) => {
    setAPICAll(false);
    setSelectedData(callid);
    setSelectedCcnCallId(ccnCallId);
    setModelOpenOptIN(true);
  };
  const handleModelOpenSeeRecords = (callid, ccnCallId) => {
    setSelectedData(callid);
    setSelectedCcnCallId(ccnCallId);
    setModelSeeRecords(true);
  };
  const handleModelOpenAdvertiseForHelp = (callId, ccnCallId) => {
    setModelAdvertiseForHelp(true);
    setSelectedCcnCallId(ccnCallId);
    setSelectedData(callId);
  };
  const handleModelOpenPrimeContractorHelpModel = (ccnCallId, callId) => {
    setSelectedCcnCallId(ccnCallId);
    setSelectedData(callId);
    setModelPrimeContractorHelp(true);
  };
  const handleModelClose = () => setModelOpen(false);
  const handleModelCloseViewPlan = () => setModdelOpenViewPlan(false);
  const handleModelCloeseOpenOptIN = () => {
    setModelOpenOptIN(false);
  };
  const handleModelCloeseSeeRecords = () => setModelSeeRecords(false);
  const handleModelCloeseAdvertiseForHelp = () => {
    setModelAdvertiseForHelp(false);
  };

  const handleAPIcall = () => {
    setAPICAll(true);
  };

  const handleModelCloesePrimeContractorHelp = () =>
    setModelPrimeContractorHelp(false);

  return (
    <>
      <div className="row mb-2">
        <div className="col-12 col-md-auto">
          <span
            className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
            id="basic-addon1"
          >
            Filter:
          </span>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div style={{ width: "100%" }}>
              <WorkTypeFilter
                onSelectWorkType={handleWorkTypeSelect}
                reset={resetTrue}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div style={{ width: "100%" }}>
              <CountryFilter
                onSelectCountry={handleCountrySelect}
                reset={resetTrue}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-md mb-2">
          <div className="input-group mb-3">
            <button
              className="form-control btn btn-primary text-uppercase me-2 fw-bolder rounded-pill"
              onClick={() => getPraposalList()}
            >
              Search
            </button>
          </div>{" "}
        </div>
        <div className="col-6 col-md mb-2">
          <div className="input-group mb-3">
            <button
              className="form-control btn btn-link text-uppercase me-2 text-danger text-start"
              onClick={() => handleReset()}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <span className="col-12 col-md-8 point-16">
          {bidderList ? (
            <a
              className={`${
                bidderList !== null && bidderList !== ""
                  ? "text-black"
                  : "text-secondary"
              }  fw-bolder text-uppercase point-16 me-2`}
              href={bidderList}
              target="_blank"
              rel="noopener noreferrer"
            >
              Bidders List
            </a>
          ) : (
            <span className="fw-bolder text-uppercase point-16 text-secondary me-2">
              Bidders List
            </span>
          )}
          <span className="point-16 fw-bolder text-uppercase text-primary me-2 d-none d-md-inline-block">
            |
          </span>
          <br className="d-block d-md-none" />
          {asReadBids ? (
            <a
              className={`${
                asReadBids !== null && asReadBids !== ""
                  ? "text-black"
                  : "text-secondary"
              }  fw-bolder text-uppercase point-16 me-2`}
              href={asReadBids}
              target="_blank"
              rel="noopener noreferrer"
            >
              As Read Bids
            </a>
          ) : (
            <span className="fw-bolder text-uppercase point-16 text-secondary me-2">
              As Read Bids
            </span>
          )}
          <span className="point-16 fw-bolder text-uppercase text-primary me-2 d-none d-md-inline-block">
            |
          </span>
          <br className="d-block d-md-none" />
          {noticeToContractor ? (
            <a
              className={`${
                noticeToContractor !== null && noticeToContractor !== ""
                  ? "text-black"
                  : "text-secondary"
              }  fw-bolder text-uppercase point-16`}
              href={noticeToContractor}
              target="_blank"
              rel="noopener noreferrer"
            >
              Notice to Contractors
            </a>
          ) : (
            <span className="fw-bolder text-uppercase point-16 text-secondary me-2">
              Notice to Contractors
            </span>
          )}
        </span>

        {/* <span className="col-6 col-md-4 listMap d-flex align-items-center">
          <span className="text-secondary fw-bolder text-uppercase point-14">
            View as :&nbsp;
          </span>
          <ul className="nav align-items-center">
            <li className="nav-item">
              <button
                className="nav-link fw-bolder text-uppercase point-16 text-black border-0 bg-transparent"
                onClick={() => handleViewMode("proposal", "list")}
              >
                List
              </button>
            </li>

            <span className="point-16 fw-bolder mx-2">|</span>

            <li className="nav-item">
              <button
                className="nav-link fw-bolder text-uppercase point-16 text-secondary border-0 bg-transparent"
                onClick={() => handleViewMode("proposal", "map")}
              >
                Map
              </button>
            </li>
          </ul>
        </span>
        <span className="col-6 col-md mb-2">
          <span className="point-12 text-black-50 fw-light">
            <span className="point-18">
              <i className="bi bi-star"></i>
            </span>
            <span className="text-uppercase fw-bold">
              &nbsp;Save to Dashboard
            </span>
          </span>
        </span> */}
      </div>
      <div className="tab-content mt-3">
        <div
          className={`tab-pane fade ${
            nestedActiveTab === 0 ? "show active" : ""
          }`}
          id="list"
        >
          {!showloader && !favloader ? (
            <ul className="list-group py-3">
              {praposalList.length && praposalList ? (
                praposalList.map((data, i) => (
                  <li
                    className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer"
                    key={i}
                  >
                    <div className="d-md-flex justify-content-between align-items-start">
                      <div className="me-auto mb-2">
                        <span className="fw-bold point-22">
                          {data?.county} &nbsp;
                        </span>
                        <span className="point-22 text-black-50 fw-light mb-2">
                          {data?.favorites === "0" ? (
                            <i
                              className="bi bi-star fs-6"
                              onClick={() =>
                                addFav(data?.ccn_call_id, data?.favorites)
                              }
                            ></i>
                          ) : (
                            <img
                              src={favoriteImg}
                              alt="fav"
                              onClick={() =>
                                addFav(data?.ccn_call_id, data?.favorites)
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <p className="lh-lg">
                     
                      <span className="fw-bold">Call:</span> {data?.Call_Order}
                      <span className="text-danger fw-bolder d-none d-md-inline-block">
                        &nbsp;|&nbsp;
                      </span>
                      <br className="d-block d-md-none" />
                      <span
                        className="fw-bold text-decoration-underline"
                        onClick={() => handleModelOpen(data?.callid)}
                      >
                        List of Bid Items
                      </span>
                      <span className="text-danger fw-bolder">
                        &nbsp;|&nbsp;
                      </span>
                      {data?.bidderDataAvailable > 0 ? (
                        <span
                          className="fw-bold text-decoration-underline"
                          onClick={() => handleModelOpenViewPlan(data?.callid)}
                        >
                          View Plan Holders
                        </span>
                      ) : (
                        <span className="fw-bold text-decoration-underline text-secondary">
                           View Plan Holders
                        </span>
                      )}
                      <br />
                      <span className="fw-bold">Description : </span>
                      {data?.Ldescription} <br />
                      {!data?.flag_optin ? (
                        <span
                          className="fw-bold text-decoration-underline"
                          onClick={() =>
                            handleModelOpenOptIN(
                              data?.callid,
                              data?.ccn_call_id
                            )
                          }
                        >
                          Subs/Suppliers: Opt In
                        </span>
                      ) : (
                        <span className="fw-bold text-secondary text-decoration-underline">
                          Subs/Suppliers: Opt In
                        </span>
                      )}
                      <span className="text-danger fw-bolder d-none d-md-inline-block">
                        &nbsp;|&nbsp;
                      </span>
                      <br className="d-block d-md-none" />
                      <span
                      className="text-decoration-underline"
                        onClick={() =>
                          handleModelOpenSeeRecords(
                            data?.callid,
                            data?.ccn_call_id
                          )
                        }
                      >
                        See <span className="fw-bold">{data?.count_optin}</span>{" "}
                        Opt-in Records
                      </span>
                      <span className="text-danger fw-bolder d-none d-md-inline-block">
                        &nbsp;|&nbsp;
                      </span>
                      <br className="d-block d-md-none" />
                      {!data?.flag_prime_advertise ? (
                        <span
                          className="fw-bold text-decoration-underline"
                          onClick={() =>
                            handleModelOpenAdvertiseForHelp(
                              data?.callid,
                              data?.ccn_call_id
                            )
                          }
                        >
                          &nbsp;Primes: Advertise for help
                        </span>
                      ) : (
                        <span className="fw-bold text-secondary">
                          &nbsp;Primes: Advertise for help
                        </span>
                      )}
                      <span className="text-danger fw-bolder d-none d-md-inline-block">
                        &nbsp;|&nbsp;
                      </span>
                      <br className="d-block d-md-none" />
                      <span className="fw-bold ">
                        {data?.count_prime_advertise || 0}
                      </span>{" "}
                      <span
                      className="text-decoration-underline"
                        onClick={() =>
                          handleModelOpenPrimeContractorHelpModel(
                            data?.ccn_call_id,
                            data?.callid
                          )
                        }
                      >
                        Prime contractors looking for help
                      </span>
                    </p>
                  </li>
                ))
              ) : (
                <div className="d-md-flex justify-content-center">
                  No Data Found.
                </div>
              )}
            </ul>
          ) : (
            <div className="loader">
              <img src={loader} alt="loading" />
            </div>
          )}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageLimit={pageLimit}
        onPageChange={handlePageChange}
      />
      {modelOpen && (
        <ListOfBidModel
          open={modelOpen}
          handleClose={handleModelClose}
          callId={selectedData}
        />
      )}
      {modelOpenViewPlan && (
        <ViewPlanHolderModel
          open={modelOpenViewPlan}
          handleClose={handleModelCloseViewPlan}
          callId={selectedData}
        />
      )}
      {modelOpenOptIN && (
        <OptInModel
          open={modelOpenOptIN}
          ccnCallId={selectedCcnCallId}
          callId={selectedData}
          handleClose={handleModelCloeseOpenOptIN}
          handleAPIcall={handleAPIcall}
        />
      )}
      {modelSeeRecords && (
        <SeeRecordsModel
          open={modelSeeRecords}
          ccnCallId={selectedCcnCallId}
          callId={selectedData}
          handleClose={handleModelCloeseSeeRecords}
        />
      )}
      {modelAdvertiseForHelp && (
        <AdvertiseForHelpModel
          open={modelAdvertiseForHelp}
          handleClose={handleModelCloeseAdvertiseForHelp}
          callId={selectedData}
          ccnCallId={selectedCcnCallId}
          handleAPIcall={handleAPIcall}
        />
      )}
      {PrimeContractorHelp && (
        <PrimeContractorHelpModel
          open={PrimeContractorHelp}
          callId={selectedData}
          ccnCallId={selectedCcnCallId}
          handleClose={handleModelCloesePrimeContractorHelp}
        />
      )}
    </>
  );
}

export default ProposalDot;
