
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import AxiosInstance from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

export default function OptInModel({ open, handleClose, callId, ccnCallId, handleAPIcall }) {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
    const [formData, setFormData] = useState({
        company_name: "",
        name: "",
        email: "",
        phone: "",
        disadvantage_status_id: [],
        services_offered: "",
        proposal_id: callId,
        ccn_call_id: ccnCallId
    });

    useEffect(() => {
        getDisadvantagedStatus();
    }, []);

    const transformData = (items) =>
        items.map(({ id, name }) => ({
            value: id,
            label: name,
        })
        );

    const getDisadvantagedStatus = () => {
        AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_DISADVANTAGED_STATUS}`)
            .then(response => {
                setOptions(transformData(response.data?.data));
            })
            .catch(error => {
                console.log(error?.message);
            });
    }
    const handleChange = (selected) => {
        const disadvantagedStatus = Array.isArray(selected)
            ? selected.map((item) => item.value)
            : selected?.value || '';
        setFormData((prevData) => ({ ...prevData, 'disadvantage_status_id': disadvantagedStatus }));
        setSelectedOptions(selected);
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }));
    };
    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } setErrors({});
        const formValue = formData;

        AxiosInstance.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_ADD_OPTIN}`, formValue)
            .then(response => {
                if (response?.data) {
                    toast.success(response?.data?.message);
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        disadvantage_status_id: {},
                        services_offered: ""
                    });
                    handleClose();
                    handleAPIcall();
                } else {
                    toast.error(response?.data?.message);
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        disadvantage_status_id: {},
                        services_offered: ""
                    });
                }
            })
            .catch(error => {
                toast.error(error?.data?.message);
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    disadvantage_status_id: {},
                    services_offered: ""
                });
            });
    };
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = "Name is required.";
        if (!formData.email.trim()) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format.";
        }
        if (!formData.phone.trim()) newErrors.phone = "Phone number is required.";
        else if (!/^(\+1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formData.phone)) {
            newErrors.phone = "Invalid phone number format.";
        }
        if (!formData.services_offered.trim()) newErrors.services_offered = "Please enter services";
        console.log('newErrors:', newErrors)
        return newErrors;
    };

    useEffect(() => {
        const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
        const { state, first_name, phone_number, ...filteredUserInfo } = loggedInUserInfo;
        setFormData(prevState => ({
            ...prevState,
            name: first_name || "",
            phone: phone_number || "",
            ...filteredUserInfo
        }));
    }, [])

    return (
        <Modal
            size="l"
            show={open} onHide={handleClose} centered
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton className="d-flex justify-content-center">
                <Modal.Title className="w-100 text-center">
                    {loggedInUserInfo?.company_name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12 bg-white py-5">
                            <form className="px-3" onSubmit={handleSubmit}>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label className="form-check-label" htmlFor="flexCheckDefault1">
                                            <span className="fw-bold point-16">Name</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control border border-secondary rounded-0 ${errors.name ? "is-invalid" : ""
                                                }`}
                                            aria-label="Name"
                                            name="name"
                                            placeholder='Enter Name'
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && <div className="text-danger">{errors.name}</div>}
                                    </div>
                                    <div className="col">
                                        <label className="form-check-label" htmlFor="flexCheckDefault1">
                                            <span className="fw-bold point-16 ">Email</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control border border-secondary rounded-0 ${errors.email ? "is-invalid" : ""
                                                }`}
                                            aria-label="email"
                                            name="email"
                                            placeholder='Enter email'
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                        {errors.email && <div className="text-danger">{errors.email}</div>}
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label className="form-check-label" htmlFor="flexCheckDefault1">
                                            <span className="fw-bold point-16 ">Phone Number</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control border border-secondary rounded-0 ${errors.phone ? "is-invalid" : ""
                                                }`}
                                            aria-label="phone Number"
                                            name="phone"
                                            placeholder='Enter Phone'
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                        />
                                        {errors.phone && <div className="text-danger">{errors.phone}</div>}

                                    </div>
                                    <div className="col">
                                        <label className="form-check-label" htmlFor="flexCheckDefault1">
                                            <span className="fw-bold point-16 ">Disadvantaged status</span>
                                        </label>
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: '#9FAFCA'
                                                }),
                                                dropdownIndicator: (provided, state) => ({
                                                    ...provided,
                                                    color: '#a71e1e',
                                                })
                                            }}
                                            options={options}
                                            isMulti
                                            value={selectedOptions}
                                            onChange={handleChange}
                                            placeholder="Select Status..."
                                            isSearchable
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">

                                    <div className="col">
                                        <label className="form-check-label" htmlFor="flexCheckDefault1">
                                            <span className="fw-bold point-16 ">Services Offered</span>
                                        </label>
                                        <textarea
                                            className={`form-control form-textarea border border-secondary rounded-0 ${errors.services_offered ? "is-invalid" : ""
                                                }`}
                                            name="services_offered"
                                            value={formData.services_offered}
                                            onChange={handleInputChange}
                                        ></textarea>
                                        {errors.services_offered && <div className="text-danger">{errors.services_offered}</div>}
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-secondary rounded-5 fw-semibold  point-18"
                                    style={{ float: 'right' }}
                                >
                                    Save
                                </button>


                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};