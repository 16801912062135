import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import ProfileBilling from "./ProfileBilling";
import ProfileSecurity from "./ProfileSecurity";
import { Link } from "react-router-dom";
import AxiosInstance from "../../utils/axiosInstance";
import Select from 'react-select';
import WorkTypeFilter from "../../components/Filters/WorkTypeFilter";
import toast from "react-hot-toast";


export default function UserProfile() {
  const [activeTab, setActiveTab] = useState(0);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [workTypeId, setWorkTypeId] = useState('');
  const [resetTrue, setResetTrue] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [loading, setloader] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    subscribe_id: "",
    billing_address_street: "",
    billing_address_city: "",
    billing_address_state: "",
    billing_address_postalcode: "",
    account_id: "",
    subscription_name: '',
    subscription_id: '',
    building: [],
    state: [],
    highway: [],
  });
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchAPIs = async () => {
      try {
        getProfileData();
        getState();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchAPIs();
  }, []);

  const transformData = (items) =>
    items.map(({ id, name }) => ({
      value: id,
      label: name,
    })
    );

  const getState = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_STATE_API}`)
      .then(response => {
        setStateList(transformData(response?.data?.data));
      })
      .catch(error => {
        console.log(error?.message);
      });
  }
  const handleStateChange = (selected) => {
    const stateData = selected.map(item => item.value);
    setSelectedState(selected);
  };
  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false)
  }

  const getProfileData = () => {
    setloader(true);
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_PROFILE}`)
      .then(response => {
        if (response.data?.data) {
          setFormData(prevState => ({
            ...prevState,
            ...response.data.data
          }));
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch(error => {
        toast.error(error?.message);
        setloader(false);
      });
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValue = formData;
    console.log(formData)
  };

  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">User Profile</h1>
            </div>
            <div className="col-12 col-md text-md-end pt-md-4">
              <span className="text-secondary fw-bolder text-uppercase point-16 me-3"
              >User Profile</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3 clearfix">
        <div className="container mt-n20">
          <div className="row">
            <div className="col-12">
              <div className="card w-100 rounded-3 shadow-sm mb-4">
                <div className="card-body">
                  <div className="container-fluid g-0">
                    <div className="row g-0">
                      <div className="col-12 col-md-4 bg-light p-4 dotPage">
                        <ul className="nav nav-tabs list-group border-0 bg-light">
                          <li className="nav-item">
                            <Link
                              className={`nav-item list-group-item border-0 bg-light text-uppercase fw-bold point-16  ${activeTab === 0 ? 'text-danger' : 'text-black'}`}
                              title="Profile"
                              onClick={() => handleTabClick(0)}
                            >
                              Profile Settings
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-item list-group-item border-0 bg-light text-uppercase fw-bold point-16  ${activeTab === 1 ? 'text-danger' : 'text-black'}`}
                              title="billing"
                              onClick={() => handleTabClick(1)}
                            >
                              Billing
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-item list-group-item border-0 bg-light text-uppercase fw-bold point-16  ${activeTab === 2 ? 'text-danger' : 'text-black'}`}
                              title="Security"
                              onClick={() => handleTabClick(2)}
                            >
                              Security
                            </Link>
                          </li>
                        </ul>
                      </div>


                      {activeTab === 0 && <div className={`col-12 col-md-8 bg-white p-4 ${activeTab === 0 ? 'show active' : ''}`} id="Profile">
                        <h2
                          className="text-primary fw-bolder point-32 mb-3 text-uppercase"
                        >
                          My Profile Settings
                        </h2>
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-4">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="username"
                                aria-label="username"
                                // name="username"
                                value={formData.username}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="First name"
                                aria-label="First name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="Last name"
                                aria-label="Last name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="Email"
                                aria-label="Email"
                                // name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="Phone Number"
                                aria-label="Phone Number"
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <h3
                              className="text-secondary fw-bolder point-16 text-uppercase mb-0 mt-4"
                            >
                              Company Information:
                            </h3>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="Company Name"
                                aria-label="Company Name"
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="Street"
                                aria-label="billing_address_street"
                                name="billing_address_street"
                                value={formData.billing_address_street}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="City"
                                aria-label="billing_address_city"
                                name="billing_address_city"
                                value={formData.billing_address_city}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="State"
                                aria-label="billing_address_state"
                                name="billing_address_state"
                                value={formData.billing_address_state}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary rounded-0"
                                placeholder="Zip"
                                aria-label="billing_address_postalcode"
                                name="billing_address_postalcode"
                                value={formData.billing_address_postalcode}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row my-2 d-none d-md-block">&nbsp;</div>
                          <div className="col-12 col-md-12 mb-12">
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                              <span className="fw-bold point-16 text-uppercase">Select Work Type</span>
                            </label>
                            <WorkTypeFilter onSelectWorkType={handleWorkTypeSelect} reset={resetTrue} borderTrue={true} />
                          </div>
                          <div className="col-12 col-md-12 mb-12 mt-4 mb-3">
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                              <span className="fw-bold point-16 text-uppercase"
                              >What states are you located in?</span>
                            </label>
                            <Select
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                              })}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: '#9FAFCA',
                                  borderWidth: '3px',
                                  '&:hover': {
                                    borderColor:'#2684FF',
                                  },
                                }),
                                dropdownIndicator: (provided, state) => ({
                                  ...provided,
                                  color: '#a71e1e',
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: '#6c757d',
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected ? '#9FAFCA' : 'white',
                                  color: state.isSelected ? 'white' : 'black',
                                  '&:hover': {
                                    backgroundColor: '#9FAFCA',
                                    color: 'white',
                                  },
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  borderRadius: 0,
                                }),
                              }}
                              options={stateList}
                              isMulti
                              value={selectedState}
                              onChange={handleStateChange}
                              placeholder="Select states..."
                              isSearchable
                            />

                          </div>
                          <button
                            type="submit"
                            className="btn btn-secondary rounded-5 fw-bold point-18 text-uppercase"
                          >
                            Save Changes
                          </button>
                        </form>
                      </div>}

                      {activeTab === 1 && <div className={`col-12 col-md-8 bg-white p-4 ${activeTab === 1 ? 'show active' : ''}`} id="billing">
                        <ProfileBilling />
                      </div>}
                      {activeTab === 2 && <div className={`col-12 col-md-8 bg-white p-4 ${activeTab === 2 ? 'show active' : ''}`} id="Security">
                        <ProfileSecurity />
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
