import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

export default function TermsAndCondition() {
    return (
        <>
        <div className="bg-primary py-3"><Header/></div>
        
            <div class="bg-primary py-3">
                <div class="container">
                    <div class="row mt-10">
                        <div class="col col-md-auto">
                            <p class="text-white point-22"></p>
                            <h1 class="text-secondary fw-boldest point-42">
                                Terms & Conditions
                            </h1>
                        </div>
                        <div class="col">&nbsp;</div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="fw-bold point-32">
                                Terms & Conditions
                            </h2>
                            <div className="point-18">
                                <p>Last updated 2024.7.5</p>
                                <p><strong>AGREEMENT TO TERMS</strong></p>
                                <p>
                                    These Terms and Conditions constitute a legally binding
                                    agreement made between you, whether personally or on behalf of
                                    an entity (“you”) and Contractors Communication Network (“we,”
                                    “us” or “our”), concerning your access to and use of the website
                                    as well as any other media form, media channel, mobile website
                                    or mobile application related, linked, or otherwise connected
                                    thereto (collectively, the “Site”).
                                </p>
                                <p>
                                    You agree that by accessing the Site, you have read, understood,
                                    and agree to be bound by all of these Terms and Conditions. If
                                    you do not agree with all of these Terms and Conditions, then
                                    you are expressly prohibited from using the Site and you must
                                    discontinue use immediately.
                                </p>
                                <p>
                                    Supplemental terms and conditions or documents that may be
                                    posted on the Site from time to time are hereby expressly
                                    incorporated herein by reference. We reserve the right, in our
                                    sole discretion, to make changes or modifications to these Terms
                                    and Conditions at any time and for any reason.
                                </p>
                                <p>
                                    We will alert you about any changes by updating the “Last
                                    updated” date of these Terms and Conditions, and you waive any
                                    right to receive specific notice of each such change.
                                </p>
                                <p>
                                    It is your responsibility to periodically review these Terms and
                                    Conditions to stay informed of updates. You will be subject to,
                                    and will be deemed to have been made aware of and to have
                                    accepted, the changes in any revised Terms and Conditions by
                                    your continued use of the Site after the date such revised Terms
                                    and Conditions are posted.
                                </p>
                                <p>
                                    The information provided on the Site is not intended for
                                    distribution to or use by any person or entity in any
                                    jurisdiction or country where such distribution or use would be
                                    contrary to law or regulation or which would subject us to any
                                    registration requirement within such jurisdiction or country.
                                </p>
                                <p>
                                    Accordingly, those persons who choose to access the Site from
                                    other locations do so on their own initiative and are solely
                                    responsible for compliance with local laws, if and to the extent
                                    local laws are applicable.
                                </p>
                                <p>
                                    These terms and conditions were created by Termly’s
                                    <a href="https://termly.io/products/terms-and-conditions-generator/">Terms and Conditions Generator</a>.
                                </p>
                                <p>
                                    The Site is intended for users who are at least 18 years old.
                                    Persons under the age of 18 are not permitted to register for
                                    the Site.
                                </p>
                                <p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
                                <p>
                                    Unless otherwise indicated, the Site is our proprietary property
                                    and all source code, databases, functionality, software, website
                                    designs, audio, video, text, photographs, and graphics on the
                                    Site (collectively, the “Content”) and the trademarks, service
                                    marks, and logos contained therein (the “Marks”) are owned or
                                    controlled by us or licensed to us, and are protected by
                                    copyright and trademark laws and various other intellectual
                                    property rights and unfair competition laws of the United
                                    States, foreign jurisdictions, and international conventions.
                                </p>
                                <p>
                                    The Content and the Marks are provided on the Site “AS IS” for
                                    your information and personal use only. Except as expressly
                                    provided in these Terms and Conditions, no part of the Site and
                                    no Content or Marks may be copied, reproduced, aggregated,
                                    republished, uploaded, posted, publicly displayed, encoded,
                                    translated, transmitted, distributed, sold, licensed, or
                                    otherwise exploited for any commercial purpose whatsoever,
                                    without our express prior written permission.
                                </p>
                                <p>
                                    Provided that you are eligible to use the Site, you are granted
                                    a limited license to access and use the Site and to download or
                                    print a copy of any portion of the Content to which you have
                                    properly gained access solely for your personal, non-commercial
                                    use. We reserve all rights not expressly granted to you in and
                                    to the Site, the Content and the Marks.
                                </p>
                                <p><strong>USER REPRESENTATIONS</strong></p>
                                <p>By using the Site, you represent and warrant that:</p>
                                <p>
                                    (1) all registration information you submit will be true,
                                    accurate, current, and complete; (2) you will maintain the
                                    accuracy of such information and promptly update such
                                    registration information as necessary;
                                </p>
                                <p>
                                    (3) you have the legal capacity and you agree to comply with
                                    these Terms and Conditions;
                                </p>
                                <p>(5) not a minor in the jurisdiction in which you reside;</p>
                                <p>
                                    (6) you will not access the Site through automated or non-human
                                    means, whether through a bot, script, or otherwise;
                                </p>
                                <p>
                                    (7) you will not use the Site for any illegal or unauthorized
                                    purpose;
                                </p>
                                <p>
                                    (8) your use of the Site will not violate any applicable law or
                                    regulation.
                                </p>
                                <p>
                                    If you provide any information that is untrue, inaccurate, not
                                    current, or incomplete, we have the right to suspend or
                                    terminate your account and refuse any and all current or future
                                    use of the Site (or any portion thereof).
                                </p>
                                <p><strong>USER REGISTRATION</strong></p>
                                <p>
                                    You may be required to register with the Site. You agree to keep
                                    your password confidential and will be responsible for all use
                                    of your account and password. We reserve the right to remove,
                                    reclaim, or change a username you select if we determine, in our
                                    sole discretion, that such username is inappropriate, obscene,
                                    or otherwise objectionable.
                                </p>
                                <p><strong>PROHIBITED ACTIVITIES</strong></p>
                                <p>
                                    You may not access or use the Site for any purpose other than
                                    that for which we make the Site available. The Site may not be
                                    used in connection with any commercial endeavors except those
                                    that are specifically endorsed or approved by us.
                                </p>

                                <p>
                                    In order to resolve a complaint regarding the Site or to receive
                                    further information regarding use of the Site, please contact us
                                    at:
                                </p>
                                <p>Contractors Communication Network</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-primary py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <p className="text-white fw-semibold">
                                For more information, please contact:
                            </p>
                        </div>
                        <div className="col-6">
                            <p className="text-white">
                                Full Name<br />
                                Email Address Here<br />
                                Phone Here
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}