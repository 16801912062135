import React, { useEffect, useState, useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import AxiosInstance from "../../utils/axiosInstance";
import Select from "react-select";
import CountryFilter from "../Filters/CountryFilter";
import WorkTypeFilter from "../Filters/WorkTypeFilter";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";

export default function UpdateUploadingRequest({
  open,
  handleModalClose,
  data,
}) {
  console.log("data", data);
  const [workTypeList, setWorkTypeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [resetTrue, setResetTrue] = useState(false);
  const [workTypeId, setWorkTypeId] = useState([]);
  const [country, setCountry] = useState("");
  const [showloader, setloader] = useState(false);
  const [tabs, setTabs] = useState([]); // Stores tab list
  const [activeTab, setActiveTab] = useState(""); // Stores active tab
  const [StateListFromLocalStorage, setStateListFromLocalStorage] = useState(
    []
  );
  const [storedState, setstoredState] = useState(
    JSON.parse(localStorage.getItem("selectedState"))
  );
  const [selectedState, setSelectedState] = useState();
  const isInitialRender = useRef(true);

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const options = {
    city: "City",
    county: "County",
    design_build: "Design Build",
    private: "Private",
    state: "State",
  };
  const location = useLocation();

  const design_build_category_options = [
    { value: "DESIGN_BUILD_MRB", label: "MRB" },
    { value: "DESIGN_BUILD_WAH", label: "WAH" },
    { value: "DESIGN_BUILD_WAYBAY", label: "BAYWAY" },
  ];

  const getStoredUserInfo = () => {
    const storedData = localStorage.getItem("loggedInUserInfo");
    return storedData ? JSON.parse(storedData) : {};
  };
  const isFileCorruptedRef = useRef(false);

  const getArrayOfSelectedWorkTypes = (workTypeString) => {
    return workTypeString
      ? workTypeString.split(",").map((item) => item.trim())
      : [];
  };
  const init = {
    project_id: data.project_id,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    date_submitting: data.estimated_end_date,
    dbe_goal: data.dbe_goal,
    project_title: data.project_title,
    project_description: data.description,
    project_page_type: data.page_type,
    design_build_category: data.design_build_category,
    worktypes: { highway: getArrayOfSelectedWorkTypes(data.worktypes) },
    county: "",
    state_id: "",
    file: null,
    download_url: data.download_url,
  };

  useEffect(() => {
    const userInfo = getStoredUserInfo();

    setFormData((prevState) => ({
      ...prevState,
      first_name: userInfo.first_name || "",
      last_name: userInfo.last_name || "",
      email: userInfo.email || "",
      phone_number: userInfo.phone_number || "",
    }));
  }, []);

  const handleChange = (selected) => {
    const workTypeData = transformWorkTypeData(workTypeList, selected);
    // setFormData((prevData) => ({ ...prevData, 'worktypes': workTypeData }));
    setSelectedOptions(selected);
  };

  const [formData, setFormData] = useState(init);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    let state = "";
    if (selectedState) {
      state = selectedState;
    } else {
      state = storedState;
    }
  }, [selectedState, storedState]);

  const handleStateChange = (selected) => {
    setSelectedState(selected);
    setFormData((prevFormData) => ({
      ...prevFormData,
      state_id: selected.value,
    }));
    setErrors((prev) => ({
      ...prev,
      ["state_id"]: "",
    }));
    isInitialRender.current = false;
  };

  const handleCategoryChange = (selected) => {
    setSelectedState(selected);
    setFormData((prevFormData) => ({
      ...prevFormData,
      design_build_category: selected.value,
    }));
    setErrors((prev) => ({
      ...prev,
      ["design_build_category"]: "",
    }));
    // isInitialRender.current = false;
  };

  useEffect(() => {
    const fetchAPIs = async () => {
      try {
        getTabType();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    const data = JSON.parse(localStorage.getItem("loggedInUserInfo") || "[]");
    const transformedData = transformData(data?.state || []);
    setStateListFromLocalStorage(transformedData);
    if (!storedState && transformedData.length > 0) {
      localStorage.setItem("selectedState", JSON.stringify(transformedData[0]));
      setSelectedState(transformedData[0]);
    }

    fetchAPIs();
  }, []);
  const transformData = (items) =>
    items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const transformWorkTypeData = (originalData, selectedValues) => {
    const selectedIds = selectedValues.map((item) => item.value);

    const result = Object.keys(originalData).reduce((acc, key) => {
      acc[key] = originalData[key]
        .filter((item) => selectedIds.includes(item.id))
        .map((item) => item.id);
      return acc;
    }, {});

    return result;
  };

  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId((prevWorkTypeIds) => {
      return workType;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      worktypes: {
        highway: workType, // Append correctly
      },
    }));
    setResetTrue(false);
    setErrors((prev) => ({
      ...prev,
      ["worktypes"]:
        workType.length > 0 ? "" : "Please select at least one work type",
    }));
  };

  const handleCountrySelect = (country) => {
    setCountry(country);
    setFormData((prevFormData) => ({
      ...prevFormData,
      county: country,
    }));
    setErrors((prev) => ({
      ...prev,
      ["county"]: "",
    }));

    setResetTrue(false);
  };
  const getTabType = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}/get_request`)
      .then((response) => {
        const tabString = response.data.data.contact_request;
        const formattedTabs = tabString
          .replace(/\^/g, "")
          .split(",")
          .map((item) => item.trim());
        setTabs(formattedTabs);
        setActiveTab(formattedTabs[0]);
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };

  const handleTabClick = (tab) => {
    if (activeTab === tab) return;
    setActiveTab(tab);
  };

  const validateForm = () => {
    const newErrors = {};
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    const allowedExtensions = ["pdf", "doc", "docx", "xlsx", "jpg", "png"];

    if (!formData.first_name.trim())
      newErrors.first_name = "First name is required.";
    if (!formData.last_name.trim())
      newErrors.last_name = "Last name is required.";

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!formData.phone_number.trim()) {
      newErrors.phone_number = "Phone number is required.";
    } else if (
      !/^(\+1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(
        formData.phone_number
      )
    ) {
      newErrors.phone_number = "Invalid phone number format.";
    }

    if (!formData.date_submitting.trim())
      newErrors.date_submitting = "Date submitting is required.";
    if (!formData.dbe_goal.trim()) newErrors.dbe_goal = "DBE goal is required.";

    if (isNaN(formData.dbe_goal) || parseInt(formData.dbe_goal) < 0 || parseInt(formData.dbe_goal)   > 99) {
      newErrors.dbe_goal = "DBE goal must be a number between 0 and 99.";
    }

    if (!formData.project_description.trim())
      newErrors.project_description = "Project description is required.";
    if (!formData.project_title.trim())
      newErrors.project_title = "Project title is required.";

    if (null && activeTab === "COUNTY") {
      if (!formData.county?.trim()) newErrors.county = "County is required.";
    }

    if (formData.page_type === "DESIGN_BUILD") {
      if (!formData.design_build_category?.trim())
        newErrors.design_build_category = "Category is required.";
    }

    if (
      !formData.worktypes ||
      Object.keys(formData.worktypes).length === 0 ||
      formData.worktypes.highway.length === 0
    ) {
      newErrors.worktypes = "At least one work type is required.";
    }

    if (!formData.file || formData.file.length === 0) {
      // newErrors.file = " file is required .";
    } else {
      const file = formData.file; // Assume single file upload

      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileSize > maxFileSize) {
        newErrors.file = "File must be 5MB or less.";
      } else if (file.size === 0) {
        newErrors.file = "This file is empty! Please select a valid file.";
      } else if (!allowedExtensions.includes(fileExtension)) {
        newErrors.file = `Invalid file type. Allowed: ${allowedExtensions.join(
          ", "
        )}`;
      } else if (isFileCorruptedRef.current === true) {
        newErrors.file = "This file appears to be invalid or does not exist.";
      }
    }

    return newErrors;
  };

  const resetForm = () => {
    //setFormData(init);
    setResetTrue(true);
    setSelectedState("");
    const userInfo = getStoredUserInfo(); // Fetch user info again

    setFormData({
      ...init, // Reset to initial structure
      first_name: userInfo.first_name || "",
      last_name: userInfo.last_name || "",
      email: userInfo.email || "",
      phone_number: userInfo.phone_number || "",
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    setSelectedFile(null); // Clear displayed file name
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(true);

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setloader(false);
      return;
    }
    setErrors({});

    const { file, ...formValue } = formData;
    if (activeTab === "state") {
      formValue.state_id = storedState.value;
    }
    console.log("final value", formValue);
    try {
      const response = await AxiosInstance.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_EDIT_MY_PROJECTS}`,
        formValue
      );

      if (response?.data) {
        console.log(response.data);
        // toast.success(response.data.message);

        if (response.data.project_id) {
          const projectId = response.data.project_id; // Corrected project ID extraction
          console.log(projectId);
          if (file) {
            try {
              const formDataForFile = new FormData();

              // Extract file extension
              const fileExtension = file.name.split(".").pop();
              const renamedFileName = `${projectId}.${fileExtension}`;

              // Rename the file while preserving its MIME type
              const renamedFile = new File([file], renamedFileName, {
                type: file.type,
              });

              // Append file & project_id
              formDataForFile.append("file", renamedFile);
              formDataForFile.append("project_id", projectId);

              const response = await AxiosInstance.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_UPLOAD_PROJECT_FILE}`,
                formDataForFile
              );

              toast.success("Data and file uploaded successfully");
            } catch (fileUploadError) {
              console.error("File Upload Error:", fileUploadError);
              toast.error("Data uploaded, but file upload failed!");
            }
          } else {
            toast.success(
              response.data.message || " Data uploaded successfully"
            );
          }
        } else {
          toast.error("Project ID missing. Please try again.");
        }
      } else {
        toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      console.error("Form Submission Error:", error);
      toast.error(
        error?.data?.message || "Error submitting data. Please try again."
      );
    } finally {
      setloader(false); // Hide loader after process
      resetForm(); // Reset form only after everything completes
      handleModalClose();
    }
  };

  const handleInputChange = (e) => {
    console.log("onchamge");
    if (!e || !e.target) {
      console.error("Invalid event: ", e);
      return;
    }

    const { name, value, type, files } = e.target;
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    const allowedExtensions = ["pdf", "doc", "docx", "xlsx", "jpg", "png"];

    if (type === "file") {
      const file = files?.[0];

      if (!file) {
        setErrors((prev) => ({
          ...prev,
          [name]: "No file selected.",
        }));
        return;
      }

      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileSize === 0) {
        setErrors((prev) => ({
          ...prev,
          [name]: "This file is empty! Please select a valid file.",
        }));
        return;
      }

      if (fileSize > maxFileSize) {
        setErrors((prev) => ({
          ...prev,
          [name]: "File must be 5MB or less.",
        }));
        return;
      }

      if (!allowedExtensions.includes(fileExtension)) {
        setErrors((prev) => ({
          ...prev,
          [name]: `Invalid file type. Allowed: ${allowedExtensions.join(", ")}`,
        }));
        return;
      }

      // File validation passed → Update state
      setSelectedFile(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Attempt to read the file to verify its existence
      const reader = new FileReader();
      reader.onload = () => {
        isFileCorruptedRef.current = false;
      };
      reader.onerror = () => {
        isFileCorruptedRef.current = true;
        setErrors((prev) => ({
          ...prev,
          [name]: "This file appears to be invalid or does not exist.",
        }));
      };
      reader.readAsArrayBuffer(file);
    } else {
      // Handle non-file inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (name === "dbe_goal" && (parseInt(value) < 0 || parseInt(value) > 99)) {
      console.log("af");
      setErrors((prev) => ({
        ...prev,
        [name]: "Only 0 to 99 value accepted ",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: value ? "" : "field is required ",
      }));
    }
  };
  const formatDateForDB = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // ✅ Save as YYYY-MM-DD
  };

  const handleDateChange = (name, date) => {
    if (date) {
      const formattedDate = formatDateForDB(date); // Convert to YYYY-MM-DD
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
      }));

      setErrors((prev) => ({
        ...prev,
        [name]: formattedDate ? "" : "This field is required.",
      }));
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={open}
        onHide={handleModalClose}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton onClick={handleModalClose}>
          <Modal.Title id="example-custom-modal-styling-title centered">
            Update Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control border border-secondary rounded-0"
                  placeholder="First name"
                  aria-label="First name"
                  name="first_name"
                  readOnly={true}
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
                {errors.first_name && (
                  <div className="text-danger">{errors.first_name}</div>
                )}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control border border-secondary rounded-0"
                  placeholder="Last name"
                  aria-label="Last name"
                  name="last_name"
                  readOnly={true}
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
                {errors.last_name && (
                  <div className="text-danger">{errors.last_name}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control border border-secondary rounded-0"
                  placeholder="Email"
                  aria-label="Email"
                  name="email"
                  readOnly={true}
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <div className="text-danger">{errors.email}</div>
                )}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control border border-secondary rounded-0"
                  placeholder="Phone Number"
                  aria-label="Phone Number"
                  name="phone_number"
                  readOnly={true}
                  value={formData.phone_number}
                  onChange={handleInputChange}
                />
                {errors.phone_number && (
                  <div className="text-danger">{errors.phone_number}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <DatePicker
                  selected={
                    formData.date_submitting
                      ? new Date(formData.date_submitting)
                      : null
                  }
                  onChange={(date) => handleDateChange("date_submitting", date)} // ✅ Pass function correctly
                  className="form-control border border-secondary rounded-0"
                  placeholderText="Deadline Date for submitting quotes (MM-DD-YYYY)" // ✅ Custom placeholder
                  dateFormat="MM-dd-yyyy" // Ensures correct format
                  minDate={new Date()} // Disables past dates
                />
                {errors.date_submitting && (
                  <div className="text-danger">{errors.date_submitting}</div>
                )}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="number"
                  className="form-control border border-secondary rounded-0"
                  placeholder="DBE Goal%"
                  name="dbe_goal"
                  aria-label="DBE Goal%"
                  value={formData.dbe_goal}
                  onChange={handleInputChange}
                />
                {errors.dbe_goal && (
                  <div className="text-danger">{errors.dbe_goal}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <input
                  type="text"
                  className="form-control border border-secondary rounded-0"
                  placeholder="Project Title"
                  aria-label="Project Title"
                  name="project_title"
                  value={formData.project_title}
                  onChange={handleInputChange}
                />
                {errors.project_title && (
                  <div className="text-danger">{errors.project_title}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <textarea
                  className="form-control border border-secondary rounded-0"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Project Description"
                  name="project_description"
                  value={formData.project_description}
                  onChange={handleInputChange}
                ></textarea>
                {errors.project_description && (
                  <div className="text-danger">
                    {errors.project_description}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              {/* Work Type Filter */}
              <div className="col-12 col-md-6 mb-3">
                <WorkTypeFilter
                  onSelectWorkType={handleWorkTypeSelect}
                  reset={resetTrue}
                  borderTrue={true}
                  ismultiple={true}
                  preSelectedValues={formData.worktypes}
                />
                {errors.worktypes && (
                  <div className="text-danger">{errors.worktypes}</div>
                )}
              </div>

              {/* Conditional Inputs Based on Active Tab */}
              {/* State Selection */}
              {null && data.page_type === "STATE" && (
                <>
                  <div className="col-12 col-md-6 mb-3">
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#9FAFCA",
                          borderWidth: "3px",
                          "&:hover": {
                            borderColor: "#2684FF",
                          },
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: "#a71e1e",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#6c757d",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#9FAFCA"
                            : "white",
                          color: state.isSelected ? "white" : "black",
                          "&:hover": {
                            backgroundColor: "#9FAFCA",
                            color: "white",
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                          borderRadius: 0,
                        }),
                      }}
                      options={StateListFromLocalStorage}
                      value={selectedState || null}
                      name="state_id"
                      onChange={handleStateChange}
                      placeholder="Select State type..."
                      isSearchable
                    />

                    {errors.state_id && (
                      <div className="text-danger">{errors.state_id}</div>
                    )}
                  </div>
                </>
              )}

              {/* County Selection */}
              {null && data.page_type === "COUNTY" && (
                <>
                  <div className="col-12 col-md-6 mb-3">
                    <CountryFilter
                      onSelectCountry={handleCountrySelect}
                      reset={resetTrue}
                      borderTrue={true}
                    />
                    {errors.county && (
                      <div className="text-danger">{errors.county}</div>
                    )}
                  </div>
                </>
              )}

              {data.page_type === "DESIGN_BUILD" && (
                <>
                  <div className="col-12 col-md-6 mb-3">
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: "#9FAFCA",
                          borderWidth: "3px",
                          "&:hover": {
                            borderColor: "#2684FF",
                          },
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: "#a71e1e",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#6c757d",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#9FAFCA"
                            : "white",
                          color: state.isSelected ? "white" : "black",
                          "&:hover": {
                            backgroundColor: "#9FAFCA",
                            color: "white",
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                          borderRadius: 0,
                        }),
                      }}
                      options={design_build_category_options}
                      value={
                        design_build_category_options.find(
                          (option) =>
                            option.value === formData.design_build_category
                        ) || null
                      } // ✅ Pre-selects the correct value
                      name="design_build_category"
                      onChange={handleCategoryChange}
                      placeholder="Select Category Type"
                      isSearchable
                    />

                    {errors.design_build_category && (
                      <div className="text-danger">
                        {errors.design_build_category}
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* File Input */}
              <div className="col-12 col-md-6 mb-3">
                <input
                  className="form-control border border-secondary rounded-0"
                  type="file"
                  id="formFile"
                  name="file"
                  onChange={handleInputChange}
                  ref={fileInputRef} // Attach ref to input
                />
                <small className="text-muted">
                  Accepted file types:{" "}
                  <strong>PDF, DOC, DOCX, XLSX, JPG, PNG</strong>. Max size:{" "}
                  <strong>5MB</strong>.
                </small>

                {formData.download_url ? (
                  <a
                    className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                    href={formData.download_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Scope of Work
                  </a>
                ) : (
                  ""
                )}
                {errors.file && (
                  <div className="text-danger">{errors.file}</div>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-secondary rounded-5 fw-semibold text-uppercase point-18 d-flex align-items-center justify-content-center"
              disabled={showloader} // Disable button while loading
              style={{ minWidth: "120px", height: "45px" }} // Keep button size consistent
            >
              {showloader ? (
                <span className="d-flex align-items-center">
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                  Submitting...
                </span>
              ) : (
                "Upadating Project"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
