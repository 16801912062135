
import { Modal } from 'react-bootstrap';
import AxiosInstance from '../../utils/axiosInstance';
import { useEffect, useState } from 'react';
import loaderImg from '../../assets/loader.svg'
import toast from 'react-hot-toast';

export default function SeeRecordsModel({ open, handleClose, callId, ccnCallId }) {
    const [optRecords, setOptRecords] = useState([])
    const [loader, setloader] = useState(false)

    useEffect(() => {
        getOptRecords();
    }, [callId, ccnCallId]);

    const getOptRecords = () => {
        const queryParams = new URLSearchParams({
            proposal_id: callId,
            ccn_call_id: ccnCallId,
        });
        setloader(true);
        AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_GET_OPTIN}&${queryParams}`)
            .then(response => {
                if (response.data?.data) {
                    setOptRecords(response.data?.data);
                    setloader(false);
                    toast.success(response.data?.message);
                } else {
                    setloader(false);
                    toast.success(response.data?.message);
                }
            })
            .catch(error => {
                toast.error(error?.message);
                setloader(false);
            });
    }

    return (
        <Modal
            size="xl"
            show={open} onHide={handleClose} centered
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton className="centered">
                <Modal.Title className="w-100 text-center">
                    OPT Reports
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-scroll'>
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12 bg-white py-5">
                            {!loader ?
                                <div className="row mb-3">
                                    {optRecords.length && optRecords ? optRecords
                                        .map((data, i) => (
                                            <div className="col-md-12" key={i}>
                                                <table className="table table-bordered"  key={i}>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="2" className="col-md-12 text-center fw-bold mb-3">{data?.company_name || '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">{data?.name || '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-8"><strong>Address:&nbsp;</strong>{'-'}</td>
                                                            <td className="col-4"><strong>Phone Number:&nbsp;</strong>{data?.phone || '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2"><strong>Disadvantaged Status:&nbsp;</strong>{data?.disadvantage_status?.map((item) => item?.name).join(',')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2"><strong>Services Offered:&nbsp;</strong>{data?.services_offered || '-'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>)) :
                                        <div className="d-md-flex justify-content-center">
                                            No Data Found.
                                        </div>
                                    }</div> : <div className="loader">
                                    <img src={loaderImg} alt="loading" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
};