import React, { useState, useEffect } from "react";
import relationshipImg from "../../../assets/relationship-img.svg";
import loader from "../../../assets/loader.svg";
import AxiosInstance from "../../../utils/axiosInstance"; // update the path as per your project
import {formatPhoneNumber} from "../../../utils/helper"; // update the path as per your project

const MySavedContactsComponent = ({ selectedState, storedState }) => {
  const [allFavContact, setAllFavContact] = useState([]);
  const [showLoader, setLoader] = useState(false);

  const getAllFavoriteContact = (state) => {
    setLoader(true);

    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_FAVORITES_DIRECTORY}?state_id=${state.value}`
    )
      .then((response) => {
        if (response.data?.data) {
          setAllFavContact(response.data?.data);
        } else {
          setAllFavContact([]);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.error(error?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    let state = "";
    if (selectedState) {
      state = selectedState;
    } else {
      state = storedState;
    }

    if (state) {
      getAllFavoriteContact(state);
    }
  }, [selectedState, storedState]);

  return (
    <div className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0">
      <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2">
        <div className="row g-0">
          <div className="col g-0">
            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
              My Saved Contacts
            </h3>
          </div>
          <div className="col-auto bg-secondary">
            <div className="rounded-pill bg-secondary w-100">
              <div className="d-flex flex-column justify-content-center">
                <img
                  src={relationshipImg}
                  alt="icon"
                  className="header-icon m-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body p-3">
        {!showLoader ? (
          allFavContact?.length > 0 ? (
            <ul className="list-group py-3 pe-3 mh-20">
              {allFavContact.map((data) => (
                <li
                  key={data?.account_id}
                  className="list-group-item py-3 mb-3 d-flex align-items-center bg-light border border-1 rounded-0"
                >
                  <div className="container-fluid">
                    <div className="row align-items-center">
                      <div className="col-md-3 text-primary fw-bold text-uppercase">
                        {data?.account_name || "N/A"}
                      </div>
                      <div className="col-md-2 text-primary">
                        {data?.contact_first_name || "N/A"}
                      </div>
                      <div className="col-md-2 text-primary">
                        {data?.contact_last_name || "N/A"}
                      </div>
                      <div className="col-md-2 text-primary">
                        {formatPhoneNumber(data?.contact_phone) || "N/A"}
                      </div>
                      <div className="col-md-3 text-primary">
                        {data?.contact_email || "N/A"}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="d-flex justify-content-center py-3">
              <p className="text-muted">No Data Found.</p>
            </div>
          )
        ) : (
          <div className="d-flex justify-content-center py-3">
            <img src={loader} alt="Loading..." className="loader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MySavedContactsComponent;
