import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { act, useEffect, useState } from "react";
import ProposalDot from "./ProposalDot";
import BidMatching from "../components/Dot/Proposals/BidMatchingDot";
import { Link, useLocation } from "react-router-dom";
import ProposalList from "../components/Dot/Proposals/ProposalList";
import ProposalMap from "../components/Dot/Proposals/ProposalMapComponent";
import { getFormattedDate } from "../utils/helper";
import Map from "../components/MapComponent";
export default function Dot() {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [viewMode, setViewMode] = useState("list");
  const [endPoint, setEndPoint] = useState(
    process.env.REACT_APP_GET_DIRECTORY_MAP
  );
  const [lattingDate, setLettingDate] = useState(null);
  const handleTabClick = (tab) => {
    console.log(tab);
    setActiveTab(tab);
  };
  useEffect(() => {
    if (location.state?.data) {
      setActiveTab(location.state?.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (activeTab === 2) {
      setViewMode("list");
    } else {
      handleViewMode(viewMode);
    }
  }, [activeTab]); //
  const handleViewMode = (value) => {
    let newEndPoint;
    if (activeTab === 0) {
      newEndPoint = process.env.REACT_APP_GET_DIRECTORY_MAP;
    } else if (activeTab === 1) {
      newEndPoint = process.env.REACT_APP_GET_DIRECTORY_MAP_;
    }

    setEndPoint(newEndPoint);

    setTimeout(() => {
      setViewMode(value);
    }, 0);
  };

  const handleLettingDate = (data) => {
    setLettingDate(data);
  };

  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-auto bg-primary mb-3">
              <p className="text-white point-22">Bid Opportunities</p>
              <h1 className="text-secondary fw-boldest point-42">DOT</h1>
              <div className="dotPage">
                <ul className="nav nav-tabs align-items-center">
                  <li className="nav-item">
                    <Link
                      className={`nav-link fw-bolder text-uppercase point-16 ${
                        activeTab === 0 ? "text-white" : "text-secondary"
                      }`}
                      title="Proposals"
                      onClick={() => handleTabClick(0)}
                    >
                      Proposals
                    </Link>
                  </li>
                  <span className="point-16 fw-bolder text-danger mx-2">|</span>
                  <li className="nav-item">
                    <Link
                      className={`nav-link fw-bolder text-uppercase point-16 ${
                        activeTab === 1 ? "text-white" : "text-secondary"
                      }`}
                      title="Bid Matching"
                      onClick={() => handleTabClick(1)}
                    >
                      Bid Matching
                    </Link>
                  </li>
                  <span className="point-16 fw-bolder text-danger mx-2">|</span>
                  <li className="nav-item">
                    <Link
                      className={`nav-link fw-bolder text-uppercase point-16 ${
                        activeTab === 2 ? "text-white" : "text-secondary"
                      }`}
                      title="Agency Information"
                      onClick={() => handleTabClick(2)}
                    >
                      Agency Information
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col text-end"></div>
          </div>
        </div>

        {activeTab == 0 && (
          <div className="container">
            <div className="row">
              {lattingDate && viewMode === "list" && (
                <span className="d-flex align-items-center gap-2 justify-content-left me-3 point-16">
                  <h1 className="text-white">
                    {getFormattedDate(lattingDate)}
                  </h1>
                  {console.log(lattingDate)}
                </span>
              )}

              <span className="d-flex align-items-center gap-2 justify-content-end me-3 point-16">
                <span className="text-secondary fw-bolder text-uppercase">
                  View as:
                </span>

                {/* List View Toggle */}
                <span
                  className={`point-16 fw-bolder text-uppercase ${
                    viewMode === "list" ? "text-white" : "text-secondary"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleViewMode("list")}
                >
                  List
                </span>

                <span className="text-secondary fw-bolder text-uppercase">
                  |
                </span>

                <span
                  className={`point-16 fw-bolder text-uppercase ${
                    viewMode === "map" ? "text-white" : "text-secondary"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleViewMode("map")}
                >
                  Map
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div className="card-body p-3">
                  {activeTab === 0 &&
                    (viewMode === "list" ? (
                      <div className="tab-content">
                        <div
                          className={`tab-pane fade ${
                            activeTab === 0 ? "show active" : ""
                          }`}
                          id="proposals"
                        >
                          <ProposalList handleLettingDate={handleLettingDate} />
                        </div>
                      </div>
                    ) : (
                      <ProposalMap
                        EndPoint={
                          process.env.REACT_APP_GET_PROJECT_PROPOSAL_MAP
                        }
                      />
                    ))}
                  {activeTab === 1 && (
                    <div className="tab-content">
                      <div
                        className={`tab-pane fade ${
                          activeTab === 1 ? "show active" : ""
                        }`}
                        id="bidMatching"
                      >
                        <BidMatching />
                      </div>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div className="tab-content">
                      <div
                        className={`tab-pane fade ${
                          activeTab === 2 ? "show active" : ""
                        }`}
                        id="agencyInfo"
                      >
                        <div
                          style={{
                            backgroundColor: "#fff",
                            color:
                              "rgba(var(--bs-primary-rgb), var(--bs-bg-opacity))",
                            padding: "15px",
                            borderRadius: "8px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "auto",
                          }}
                        >
                          <p>
                            <i className="bi bi-telephone-fill"></i>{" "}
                            <strong>Phone</strong>
                          </p>
                          <p>
                            <a
                              href="tel:+13343536554"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              (334) 353-6554
                            </a>
                          </p>
                          <p>
                            <i className="bi bi-envelope-fill"></i>{" "}
                            <strong>Mailing Address:</strong>
                          </p>
                          <p>P.O. Box 303050</p>
                          <p>Montgomery, AL 36130-3050</p>

                          <p>
                            <strong>Email</strong>
                          </p>
                          <p style={{ color: "#f0ad4e" }}>
                            <a
                              href="mailto:aldotinfo@dot.state.al.us"
                              style={{
                                
                                textDecoration: "underline",
                              }}
                            >
                              aldotinfo@dot.state.al.us
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
