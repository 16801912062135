import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { Link, useLocation } from "react-router-dom";
import paginationValue from "../common/paginationValue.json";
import Pagination from "../components/Layout/Pagination";
import aldotImg from "../assets/WAH-Logo-ALDOT-Approved.png";
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";
import UploadingRequest from "./UploadingRequest";
import axios from "axios";
import AxiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";
import loader from "../assets/loader.svg";
import favoriteImg from "../assets/bookmark-favorite.svg";
import MRBLOGO from "../assets/KMT_Logo_WhiteBG.png";

import OptInModel from "../components/Model/OptInModel";
import ViewPlanHolderModel from "../components/Model/ViewPlanHolderModel";
import SeeRecordsModel from "../components/Model/SeeRecordsModel";
import AdvertiseForHelpModel from "../components/Model/AdvertiseForHelpModel";
import PrimeContractorHelpModel from "../components/Model/PrimeContractorHelpModel";
function DesignBuildPage() {
  console.log("DesignBuildPage");
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [workTypeId, setWorkTypeId] = useState("");
  const [resetTrue, setResetTrue] = useState(false);
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [desingList, setDesignList] = useState([]);
  const [APICall, setAPICAll] = useState(true);
  const [favloader, setFavloader] = useState(false);
  const abortControllerRef = useRef(null);
  const [showloader, setloader] = useState(false);
  const [selectedState, setSelectedState] = useState(
    JSON.parse(localStorage.getItem("selectedState")) || null
  );
  const isFirstRender = useRef(true);

  const [categoryObject, setCategoryObject] = useState(
    location.state?.category || { label: "WAH", value: "DESIGN_BUILD_WAH" }
  );

  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpenViewPlan, setModdelOpenViewPlan] = useState(false);
  const [modelOpenOptIN, setModelOpenOptIN] = useState(false);
  const [modelSeeRecords, setModelSeeRecords] = useState(false);
  const [modelAdvertiseForHelp, setModelAdvertiseForHelp] = useState(false);
  const [PrimeContractorHelp, setModelPrimeContractorHelp] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedCcnCallId, setSelectedCcnCallId] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleReset = () => {
    setResetTrue(true);
    setWorkTypeId("");
  };

  useEffect(() => {
    if (APICall) {
      getDesignList();
      setAPICAll(false);
    }
  }, [APICall]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getDesignList();
  }, [currentPage, workTypeId]);
  const getDesignList = () => {
    console.log("getDesignList");
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setAPICAll(false);
    setloader(true);

    const controller = new AbortController();
    abortControllerRef.current = controller;
    const queryParams = new URLSearchParams({
      page_type: "DESIGN_BUILD",
      page: currentPage,
      limit: pageLimit,
      report_id: workTypeId,
      design_build_category: categoryObject.value.toUpperCase(),
    });

    const currentTime = new Date().getTime();
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_PROJECT}?currentTime=${currentTime}&${queryParams}`,
      { signal: controller.signal }
    )
      .then((response) => {
        if (response.data?.data?.length > 0) {
          setDesignList(response.data.data);
          setPageLimit(response.data.limit);
          setTotalRecords(response.data.total_records);
          setTotalPages(response.data.total_pages);
          setCurrentPage(response.data.page);
          toast.success(response.data?.message);
        } else {
          setDesignList([]);
          toast.success("No projects found.");
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          toast.error(error?.message);
        }
      })
      .finally(() => setloader(false));
  };
  const addFav = (account_Id, favorite) => {
    setloader(true);
    const payload = {
      project_id: account_Id,
      type: "DESIGN_BUILD",
      action: favorite,
      state_id: selectedState?.value,
    };

    AxiosInstance.post(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_FAVORITE}`,
      payload
    )
      .then((response) => {
        setloader(false);
        toast.success(response.data?.message);
        // getDirectoryList();
        setDesignList((prevList) =>
          prevList.map((item) =>
            item.project_id === account_Id
              ? { ...item, favorites: item.favorites === "0" ? "1" : "0" }
              : item
          )
        );
      })
      .catch((error) => {
        toast.error(error?.message);
        setloader(false);
      });
  };

  const handleAPIcall = () => {
    setAPICAll(true);
  };
  const handleModelOpenOptIN = (callid, ccnCallId) => {
    setAPICAll(false);
    //    setSelectedData(callid);
    // setSelectedCcnCallId(ccnCallId);
    setModelOpenOptIN(true);
  };

  const handleModelOpenSeeRecords = (callid, ccnCallId) => {
    //   setSelectedData(callid);
    //   setSelectedCcnCallId(ccnCallId);
    setModelSeeRecords(true);
  };

  const handleModelOpenAdvertiseForHelp = (callId, ccnCallId) => {
    setModelAdvertiseForHelp(true);
    //  setSelectedCcnCallId(ccnCallId);
    //  setSelectedData(callId);
  };
  const handleModelOpenPrimeContractorHelpModel = (ccnCallId, callId) => {
    //  setSelectedCcnCallId(ccnCallId);
    //    setSelectedData(callId);
    setModelPrimeContractorHelp(true);
  };

  const handleModelClose = () => setModelOpen(false);
  const handleModelCloseViewPlan = () => setModdelOpenViewPlan(false);
  const handleModelCloeseOpenOptIN = () => {
    setModelOpenOptIN(false);
  };
  const handleModelCloeseSeeRecords = () => setModelSeeRecords(false);
  const handleModelCloeseAdvertiseForHelp = () => {
    setModelAdvertiseForHelp(false);
  };

  const handleModelCloesePrimeContractorHelp = () =>
    setModelPrimeContractorHelp(false);

  return (
    <>
      <Header />

      <div className={`bg-primary pt-5 ${activeTab !== 1 ? "pb-20" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <p className="text-white point-22">Bid Opportunities</p>
              {activeTab === 0 ? (
                <h1 className="text-secondary fw-boldest point-42">
                  {categoryObject.label.toUpperCase()}
                </h1>
              ) : (
                <h1 className="text-secondary fw-boldest point-42">
                  {" "}
                  Uploading Request{" "}
                </h1>
              )}
              <div className="dotPage">
                <ul className="nav nav-tabs align-items-center">
                  <li className="nav-item">
                    <Link
                      className={`nav-link fw-bolder text-uppercase point-16 ${
                        activeTab === 0 ? "text-white" : "text-secondary"
                      }`}
                      title="Forms"
                      onClick={() => handleTabClick(0)}
                    >
                      Forms/Information
                    </Link>
                  </li>
                  <span className="point-16 fw-bolder text-danger mx-2">|</span>
                  <li className="nav-item">
                    <Link
                      className={`nav-link fw-bolder text-uppercase point-16 ${
                        activeTab === 1 ? "text-white" : "text-secondary"
                      }`}
                      onClick={() => handleTabClick(1)}
                      title="uplodingRequest"
                    >
                      Advertise for sub-quotes
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {activeTab !== 1 && (
              <>
                <div
                  className="dotPage col align-self-end mb-3 text-center"
                  style={{ marginLeft: "20%" }}
                >
                  <ul className="nav nav-tabs align-items-center">
                    <li className="nav-item">
                      <Link
                        to="/directory"
                        className="nav-link fw-bolder text-uppercase point-16 text-secondary"
                        title="Directory"
                      >
                        DBE Directory
                      </Link>
                    </li>
                    <span className="point-16 fw-bolder text-danger mx-2">
                      |
                    </span>
                    <li className="nav-item">
                      {(() => {
                        const href =
                          categoryObject.value === "DESIGN_BUILD_WAYBAY"
                            ? "#"
                            : categoryObject.value === "DESIGN_BUILD_WAH"
                            ? "https://westalabamahighway.com/"
                            : "https://mobileriverbridge.com/";

                        const target = href === "#" ? "_self" : "_blank";

                        return (
                          <a
                            href={href}
                            className="nav-link fw-bolder text-uppercase point-16 text-secondary"
                            target={target}
                            rel="noopener noreferrer"
                          >
                            B&G
                          </a>
                        );
                      })()}
                    </li>
                  </ul>
                </div>

                {categoryObject.value !== "DESIGN_BUILD_WAYBAY" && (
                  <div className="col col-md-2 text-end">
                    <a
                      href={
                        categoryObject.value === "DESIGN_BUILD_WAH"
                          ? "https://westalabamahighway.com/"
                          : "https://mobileriverbridge.com/ "
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          categoryObject.value === "DESIGN_BUILD_WAH"
                            ? aldotImg
                            : MRBLOGO
                        }
                        width="160px"
                        alt={categoryObject.value}
                        style={{ cursor: "pointer" }}
                      />
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {activeTab === 0 && (
        <>
          <div className="bg-white py-3">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-12 col-md-auto">
                          <span
                            className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
                            id="basic-addon1"
                          >
                            Filter:
                          </span>
                        </div>
                        <div className="col-12 col-md">
                          <div className="input-group mb-3">
                            <div style={{ width: "100%" }}>
                              <WorkTypeFilter
                                onSelectWorkType={handleWorkTypeSelect}
                                reset={resetTrue}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md mb-2">
                          <div className="input-group mb-3">
                            <input
                              type="button"
                              className="form-control btn btn-primary text-white text-uppercase me-2 fw-bolder rounded-pill"
                              placeholder="Search"
                              aria-label="Search"
                              value="Search"
                              aria-describedby="basic-addon1"
                            />
                            <input
                              type="button"
                              className="form-control btn btn-link text-uppercase me-2 text-danger text-start"
                              placeholder="Reset"
                              aria-label="Reset"
                              value="Reset"
                              onClick={() => handleReset()}
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div className="col">
                          <div className="clearfix">
                            <span className="float-md-end">
                              <span className="point-12 text-black-50 fw-light">
                                <span className="point-18">
                                  <i className="bi bi-star fs-6"></i>
                                </span>
                                <span className="text-uppercase fw-bold">
                                  &nbsp;Save to Dashboard
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <ul className="list-group py-3">
                          {!showloader ? (
                            desingList.length > 0 ? (
                              desingList.map((data, i) => (
                                <li
                                  key={data.project_id}
                                  className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer"
                                >
                                  <div className="d-md-flex justify-content-between align-items-start">
                                    <div className="me-auto mb-2">
                                      <span className="fw-bold point-22">
                                        {data.project_title}&nbsp;
                                      </span>
                                      <span className="point-22 text-black-50 fw-light mb-2">
                                        {data?.favorites === "0" ? (
                                          <i
                                            className="bi bi-star fs-6"
                                            onClick={() =>
                                              addFav(
                                                data?.project_id,
                                                data?.favorites
                                              )
                                            }
                                          ></i>
                                        ) : (
                                          <img
                                            src={favoriteImg}
                                            alt="fav"
                                            onClick={() =>
                                              addFav(
                                                data?.project_id,
                                                data?.favorites
                                              )
                                            }
                                          />
                                        )}
                                      </span>
                                    </div>
                                    <div className="mb-2">
                                      <div className="mb-2">
                                        {data.download_url ? (
                                          <a
                                            className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                                            href={data.download_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                          >
                                            Scope of Work
                                          </a>
                                        ) : (
                                          <span className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">
                                            Scope of Work
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <p className="lh-lg">
                                    <span className="fw-bold">DBE Goal:</span>{" "}
                                    {data.dbe_goal}% <br />
                                    {data.date_entered &&
                                      data.estimated_end_date && (
                                        <>
                                          <span className="fw-bold">
                                            Open Date:
                                          </span>{" "}
                                          {new Date(
                                            data.date_entered
                                          ).toLocaleDateString("en-US")}
                                          <span className="text-danger fw-bolder">
                                            &nbsp;|&nbsp;
                                          </span>
                                          <span className="fw-bold">
                                            Close Date:
                                          </span>{" "}
                                          {new Date(
                                            data.estimated_end_date
                                          ).toLocaleDateString("en-US")}
                                          <br />
                                        </>
                                      )}
                                    {data.description && (
                                      <>
                                        <span className="fw-bold">
                                          Description:
                                        </span>{" "}
                                        {data.description}
                                        <br />
                                      </>
                                    )}
                                    <span className="fw-bold">
                                      Description:{" "}
                                    </span>
                                    {data?.Ldescription} <br />
                                    {!data?.flag_optin ? (
                                      <span
                                        className="fw-bold text-decoration-underline"
                                        onClick={() =>
                                          handleModelOpenOptIN(
                                            data?.callid,
                                            data?.ccn_call_id
                                          )
                                        }
                                      >
                                        Subs/Suppliers: Opt In
                                      </span>
                                    ) : (
                                      <span className="fw-bold text-secondary">
                                        Subs/Suppliers: Opt In
                                      </span>
                                    )}
                                    <span className="text-danger fw-bolder d-none d-md-inline-block">
                                      &nbsp;|&nbsp;
                                    </span>
                                    <br className="d-block d-md-none" />
                                    <span
                                      className="text-decoration-underline"
                                      onClick={() =>
                                        handleModelOpenSeeRecords(
                                          data?.callid,
                                          data?.ccn_call_id
                                        )
                                      }
                                    >
                                      See{" "}
                                      <span className="fw-bold">
                                        {data?.count_optin}
                                      </span>{" "}
                                      Opt-in Records
                                    </span>
                                    <span className="text-danger fw-bolder d-none d-md-inline-block">
                                      &nbsp;|&nbsp;
                                    </span>
                                    <br className="d-block d-md-none" />
                                    {!data?.flag_prime_advertise ? (
                                      <span
                                        className="fw-bold text-decoration-underline"
                                        onClick={() =>
                                          handleModelOpenAdvertiseForHelp(
                                            data?.callid,
                                            data?.ccn_call_id
                                          )
                                        }
                                      >
                                        &nbsp;Primes: Advertise for help
                                      </span>
                                    ) : (
                                      <span className="fw-bold text-secondary">
                                        &nbsp;Primes: Advertise for help
                                      </span>
                                    )}
                                    <span className="text-danger fw-bolder d-none d-md-inline-block">
                                      &nbsp;|&nbsp;
                                    </span>
                                    <br className="d-block d-md-none" />
                                    <span className="fw-bold ">
                                      {data?.count_prime_advertise || 0}
                                    </span>{" "}
                                    <span
                                      className="text-decoration-underline"
                                      onClick={() =>
                                        handleModelOpenPrimeContractorHelpModel(
                                          data?.ccn_call_id,
                                          data?.callid
                                        )
                                      }
                                    >
                                      Prime contractors looking for help
                                    </span>
                                  </p>
                                </li>
                              ))
                            ) : (
                              <div className="d-md-flex justify-content-center">
                                No Data Found.
                              </div>
                            )
                          ) : (
                            <div className="loader">
                              <img src={loader} alt="loading" />
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      pageLimit={pageLimit}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {modelOpenOptIN && (
            <OptInModel
              open={modelOpenOptIN}
              ccnCallId={selectedCcnCallId}
              callId={selectedData}
              handleClose={handleModelCloeseOpenOptIN}
              handleAPIcall={handleAPIcall}
            />
          )}

          {modelOpenViewPlan && (
            <ViewPlanHolderModel
              open={modelOpenViewPlan}
              handleClose={handleModelCloseViewPlan}
              callId={selectedData}
            />
          )}
          {modelOpenOptIN && (
            <OptInModel
              open={modelOpenOptIN}
              ccnCallId={selectedCcnCallId}
              callId={selectedData}
              handleClose={handleModelCloeseOpenOptIN}
              handleAPIcall={handleAPIcall}
            />
          )}
          {modelSeeRecords && (
            <SeeRecordsModel
              open={modelSeeRecords}
              ccnCallId={selectedCcnCallId}
              callId={selectedData}
              handleClose={handleModelCloeseSeeRecords}
            />
          )}
          {modelAdvertiseForHelp && (
            <AdvertiseForHelpModel
              open={modelAdvertiseForHelp}
              handleClose={handleModelCloeseAdvertiseForHelp}
              callId={selectedData}
              ccnCallId={selectedCcnCallId}
              handleAPIcall={handleAPIcall}
            />
          )}
          {PrimeContractorHelp && (
            <PrimeContractorHelpModel
              open={PrimeContractorHelp}
              callId={selectedData}
              ccnCallId={selectedCcnCallId}
              handleClose={handleModelCloesePrimeContractorHelp}
            />
          )}
        </>
      )}
      {activeTab === 1 && <UploadingRequest />}
      <Footer />
    </>
  );
}
export default DesignBuildPage;
