import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import AxiosInstance from "../utils/axiosInstance";
import Select from "react-select";
import CountryFilter from "../components/Filters/CountryFilter";
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

export default function UploadingRequest() {
  const [workTypeList, setWorkTypeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [resetTrue, setResetTrue] = useState(false);
  const [workTypeId, setWorkTypeId] = useState([]);
  const [country, setCountry] = useState("");
  const [showloader, setloader] = useState(false);
  const [tabs, setTabs] = useState([]); // Stores tab list
  const [activeTab, setActiveTab] = useState(""); // Stores active tab
  const [StateListFromLocalStorage, setStateListFromLocalStorage] = useState(
    []
  );
  const [storedState, setstoredState] = useState(
    JSON.parse(localStorage.getItem("selectedState"))
  );
  const [selectedState, setSelectedState] = useState();
  const isInitialRender = useRef(true);

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null); // Reference for file input
  const options = {
    city: "City",
    county: "County",
    design_build: "Design Build",
    private: "Private",
    state: "State",
  };
  const location = useLocation();
  const navigate = useNavigate();

  const EditData = useRef(location.state?.data || {});

  const design_build_category_options = [
    { value: "DESIGN_BUILD_MRB", label: "MRB" },
    { value: "DESIGN_BUILD_WAH", label: "WAH" },
    { value: "DESIGN_BUILD_WAYBAY", label: "BAYWAY" },
  ];

  const getStoredUserInfo = () => {
    const storedData = localStorage.getItem("loggedInUserInfo");
    return storedData ? JSON.parse(storedData) : {};
  };
  const isFileCorruptedRef = useRef(false);
  const init = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    date_submitting: "",
    dbe_goal: "",
    project_title: "",
    project_description: "",
    project_page_type: "",
    design_build_category: "",
    worktypes: {},
    county: "",
    state_id: "",
    file: null,
  };

  useEffect(() => {
    const userInfo = getStoredUserInfo();

    setFormData((prevState) => ({
      ...prevState,
      first_name: userInfo.first_name || "",
      last_name: userInfo.last_name || "",
      email: userInfo.email || "",
      phone_number: userInfo.phone_number || "",
    }));
  }, []);

  const handleChange = (selected) => {
    const workTypeData = transformWorkTypeData(workTypeList, selected);
    // setFormData((prevData) => ({ ...prevData, 'worktypes': workTypeData }));
    setSelectedOptions(selected);
  };

  const [formData, setFormData] = useState(init);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    let state = "";
    if (selectedState) {
      state = selectedState;
    } else {
      state = storedState;
    }
  }, [selectedState, storedState]);

  const handleStateChange = (selected) => {
    setSelectedState(selected);
    setFormData((prevFormData) => ({
      ...prevFormData,
      state_id: selected.value,
    }));
    setErrors((prev) => ({
      ...prev,
      ["state_id"]: "",
    }));
    isInitialRender.current = false;
  };

  const handleCategoryChange = (selected) => {
    setSelectedState(selected);
    setFormData((prevFormData) => ({
      ...prevFormData,
      design_build_category: selected.value,
    }));
    setErrors((prev) => ({
      ...prev,
      ["design_build_category"]: "",
    }));
    // isInitialRender.current = false;
  };

  useEffect(() => {
    const fetchAPIs = async () => {
      try {
        getTabType();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    const data = JSON.parse(localStorage.getItem("loggedInUserInfo") || "[]");
    const transformedData = transformData(data?.state || []);
    setStateListFromLocalStorage(transformedData);
    if (!storedState && transformedData.length > 0) {
      localStorage.setItem("selectedState", JSON.stringify(transformedData[0]));
      setSelectedState(transformedData[0]);
    }

    fetchAPIs();
  }, []);
  const transformData = (items) =>
    items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const transformWorkTypeData = (originalData, selectedValues) => {
    const selectedIds = selectedValues.map((item) => item.value);

    const result = Object.keys(originalData).reduce((acc, key) => {
      acc[key] = originalData[key]
        .filter((item) => selectedIds.includes(item.id))
        .map((item) => item.id);
      return acc;
    }, {});

    return result;
  };

  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId((prevWorkTypeIds) => {
      return workType;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      worktypes: {
        highway: workType, // Append correctly
      },
    }));
    setResetTrue(false);
    setErrors((prev) => ({
      ...prev,
      ["worktypes"]:
        workType.length > 0 ? "" : "Please select at least one work type",
    }));
  };

  const handleCountrySelect = (country) => {
    setCountry(country);
    setFormData((prevFormData) => ({
      ...prevFormData,
      county: country,
    }));
    setErrors((prev) => ({
      ...prev,
      ["county"]: "",
    }));

    setResetTrue(false);
  };
  const getTabType = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}/get_request`)
      .then((response) => {
        const tabString = response.data.data.contact_request;
        const formattedTabs = tabString
          .replace(/\^/g, "")
          .split(",")
          .map((item) => item.trim())
          .sort();
        setTabs(formattedTabs);
        setActiveTab(formattedTabs[0]);
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };

  const handleTabClick = (tab) => {
    if (activeTab === tab) return;
    setActiveTab(tab);
  };

  const validateForm = () => {
    const newErrors = {};
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    const allowedExtensions = ["pdf", "doc", "docx", "xlsx", "jpg", "png"];

    if (!formData.first_name.trim())
      newErrors.first_name = "First name is required.";
    if (!formData.last_name.trim())
      newErrors.last_name = "Last name is required.";

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!formData.phone_number.trim()) {
      newErrors.phone_number = "Phone number is required.";
    } else if (
      !/^(\+1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(
        formData.phone_number
      )
    ) {
      newErrors.phone_number = "Invalid phone number format.";
    }

    if (!formData.date_submitting.trim())
      newErrors.date_submitting = "Date submitting is required.";
    if (!formData.dbe_goal.trim()) newErrors.dbe_goal = "DBE goal is required.";
    if (!formData.project_description.trim())
      newErrors.project_description = "Project description is required.";
    if (!formData.project_title.trim())
      newErrors.project_title = "Project title is required.";

    // Validate active tab selection
    if (activeTab === "county") {
      if (!formData.county?.trim()) newErrors.county = "County is required.";
    }

    if (activeTab === "design_build") {
      if (!formData.design_build_category?.trim())
        newErrors.design_build_category = "Category is required.";
    }

    if (
      !formData.worktypes ||
      Object.keys(formData.worktypes).length === 0 ||
      formData.worktypes.highway.length === 0
    ) {
      newErrors.worktypes = "At least one work type is required.";
    }

    if (!formData.file || formData.file.length === 0) {
      newErrors.file = " file is required .";
    } else {
      const file = formData.file; // Assume single file upload

      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileSize > maxFileSize) {
        newErrors.file = "File must be 5MB or less.";
      } else if (file.size === 0) {
        newErrors.file = "This file is empty! Please select a valid file.";
      } else if (!allowedExtensions.includes(fileExtension)) {
        newErrors.file = `Invalid file type. Allowed: ${allowedExtensions.join(
          ", "
        )}`;
      } else if (isFileCorruptedRef.current === true) {
        newErrors.file = "This file appears to be invalid or does not exist.";
      }
    }
    return newErrors;
  };

  const resetForm = () => {
    //setFormData(init);
    setResetTrue(true);
    setSelectedState("");
    const userInfo = getStoredUserInfo(); // Fetch user info again

    setFormData({
      ...init, // Reset to initial structure
      first_name: userInfo.first_name || "",
      last_name: userInfo.last_name || "",
      email: userInfo.email || "",
      phone_number: userInfo.phone_number || "",
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    setSelectedFile(null);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(true);

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setloader(false);
      return;
    }
    setErrors({});

    const { file, ...formValue } = formData;
    formValue.project_page_type = activeTab.toUpperCase();
    if (activeTab === "state") {
      formValue.state_id = storedState.value;
    }

    try {
      const response = await AxiosInstance.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_PROJECT}`,
        formValue
      );

      if (response?.data) {
        // toast.success(response.data.message);

        if (response.data.project_id) {
          const projectId = response.data.project_id; // Corrected project ID extraction
          console.log(projectId);
          if (file) {
            try {
              const formDataForFile = new FormData();

              // Extract file extension
              const fileExtension = file.name.split(".").pop();
              const renamedFileName = `${projectId}.${fileExtension}`;

              // Rename the file while preserving its MIME type
              const renamedFile = new File([file], renamedFileName, {
                type: file.type,
              });

              // Append file & project_id
              formDataForFile.append("file", renamedFile);
              formDataForFile.append("project_id", projectId);

              // Send the request
              const response = await AxiosInstance.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_UPLOAD_PROJECT_FILE}`,
                formDataForFile
              );

              toast.success("Data and file uploaded successfully");
            } catch (fileUploadError) {
              console.error("File Upload Error:", fileUploadError);
              toast.error("Data uploaded, but file upload failed!");
            }
          } else {
            toast.error("Project created, but no file uploaded.");
          }
        } else {
          toast.error("Project ID missing. Please try again.");
        }
      } else {
        toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      console.error("Form Submission Error:", error);
      toast.error(
        error?.data?.message || "Error submitting data. Please try again."
      );
    } finally {
      setloader(false); // Hide loader after process
      resetForm(); // Reset form only after everything completes

      setTimeout(() => {
        navigate("/myprojects");
      }, 1000);
    }
  };

  const handleInputChange = (e) => {
    console.log();
    if (!e || !e.target) {
      console.error("Invalid event: ", e);
      return;
    }

    const { name, value, type, files } = e.target;

    console.log(name, value);
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    const allowedExtensions = ["pdf", "doc", "docx", "xlsx", "jpg", "png"];

    if (type === "file") {
      const file = files?.[0];

      if (!file) {
        setErrors((prev) => ({
          ...prev,
          [name]: "No file selected.",
        }));
        return;
      }

      const fileSize = file.size;
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileSize === 0) {
        setErrors((prev) => ({
          ...prev,
          [name]: "This file is empty! Please select a valid file.",
        }));
        return;
      }

      if (fileSize > maxFileSize) {
        setErrors((prev) => ({
          ...prev,
          [name]: "File must be 5MB or less.",
        }));
        return;
      }

      if (!allowedExtensions.includes(fileExtension)) {
        setErrors((prev) => ({
          ...prev,
          [name]: `Invalid file type. Allowed: ${allowedExtensions.join(", ")}`,
        }));
        return;
      }

      // File validation passed → Update state
      setSelectedFile(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Attempt to read the file to verify its existence
      const reader = new FileReader();
      reader.onload = () => {
        isFileCorruptedRef.current = false;
      };
      reader.onerror = () => {
        isFileCorruptedRef.current = true;
        setErrors((prev) => ({
          ...prev,
          [name]: "This file appears to be invalid or does not exist.",
        }));
      };
      reader.readAsArrayBuffer(file);
    } else {
      // Handle non-file inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Clear errors on successful input

    if (name === "dbe_goal" && (parseInt(value) < 0 || parseInt(value) > 99)) {
      console.log("af");
      setErrors((prev) => ({
        ...prev,
        [name]: "Only 0 to 99 value accepted ",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: value ? "" : "field is required ",
      }));
    }
  };
  const formatDateForDB = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // ✅ Save as YYYY-MM-DD
  };

  const handleDateChange = (name, date) => {
    if (date) {
      const formattedDate = formatDateForDB(date); // Convert to YYYY-MM-DD
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
      }));

      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  return (
    <>
      {location.pathname !== "/designbuild" && (
        <>
          <Header />
          <div className="bg-primary py-3">
            <div className="container">
              <div className="row mt-10">
                <div className="col col-md-auto">
                  <p className="text-white point-22">Bid Opportunities</p>
                  <h1 className="text-secondary fw-boldest point-42">
                    Uploading Request
                  </h1>
                </div>
                <div className="col">&nbsp;</div>
                <div className="dotPage">
                  <ul className="nav nav-tabs align-items-center">
                    {tabs.length > 0 &&
                      tabs.map((tab, index) => (
                        <React.Fragment key={index}>
                          <li className="nav-item">
                            <button
                              className={`nav-link fw-bolder text-uppercase point-16 ${
                                activeTab === tab
                                  ? "text-white bg-primary"
                                  : "text-secondary"
                              }`}
                              onClick={() => handleTabClick(tab)}
                            >
                              {options[tab] ?? tab}
                              {/* ✅ Correct way to use label */}
                            </button>
                          </li>
                          {/* Add separator except for the last tab */}
                          {index < tabs.length - 1 && (
                            <span className="point-16 fw-bolder text-danger mx-2">
                              |
                            </span>
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="bg-white py-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <h2 className="fw-bold point-32">Get Started</h2>
                <span>
                  ( All fields required <span className="text-danger">*</span> )
                </span>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="First name"
                      aria-label="First name"
                      name="first_name"
                      readOnly={true}
                      value={formData.first_name}
                      onChange={handleInputChange}
                    />
                    {errors.first_name && (
                      <div className="text-danger">{errors.first_name}</div>
                    )}
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Last name"
                      aria-label="Last name"
                      name="last_name"
                      readOnly={true}
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                    {errors.last_name && (
                      <div className="text-danger">{errors.last_name}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Email"
                      aria-label="Email"
                      name="email"
                      readOnly={true}
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                      name="phone_number"
                      readOnly={true}
                      value={formData.phone_number}
                      onChange={handleInputChange}
                    />
                    {errors.phone_number && (
                      <div className="text-danger">{errors.phone_number}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <DatePicker
                      selected={
                        formData.date_submitting
                          ? new Date(formData.date_submitting)
                          : null
                      }
                      onChange={(date) =>
                        handleDateChange("date_submitting", date)
                      } // ✅ Pass function correctly
                      className="form-control border border-secondary rounded-0"
                      placeholderText="Deadline Date for submitting quotes (MM-DD-YYYY)" // ✅ Custom placeholder
                      dateFormat="MM-dd-yyyy" // Ensures correct format
                      minDate={new Date()} // Disables past dates
                    />
                    {errors.date_submitting && (
                      <div className="text-danger">
                        {errors.date_submitting}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="number"
                      className="form-control border border-secondary rounded-0"
                      placeholder="DBE Goal% (0 - 99 )"
                      name="dbe_goal"
                      min="0"
                      max="99"
                      aria-label="DBE Goal%"
                      value={formData.dbe_goal}
                      onChange={handleInputChange}
                    />
                    {errors.dbe_goal && (
                      <div className="text-danger">{errors.dbe_goal}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Project Title"
                      aria-label="Project Title"
                      name="project_title"
                      value={formData.project_title}
                      onChange={handleInputChange}
                    />
                    {errors.project_title && (
                      <div className="text-danger">{errors.project_title}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <textarea
                      className="form-control border border-secondary rounded-0"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Project Description"
                      name="project_description"
                      value={formData.project_description}
                      onChange={handleInputChange}
                    ></textarea>
                    {errors.project_description && (
                      <div className="text-danger">
                        {errors.project_description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* Work Type Filter */}
                  <div className="col-12 col-md-6 mb-3">
                    <WorkTypeFilter
                      onSelectWorkType={handleWorkTypeSelect}
                      reset={resetTrue}
                      borderTrue={true}
                      ismultiple={true}
                    />
                    {errors.worktypes && (
                      <div className="text-danger">{errors.worktypes}</div>
                    )}
                  </div>

                  {/* Conditional Inputs Based on Active Tab */}
                  {/* State Selection */}
                  {null && activeTab === "state" && (
                    <>
                      <div className="col-12 col-md-6 mb-3">
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                          })}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: "#9FAFCA",
                              borderWidth: "3px",
                              "&:hover": {
                                borderColor: "#2684FF",
                              },
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "#a71e1e",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#6c757d",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#9FAFCA"
                                : "white",
                              color: state.isSelected ? "white" : "black",
                              "&:hover": {
                                backgroundColor: "#9FAFCA",
                                color: "white",
                              },
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                              borderRadius: 0,
                            }),
                          }}
                          options={StateListFromLocalStorage}
                          value={selectedState || null}
                          name="state_id"
                          onChange={handleStateChange}
                          placeholder="Select State type..."
                          isSearchable
                        />

                        {errors.state_id && (
                          <div className="text-danger">{errors.state_id}</div>
                        )}
                      </div>
                    </>
                  )}

                  {/* County Selection */}
                  {activeTab === "county" && (
                    <>
                      <div className="col-12 col-md-6 mb-3">
                        <CountryFilter
                          onSelectCountry={handleCountrySelect}
                          reset={resetTrue}
                          borderTrue={true}
                        />
                        {errors.county && (
                          <div className="text-danger">{errors.county}</div>
                        )}
                      </div>
                    </>
                  )}

                  {activeTab === "design_build" && (
                    <>
                      <div className="col-12 col-md-6 mb-3">
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                          })}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: "#9FAFCA",
                              borderWidth: "3px",
                              "&:hover": {
                                borderColor: "#2684FF",
                              },
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "#a71e1e",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#6c757d",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#9FAFCA"
                                : "white",
                              color: state.isSelected ? "white" : "black",
                              "&:hover": {
                                backgroundColor: "#9FAFCA",
                                color: "white",
                              },
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                              borderRadius: 0,
                            }),
                          }}
                          options={design_build_category_options}
                          value={selectedState || null}
                          name="design_build_category"
                          onChange={handleCategoryChange}
                          placeholder="Select Categoty Type"
                          isSearchable
                        />
                        {errors.design_build_category && (
                          <div className="text-danger">
                            {errors.design_build_category}
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {/* File Input */}
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      className="form-control border border-secondary rounded-0"
                      type="file"
                      id="formFile"
                      name="file"
                      onChange={handleInputChange}
                      ref={fileInputRef} // Attach ref to input
                    />
                    <small className="text-muted">
                      Accepted file types:{" "}
                      <strong>PDF, DOC, DOCX, XLSX, JPG, PNG</strong>. Max size:{" "}
                      <strong>5MB</strong>.
                    </small>
                    {errors.file && (
                      <div className="text-danger">{errors.file}</div>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-secondary rounded-5 fw-semibold text-uppercase point-18 d-flex align-items-center justify-content-center"
                  disabled={showloader} // Disable button while loading
                  style={{ minWidth: "120px", height: "45px" }} // Keep button size consistent
                >
                  {showloader ? (
                    <span className="d-flex align-items-center">
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                      ></span>
                      Submitting...
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {location.pathname !== "/designbuild" && <Footer />}
    </>
  );
}
