
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ListOfBidModel from './ListOfBidModel';
import toast from 'react-hot-toast';
import AxiosInstance from '../../utils/axiosInstance';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clanderSvg from '../../assets/calander.svg'

export default function AdvertiseForHelpModel({ open, handleClose, callId, ccnCallId, handleAPIcall }) {
    const [bidItemsModel, setBidItemsModel] = useState(false);
    const [openFromAdForHelp, setOpenFromAdForHelp] = useState(false);
    const [selectedBiditems, setSelectedBiditems] = useState([]);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        company_name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        street_address: "",
        city: "",
        state: "",
        zip: "",
        requirements: "",
        due_date: "",
        proposal_id: callId,
        ccn_call_id: ccnCallId,
        services_needed: []
    });

    useEffect(() => {
        const loggedInUserInfo = JSON.parse(localStorage.getItem("loggedInUserInfo"));
        const { state, ...filteredUserInfo } = loggedInUserInfo;
        setFormData(prevState => ({
            ...prevState,
            ...filteredUserInfo
        }));
    }, [])

    const handleModelOpen = () => {
        setBidItemsModel(true);
        setOpenFromAdForHelp(true)
    }
    const handleModelClose = () => setBidItemsModel(false);
    const handleSelectBidItems = (selectedItems) => {
        setSelectedBiditems(selectedItems.map(item => item.Item));
        setFormData((prevData) => ({ ...prevData, 'services_needed': selectedItems.map(item => item.id) }));
        setErrors((prev) => ({
            ...prev,
            'services_needed': ''
        }));
        setBidItemsModel(false);
    };
    const validateForm = () => {
        const newErrors = {};
        if (!formData.company_name.trim()) newErrors.company_name = "Company name is required.";
        if (!formData.first_name.trim()) newErrors.first_name = "First name is required.";
        if (!formData.last_name.trim()) newErrors.last_name = "Last name is required.";
        if (!formData.email.trim()) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format.";
        }
        if (!formData.phone_number.trim()) newErrors.phone_number = "Phone number is required.";
        else if (!/^(\+1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formData.phone_number)) {
            newErrors.phone_number = "Invalid phone number format.";
        }

        if (!formData.street_address.trim()) newErrors.street_address = "Street Address is required.";
        if (!formData.city.trim()) newErrors.city = "City is required.";
        if (!formData.zip.trim()) {
            newErrors.zip = "ZIP is required.";
        } else if (!/^\d{1,10}$/.test(formData.zip.trim())) {
            newErrors.zip = "ZIP code must be numeric and up to 10 digits.";
        }

        if (!formData.state.trim()) newErrors.state = "State is required.";
        if (!formData.services_needed.length) newErrors.services_needed = "Please select any Service";
        if (!formData.requirements.trim()) newErrors.requirements = "Please Enter some requirements";
        if (!formData.due_date.trim()) newErrors.due_date = "Please select a date";
        return newErrors;
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } setErrors({});

        const formValue = {
            ...formData,
            due_date: formData.due_date
                ? moment(formData.due_date, "MM-DD-YYYY").format("YYYY-MM-DD")
                : null
        };

        AxiosInstance.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_ADD_ADVERTISE_FOR_HELP}`, formValue)
            .then(response => {
                if (response?.data) {
                    toast.success(response?.data?.message);
                    handleClose();
                    handleAPIcall();
                } else {
                    toast.error(response?.data?.message);
                }
            })
            .catch(error => {
                toast.error(error?.data?.message);

            });
    };
    return (
        <>
            <Modal
                size="l"
                show={open} onHide={handleClose} centered
                aria-labelledby="staticBackdropLabel"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">
                        <h1 className="modal-title point-32 fw-bold text-primary">Primes Advertise For Help</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-scroll'>
                    <div className="container">
                        <div className="row">
                            <p>
                                Thank you for using the Opt-In Search Tool. Please note that the
                                Opt-In Search Tool does not replace good faith efforts as
                                described in the contract documents. This is a service for bidding
                                / prime contractors to let available subcontractors and suppliers
                                know what you intend to subcontract out or what materials or
                                services you need provided while working on this project. aware
                                that opt-in users will not receive updates when addenda are
                                issued.
                            </p>
                            <div className="col col-md-12 bg-white">
                                <div className="row mb-3">
                                    <form className="px-3" onSubmit={handleSubmit}>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                    <span className="fw-bold point-16 text-secondary text-uppercase">Company Name</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control border border-secondary rounded-0 secondary text-secondary ${errors.company_name ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="company_name"
                                                    name="company_name"
                                                    value={formData.company_name}
                                                />
                                                {errors.company_name && <div className="text-danger">{errors.company_name}</div>}
                                            </div>
                                        </div>
                                        <label className="form-check-label" htmlFor="flexCheckDefault1">
                                            <span className="fw-bold point-16 text-secondary text-uppercase">Contact Person</span>
                                        </label>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="First name"
                                                    className={`form-control border border-secondary rounded-0 ${errors.first_name ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="first_name"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="Last name"
                                                    className={`form-control border border-secondary rounded-0 ${errors.last_name ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="last_name"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="Email"
                                                    className={`form-control border border-secondary rounded-0 ${errors.email ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.email && <div className="text-danger">{errors.email}</div>}
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    className={`form-control border border-secondary rounded-0 ${errors.phone_number ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="phone_number"
                                                    name="phone_number"
                                                    value={formData.phone_number}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="Street Address"
                                                    className={`form-control border border-secondary rounded-0 ${errors.street_address ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="street_address"
                                                    name="street_address"
                                                    value={formData.street_address}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.street_address && <div className="text-danger">{errors.street_address}</div>}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="City"
                                                    className={`form-control border border-secondary rounded-0 ${errors.city ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="city"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.city && <div className="text-danger">{errors.city}</div>}
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="State"
                                                    className={`form-control border border-secondary rounded-0 ${errors.state ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="state"
                                                    name="state"
                                                    value={formData.state}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.state && <div className="text-danger">{errors.state}</div>}
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    placeholder="ZIP"
                                                    className={`form-control border border-secondary rounded-0 ${errors.zip ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="zip"
                                                    name="zip"
                                                    value={formData.zip}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.zip && <div className="text-danger">{errors.zip}</div>}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <p
                                                    className="text-primary cursor-pointer mb-2"
                                                    onClick={() => handleModelOpen()}
                                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                                >
                                                    Add: "List of Bid Items"
                                                </p>
                                                <textarea
                                                    className={`form-control form-textarea border border-secondary rounded-0 ${errors.services_needed ? "is-invalid" : ""
                                                        }`}
                                                    name="services_needed"
                                                    placeholder="Services Needed"
                                                    value={selectedBiditems.join(', ')}
                                                    readOnly
                                                    onChange={handleInputChange}
                                                ></textarea>
                                                {errors.services_needed && <div className="text-danger">{errors.services_needed}</div>}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <textarea
                                                    className={`form-control form-textarea border border-secondary rounded-0 ${errors.requirements ? "is-invalid" : ""
                                                        }`}
                                                    name="requirements"
                                                    placeholder="Requirements"
                                                    value={formData.requirements}
                                                    onChange={handleInputChange}
                                                ></textarea>
                                                {errors.requirements && <div className="text-danger">{errors.requirements}</div>}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-3">
                                                <DatePicker
                                                    selected={formData.due_date ? new Date(formData.due_date) : ''}
                                                    placeholderText='MM-DD-YYYY'
                                                    onChange={(date) => handleInputChange({ target: { name: "due_date", value: moment(date).format("MM-DD-YYYY") } })}
                                                    dateFormat="MM-dd-yyyy"
                                                    className={`form-control form-textarea border border-secondary rounded-0 ${errors.due_date ? "is-invalid" : ""}`}
                                                    minDate={new Date().toISOString().split('T')[0]}
                                                    showIcon={clanderSvg}
                                                />
                                                {errors.due_date && <div className="text-danger">{errors.due_date}</div>}
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary rounded-5 fw-semibold  point-18"
                                        >
                                            Submit
                                        </button>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                bidItemsModel && <ListOfBidModel
                    open={bidItemsModel}
                    handleClose={handleModelClose}
                    callId={callId}
                    openFromAdForHelp={openFromAdForHelp}
                    handleSelectBidItems={handleSelectBidItems}
                />
            }
        </>
    );
};