import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import searchIcon from "../../assets/search-icon.svg";
import CountryFilter from "../../components/Filters/CountryFilter";
import paginationValue from "../../common/paginationValue.json";
import Pagination from "../../components/Layout/Pagination";
import loader from "../../assets/loader.svg";
import toast from "react-hot-toast";
import AxiosInstance from "../../utils/axiosInstance";
import favoriteImg from "../../assets/bookmark-favorite.svg";
import WorkTypeFilter from "../../components/Filters/WorkTypeFilter";
import { useNavigate, Link } from "react-router-dom";
export default function DirectoryPage() {
  const [selectedValue, setSelectedValue] = useState("");
  const [country, setCountry] = useState("");
  const [resetTrue, setResetTrue] = useState(false);
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [APICall, setAPICAll] = useState(false);
  const [favloader, setFavloader] = useState(false);
  const [selectedState, setSelectedState] = useState(
    JSON.parse(localStorage.getItem("selectedState")) || null
  );
  const [showloader, setloader] = useState(false);
  const [directoryList, setDirectoryList] = useState([]);
  const [noticeToContractor, setNoticeToContractor] = useState("");
  const [asReadBids, setAsReadBids] = useState("");
  const [workTypeId, setWorkTypeId] = useState("");
  const navigate = useNavigate();

  const abortControllerRef = useRef(null);
  useEffect(() => {
    if (currentPage > 0) {
      getDirectoryList();
    }
  }, [currentPage, country, workTypeId]);

  useEffect(() => {
    if (APICall) getDirectoryList();

  }, [APICall ]);

  const getDirectoryList = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setAPICAll(false);
    setloader(true);

    const controller = new AbortController();
    abortControllerRef.current = controller;
    const queryParams = new URLSearchParams({
      county: country,
      page: currentPage,
      limit: pageLimit,
      stateid: selectedState?.label,
      report_id: workTypeId,
    });

    const currentTime = new Date().getTime();
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_DIRECTORY}?currentTime=${currentTime}&${queryParams}`,
      { signal: controller.signal }
    )
      .then((response) => {
        if (response.data?.data) {
          setDirectoryList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setNoticeToContractor(response.data?.notice_to_contractors);
          setAsReadBids(response.data?.as_read_bids);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setDirectoryList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") {
          // console.log("Aborted");
          //toast.error(error?.message);
        } else {
          toast.error(error?.message);
          setloader(false);
        }
      });
  };

  const handleCountrySelect = (country) => {
    setCountry(country);
    setResetTrue(false);
  };

  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleReset = () => {
    setResetTrue(true);
    setSelectedValue("");
    setCountry("");
    setSearchValue("");
    setWorkTypeId("");
  };

  const addFav = (account_Id, favorite) => {
    setFavloader(true);
    const payload = {
      project_id: account_Id,
      type: "DIRECTORY",
      action: favorite,
      state_id: selectedState?.value,
    };

    AxiosInstance.post(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_FAVORITE}`,
      payload
    )
      .then((response) => {
        setFavloader(false);
        toast.success(response.data?.message);
        // getDirectoryList();
        setDirectoryList((prevList) =>
          prevList.map((item) =>
            item.account_id === account_Id
              ? { ...item, favorites: item.favorites === "0" ? "1" : "0" }
              : item
          )
        );
      })
      .catch((error) => {
        toast.error(error?.message);
        setFavloader(false);
      });
  };
  return (
    <>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-12 col-md-auto">
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
                            id="basic-addon1"
                          >
                            Filter:
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md">
                        <div className="input-group mb-3">
                          <div style={{ width: "100%" }}>
                            <WorkTypeFilter
                              onSelectWorkType={handleWorkTypeSelect}
                              reset={resetTrue}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div className="input-group mb-3">
                          <div style={{ width: "100%" }}>
                            <CountryFilter
                              onSelectCountry={handleCountrySelect}
                              reset={resetTrue}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control fw-bolder text-white bg-secondary rounded-0 border border-1 border-secondary placeholder-white point-18"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="button-addon2"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <button
                            className="btn btn-primary text-secondary rounded-0 border-0"
                            type="button"
                            id="button-addon2"
                          >
                            <span className="point-20">
                              <img
                                src={searchIcon}
                                alt="icon"
                                reset={resetTrue}
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="col-12 col-md mb-2">
                        <div className="input-group mb-3">
                          <input
                            type="button"
                            className="form-control btn btn-link text-uppercase me-2 text-danger text-start"
                            placeholder="Reset"
                            aria-label="Reset"
                            value="Reset"
                            onClick={() => handleReset()}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="clearfix">
                      <span className="float-md-end">
                        <span className="point-12 text-black-50 fw-light">
                          <span className="point-18">
                            <i className="bi bi-star fs-6"></i>
                          </span>
                          <span className="text-uppercase fw-bold">
                            &nbsp;Save to Dashboard
                          </span>
                        </span>
                      </span>
                    </div>

                    <div>
                      <ul className="list-group py-3">
                        {!showloader && !favloader ? (
                          directoryList.length > 0 ? (
                            directoryList.map((data, i) => (
                              <li
                                key={i}
                                className="list-group-item py-3 mb-3 bg-light border border-1 rounded-0 slideup-p cursor-pointer"
                              >
                                <div className="d-md-flex justify-content-between align-items-start">
                                  <div className="me-auto mb-2">
                                    <span className="fw-bold point-22">
                                      {data?.account_name}&nbsp;&nbsp;
                                    </span>
                                    <span className="point-22 text-black-50 fw-light">
                                      {/* temprary null === null for testing */}
                                      {data?.favorites === "0" ? (
                                        <i
                                          className="bi bi-star fs-6"
                                          onClick={() =>
                                            addFav(
                                              data?.account_id,
                                              data?.favorites
                                            )
                                          }
                                        ></i>
                                      ) : (
                                        <img
                                          src={favoriteImg}
                                          alt="fav"
                                          onClick={() =>
                                            addFav(
                                              data?.account_id,
                                              data?.favorites
                                            )
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </span>
                                    <span className="fw-normal font-sm">
                                      Worktype : {data?.report_name}
                                    </span>
                                  </div>
                                  <div className="mb-2"></div>
                                </div>
                                <p className="lh-lg">
                                  <span className="fw-bold">
                                    Address : &nbsp;{" "}
                                  </span>
                                  <span>
                                    <span>
                                      {(data?.billing_address_street
                                        ? data.billing_address_street
                                        : "-") +
                                        " , " +
                                        (data?.billing_address_city
                                          ? data.billing_address_city
                                          : "-")}
                                    </span>
                                  </span>
                                  <span className="text-danger fw-bolder d-none d-md-inline-block">
                                    &nbsp;|&nbsp;
                                  </span>
                                  <span className="fw-bold">
                                    Phone : &nbsp;
                                  </span>
                                  <span> {data?.contact_phone}</span>

                                  <span className="text-danger fw-bolder d-none d-md-inline-block">
                                    &nbsp;|&nbsp;
                                  </span>
                                  <span className="fw-bold">
                                    Email : &nbsp;
                                  </span>
                                  <span>
                                    <a
                                      href={`mailto:${data?.account_email}`}
                                      className="text-dark text-decoration-none"
                                    >
                                      {data?.account_email}{" "}
                                    </a>
                                  </span>
                                  {data?.description && (
                                    <>
                                      <br />
                                      <span className="fw-bold">
                                        Description :{" "}
                                      </span>
                                      <span>{data.description}</span>
                                    </>
                                  )}
                                </p>
                              </li>
                            ))
                          ) : (
                            <div className="d-md-flex justify-content-center">
                              No Data Found.
                            </div>
                          )
                        ) : (
                          <div className="loader">
                            <img src={loader} alt="loading" />
                          </div>
                        )}
                      </ul>
                    </div>

                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      pageLimit={pageLimit}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  );
}
