import React, { useEffect, useState } from "react";
import AxiosInstance from "../../utils/axiosInstance";
import { setLocalStoarge } from "../../common/allFunctions";
import Select from "react-select";

export default function WorkTypeFilter({
  onSelectWorkType,
  reset,
  borderTrue,
  ismultiple,
  preSelectedValues,
}) {
  const [selectedWorkType, setSelectedWorkType] = useState(
    ismultiple ? [] : ""
  );

  const [list, setList] = useState([]);

  useEffect(() => {
    if (
      preSelectedValues?.highway &&
      Array.isArray(preSelectedValues.highway) &&
      list.length > 0 &&
      selectedWorkType.length === 0
    ) {
      const preSelectedOptions = list.filter((option) =>
        preSelectedValues.highway.includes(option.value)
      );

      setSelectedWorkType(
        ismultiple ? preSelectedOptions : preSelectedOptions[0] || ""
      );
    }
  }, [list, preSelectedValues, ismultiple]);

  useEffect(() => {
    if (reset) {
      setSelectedWorkType(ismultiple ? [] : "");
    }
  }, [reset]);

  const transformData = (items) =>
    items
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

  const getWorkType = () => {
    AxiosInstance.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_WORKTYPE_HIGHWAY}`
    )
      .then((response) => {
        if (response.data?.data) {
          setList(transformData(response.data?.data));
          setLocalStoarge(
            "workTypeList",
            JSON.stringify(transformData(response.data?.data))
          );
        }
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };

  useEffect(() => {
    const storedList = JSON.parse(localStorage.getItem("workTypeList") || "[]");
    setList(storedList);
    if (storedList.length === 0) {
      getWorkType();
    }
  }, []);

  const handleChange = (workType) => {
    setSelectedWorkType(workType);
    if (ismultiple) {
      onSelectWorkType(workType.map((option) => option.value));
    } else {
      onSelectWorkType(workType?.value || "");
    }
  };

  return (
    <Select
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: "#9FAFCA",
          borderWidth: "3px",
          borderTop: !borderTrue && "none",
          borderLeft: !borderTrue && "none",
          borderRight: !borderTrue && "none",
          boxShadow: borderTrue && "none",
          "&:hover": {
            borderColor: "#2684FF",
          },
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: "#a71e1e",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#6c757d",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#9FAFCA" : "white",
          color: state.isSelected ? "white" : "black",
          "&:hover": {
            backgroundColor: "#9FAFCA",
            color: "white",
          },
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
          borderRadius: 0,
        }),
      }}
      options={list}
      value={selectedWorkType}
      onChange={handleChange}
      placeholder="WORK TYPE"
      isMulti={ismultiple}
      isSearchable
    />
  );
}